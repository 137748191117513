<div class="relative bg-[#043D57]">
  <div class="max-w-screen-desktop mx-auto">
    <div class="text-[#ffffff] text-[14px] font-quicksand text-center p-[15px]">
      <div class="md:flex block justify-between">
        <span class="block md:mb-0 mb-[20px]">{{
          footerData?.footer_ribbon_txt
        }}</span>
        <a
          href="https://atomikal.com/"
          target="_blank"
          class="block no-underline"
        >
          <img src="../../../../assets/images/logo-atomikal-footer.svg" />
        </a>
      </div>
    </div>
  </div>
</div>
