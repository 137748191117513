import {
  Component,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-device-detector",
  template: "",
  standalone: true,
})
export class DeviceDetectorComponent implements OnInit {
  @Output() isMobile = new EventEmitter<boolean>();
  @Output() isLandscape = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    this.checkDevice();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?: any) {
    this.checkDevice();
  }

  private checkDevice() {
    const mobile = window.innerWidth < 560;
    const landscape = window.innerWidth >= 561 && window.innerWidth <= 760;
    this.isMobile.emit(mobile);
    this.isLandscape.emit(landscape);
  }
}
