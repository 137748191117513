<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<section class="renders-account overflow-x-hidden">
  <div
    class="relative pt-[150px] max-[1560px]:pt-[100px] max-[992px]:pt-[0px] md:pb-0 pb-[50px]"
  >
    <div class="relative bg-[#e30613]">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative md:pt-[50px] md:px-0 px-[20px]">
          <div class="md:flex gap-[10px]">
            <div class="md:w-1/2 md:pt-0 pt-[20px]">
              <img
                [src]="
                  isMobile
                    ? dataRenders?.banner_img_mobile
                    : dataRenders?.banner_img_desktop
                "
                class="w-full align-bottom"
              />
            </div>
            <div class="md:w-1/2 relative md:mt-0 mt-[-50px]">
              <img
                src="../../../assets/images/cartel-teleton-2024.webp"
                class="md:absolute relative m-auto top-0 bottom-0 w-full"
              />
              <div
                class="text-[#000000] text-[60px] max-[992px]:text-[30px] font-montserrat absolute m-auto top-0 bottom-0 left-0 right-0 w-full h-max z-[1] text-center font-bold"
              >
                Rinde Cuentas
                <span
                  class="block font-black text-[70px] max-[992px]:text-[35px]"
                  >2023</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative bg-[#FCE8E8]">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[60px] md:px-0 px-[20px] font-montserrat">
          <div class="teleton-card p-[40px] text-center">
            <div class="relative md:w-[600px] mx-auto">
              <div
                class="md:text-[20px] text-[16px] text-[#000000] font-semibold mb-[20px]"
                [innerHTML]="dataRenders?.section2_text1"
              ></div>
              <div
                class="md:text-[60px] text-[30px] text-[#000000] font-extrabold mb-[20px] bg-[#FAB530]"
                [innerHTML]="dataRenders?.section2_text2"
              ></div>
              <div
                class="md:text-[20px] text-[16px] text-[#000000] font-semibold"
                [innerHTML]="dataRenders?.section2_text3"
              ></div>
            </div>
            <div
              class="md:text-[96px] text-[27px] font-black text-[#FC1821]"
              [innerHTML]="dataRenders?.section2_text4"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[60px] md:px-0 px-[5px] font-montserrat">
          <app-tabs-renders [items]="dataRenders?.years"></app-tabs-renders>
        </div>
      </div>
    </div>
    <div class="relative bg-[#ECF9FE]">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[60px] md:px-0 px-[10px] font-montserrat">
          <ng-container *ngFor="let item of dataRenders?.items">
            <div
              class="teleton-card mb-[20px]"
              [ngClass]="
                item.title === null && isMobile ? 'py-[40px]' : 'p-[40px]'
              "
            >
              <div class="md:flex gap-[40px]" *ngIf="item.title !== null">
                <div
                  class="relative md:w-1/4 md:h-[200px] mx-auto md:mb-0 mb-[20px]"
                >
                  <img
                    [src]="isMobile ? item.img_mobile : item.img_desktop"
                    class="md:absolute relative m-auto object-contain object-center z-[1] md:w-auto w-full h-full top-0 left-0 right-0"
                  />
                </div>
                <div class="relative md:w-4/5">
                  <div
                    class="md:text-[36px] text-[27px] font-bold font-montserrat"
                  >
                    {{ item.title }}
                  </div>
                  <div class="line"></div>
                  <div
                    class="text-[20px] font-montserrat font-semibold leading-[120%]"
                    [ngClass]="isMobile ? 'mb-[30px]' : ''"
                    [innerHTML]="item.text"
                  ></div>
                </div>
              </div>
              <div class="relative" *ngIf="item.title === null">
                <img
                  [src]="isMobile ? item.img_mobile : item.img_desktop"
                  class="w-full"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[50px] font-montserrat px-[15px]">
          <div
            class="text-[40px] leading-[100%] font-bold"
            [innerHTML]="dataRenders?.section6_title"
          ></div>
          <div class="line"></div>
          <div
            class="text-[20px] font-montserrat font-semibold leading-[120%] pr-[20px]"
            [innerHTML]="dataRenders?.section6_text"
          ></div>
        </div>
      </div>
    </div>
    <div class="relative bg-[#FCE8E8]">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[50px] md:px-0 px-[10px] font-montserrat">
          <div class="outter-box">
            <div class="bg-[#ffffff] md:p-[40px] p-[20px] rounded-[20px]">
              <img
                [src]="
                  isMobile
                    ? dataRenders?.section6_img1_mobile
                    : dataRenders?.section6_img1_desktop
                "
                class="w-full"
              />
            </div>
          </div>
        </div>
        <img
          [src]="
            isMobile
              ? dataRenders?.section6_img2_mobile
              : dataRenders?.section6_img2_desktop
          "
          class="block max-[992px]:hidden absolute right-[-195px] bottom-[-40px] w-[40%]"
        />
      </div>
    </div>
    <div class="relative">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[50px] font-montserrat px-[15px]">
          <div
            class="md:text-[40px] text-[30px] leading-[100%] font-bold text-center"
            [innerHTML]="dataRenders?.section7_title"
          ></div>
          <div class="line"></div>
          <div
            class="text-[20px] font-montserrat font-semibold leading-[100%] md:pr-[20px] text-center md:mb-[20px] mb-[50px]"
            [innerHTML]="dataRenders?.section7_text"
          ></div>
          <div
            class="md:flex items-baseline justify-center gap-[30px] mb-[20px] max-[768px]:text-center"
          >
            <img [src]="dataRenders?.section7_logo1" class="w-[260px]" />
            <img [src]="dataRenders?.section7_logo2" class="w-auto" />
          </div>
          <a
            [href]="dataRenders?.section7_btn_url"
            target="_blank"
            class="block font-nunito w-max no-underline text-[20px] font-bold bg-[#FC1821] text-[#ffffff] rounded-[20px] py-[15px] px-[30px] max-[992px]:py-[20px] mx-auto"
            >{{ dataRenders?.section7_btn_text }}</a
          >
        </div>
      </div>
    </div>
    <div class="relative md:h-[370px] h-[205px]">
      <img
        [src]="
          isMobile
            ? dataRenders?.section8_img_mobile
            : dataRenders?.section8_img_desktop
        "
        class="absolute m-auto md:object-cover object-contain object-center z-[1] w-full h-full top-0 left-0 right-0"
      />
    </div>
    <div class="relative">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative py-[60px] md:px-0 px-[20px] font-montserrat">
          <div
            class="text-[20px] font-montserrat font-semibold leading-[100%] pr-[20px] text-center mb-[20px]"
            [innerHTML]="dataRenders?.section8_text1"
          ></div>
          <div class="outter-box">
            <div
              class="max-w-[720px] bg-[#ED1C24] text-[#ffffff] md:py-[45px] py-[15px] md:px-[40px] px-[10px] rounded-[20px]"
            >
              <div
                class="md:text-[42px] text-[25px] text-center font-semibold border-box"
                [innerHTML]="dataRenders?.section8_text2"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="max-w-screen-large mx-auto w-full relative h-full">
        <div class="relative md:px-0 px-[20px]">
          <div
            class="md:text-[42px] text-[27px] text-center text-[#FC1821] font-extrabold font-quicksand"
          >
            Conoce aqui nuestras rendiciones de cuentas
          </div>
        </div>
        <app-slider-renders [data]="dataRenders?.reports"></app-slider-renders>
      </div>
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
