<div
  class="relative bg-[#E01319] px-[20px] md:pt-[100px] pt-[60px] pb-[80px] overflow-hidden"
>
  <img
    src="../../../../../assets/images/flor_azul.webp"
    class="absolute md:block hidden top-[-35px] left-[-50px] w-[170px]"
  />
  <div class="max-w-screen-short mx-auto">
    <div class="relative font-helloKidme md:px-[20px] px-0">
      <div class="relative text-center mb-[5px]">
        <img
          src="../../../../../assets/images/lateral_superior_blue.png"
          alt=""
        />
      </div>
      <div
        class="relative text-center shadow-text md:text-[100px] text-[40px] uppercase text-[#ffffff] mb-[20px] flex items-center justify-between"
      >
        <div class="md:mr-[20px] mr-[10px]">
          <img
            src="../../../../../assets/images/lateral_skyblue_left.png"
            class="w-auto"
          />
        </div>
        <div class="block md:leading-[70%] leading-[100%]">
          <span class="block">GRACIAS A TU APOYO,</span>
          <span class="block md:text-[80px] text-[32px]">EN EL 2023:</span>
        </div>
        <div class="md:ml-[20px] ml-[10px]">
          <img
            src="../../../../../assets/images/lateral_skyblue_right.png"
            class="w-auto"
          />
        </div>
      </div>
      <div class="relative">
        <div
          class="relative bg-[#ffffff] shadow-custom mb-[40px] z-[1] flex md:items-center items-end pt-[20px]"
        >
          <div class="w-1/2 font-quicksand md:pb-0 pb-[20px]">
            <div class="relative text-center">
              <img
                src="../../../../../assets/images/lateral_superior_blue.png"
                class="w-[105px]"
              />
            </div>
            <div class="relative text-center md:text-[40px] text-[20px]">
              <span class="block">Se realizaron</span>
              <span class="block md:text-[60px] text-[30px] font-bold"
                >114,802</span
              >
              <span class="block font-bold">atenciones</span>
              <span class="block">médicas.</span>
            </div>
            <div class="relative text-center mt-[10px]">
              <img
                src="../../../../../assets/images/lateral_superior_blue.png"
                class="w-[105px] transform rotate-180"
              />
            </div>
          </div>
          <div class="w-1/2 flex justify-end md:h-full h-[180px] relative">
            <img
              src="../../../../../assets/images/img_thanks_01.png"
              class="md:w-auto w-full md:relative absolute h-full w-full object-cover object-bottom"
            />
          </div>
        </div>
        <img
          src="../../../../../assets/images/flor_amarilla.webp"
          class="absolute top-[-70px] right-[-200px] w-[170px]"
        />
      </div>

      <div class="relative">
        <div class="relative bg-[#ffffff] shadow-custom mb-[40px] z-[1]">
          <div
            class="relative flex items-center justify-center font-quicksand py-[20px]"
          >
            <div class="md:mr-[40px] mr-[10px] relative">
              <img
                src="../../../../../assets/images/group_blue_01.png"
                class="md:w-auto w-[40px]"
              />
            </div>
            <div class="text-center">
              <div class="md:text-[30px] text-[25px] font-medium">
                Se inauguró el <span class="font-bold">Centro TEA.</span>
              </div>
              <div class="md:text-[16px] text-[14px] font-semibold">
                Con instalaciones de 470 m<sup>2</sup> y especialistas de alta
                calidad.
              </div>
            </div>
            <div class="md:ml-[40px] ml-[10px] relative">
              <img
                src="../../../../../assets/images/group_blue_02.png"
                class="md:w-auto w-[40px]"
              />
            </div>
          </div>
          <div class="relative flex">
            <img
              class="relative h-full w-full object-cover object-bottom absolute"
              src="../../../../../assets/images/img_thanks_02.png"
            />
          </div>
        </div>
        <img
          src="../../../../../assets/images/flor_verde.png"
          class="absolute md:block hidden top-[-50px] left-[-100px]"
        />
      </div>

      <div class="relative z-[1]">
        <div
          class="relative bg-[#ffffff] shadow-custom mb-[40px] overflow-hidden"
        >
          <div
            class="relative text-center md:text-[30px] text-[24px] font-quicksand py-[40px] px-[20px] font-medium"
          >
            En los últimos <span class="font-bold">4 años</span> se atendieron a
            <span class="font-bold">3,522 niñas y niños.</span>
          </div>
          <img
            src="../../../../../assets/images/flor_celeste.png"
            class="absolute bottom-[-30px] left-[-10px] w-[80px]"
          />
          <img
            src="../../../../../assets/images/flor_naranja.png"
            class="absolute top-[-25px] right-[-55px] w-[105px]"
          />
        </div>
      </div>

      <div class="relative">
        <div
          class="relative bg-[#ffffff] p-[40px] shadow-custom mb-[40px] z-[1]"
        >
          <img
            src="../../../../../assets/images/cornerback_top_left.webp"
            class="absolute top-[20px] left-[20px]"
          />
          <img
            src="../../../../../assets/images/cornerback_top_right.webp"
            class="absolute top-[20px] right-[20px]"
          />
          <img
            src="../../../../../assets/images/cornerback_bottom_left.webp"
            class="absolute bottom-[20px] left-[20px]"
          />
          <img
            src="../../../../../assets/images/cornerback_bottom_right.webp"
            class="absolute bottom-[20px] right-[20px]"
          />
          <div class="md:flex block justify-between">
            <div class="md:w-1/2 w-full text-center">
              <div class="font-quicksand">
                <div
                  class="md:text-[24px] text-[20px] mb-[20px] w-[220px] mx-auto"
                >
                  Estamos auditados por:
                </div>
                <img src="../../../../../assets/images/logo_pwc.png" alt="" />
              </div>
            </div>
            <img
              src="../../../../../assets/images/separator_vertical_yellow_mini_02.png"
              class="md:block hidden"
            />
            <img
              src="../../../../../assets/images/separator_horizontal_yellow_mini_02.png"
              class="md:hidden block my-[20px]"
            />
            <div
              class="md:w-1/2 w-full text-center flex md:items-start items-center justify-center"
            >
              <div class="font-quicksand">
                <div
                  class="md:text-[24px] text-[20px] md:mb-[40px] mb-[20px] w-[220px] mx-auto"
                >
                  Estamos administrados por:
                </div>
                <img
                  src="../../../../../assets/images/logo_fiducaria.png"
                  class="md:w-auto w-[200px]"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          src="../../../../../assets/images/flor_celeste.png"
          class="absolute top-[-80px] right-[-120px]"
        />
      </div>
    </div>
  </div>
</div>
