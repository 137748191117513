<app-header-pages></app-header-pages>
<div class="relative py-[60px]">
  <div class="max-w-screen-short mx-auto">
    <div
      class="md:flex block gap-[30px] items-center font-quicksand md:px-0 px-[20px]"
    >
      <div class="md:w-1/2 w-full md:mb-0 mb-[20px]">
        <img src="../../../assets/images/image-donation.png" class="w-full" />
      </div>
      <div class="md:w-1/2 w-full">
        <div class="relative">
          <form #donationForm="ngForm" (ngSubmit)="onSubmit()">
            <div
              class="text-[#fc1821] text-[40px] font-black font-quicksand mb-[20px] md:text-left text-center"
            >
              ¡Donar ahora!
            </div>
            <div class="text-[14px] my-[10px] mb-[20px]">
              Tu donación contribuye a la rehabilitación de las niñas y niños
              Teletón que se atienden en las 6 clínicas San Juan de Dios
            </div>
            <div class="px-0">
              <div class="mb-[15px]">
                <label
                  for="Nombre"
                  class="block text-[12px] text-[#999b99] mb-[5px] ml-[20px]"
                  >Nombre:</label
                >
                <input
                  type="text"
                  placeholder="Digite su Nombre"
                  [(ngModel)]="name"
                  name="name"
                  class="w-full px-[20px] py-[10px] border-0 rounded-full bg-[#ededed] text-[14px] box-border outline-none"
                  [class]="
                    nameInput.invalid && nameInput.touched
                      ? 'border-[#fc1821] border-solid !border-[1px]'
                      : ''
                  "
                  required
                  maxlength="200"
                  #nameInput="ngModel"
                  (input)="nameInput.control.markAsTouched()"
                  (keypress)="
                    validateNoNumbers($event); validateNoSpecialChars($event)
                  "
                />
                <div
                  *ngIf="nameInput.invalid && nameInput.touched"
                  class="text-[#fc1821] text-[12px] font-bold pl-[20px] pt-[5px]"
                >
                  El nombre es requerido.
                </div>
              </div>

              <div class="mb-[15px]">
                <label
                  for="Apellido"
                  class="block text-[12px] text-[#999b99] mb-[5px] ml-[20px]"
                  >Apellido:</label
                >
                <input
                  type="text"
                  placeholder="Digite su Apellido"
                  [(ngModel)]="lastname"
                  name="lastname"
                  class="w-full px-[20px] py-[10px] border-0 rounded-full bg-[#ededed] text-[14px] box-border outline-none"
                  [class]="
                    lastnameInput.invalid && lastnameInput.touched
                      ? 'border-[#fc1821] border-solid !border-[1px]'
                      : ''
                  "
                  required
                  maxlength="200"
                  #lastnameInput="ngModel"
                  (input)="lastnameInput.control.markAsTouched()"
                  (keypress)="
                    validateNoNumbers($event); validateNoSpecialChars($event)
                  "
                />
                <div
                  *ngIf="lastnameInput.invalid && lastnameInput.touched"
                  class="text-[#fc1821] text-[12px] font-bold pl-[20px] pt-[5px]"
                >
                  El apellido es requerido y debe tener al menos 2 caracteres.
                </div>
              </div>

              <div class="mb-[15px]">
                <label
                  for="Correo electrónico"
                  class="block text-[12px] text-[#999b99] mb-[5px] ml-[20px]"
                  >Correo Electrónico:</label
                >
                <input
                  type="email"
                  placeholder="Digite su correo electrónico"
                  [(ngModel)]="email"
                  name="email"
                  class="w-full px-[20px] py-[10px] border-0 rounded-full bg-[#ededed] text-[14px] box-border outline-none"
                  [class]="
                    emailInput.invalid && emailInput.touched
                      ? 'border-[#fc1821] border-solid !border-[1px]'
                      : ''
                  "
                  required
                  #emailInput="ngModel"
                  maxlength="200"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  (input)="emailInput.control.markAsTouched()"
                />
                <div
                  *ngIf="emailInput.invalid && emailInput.touched"
                  class="text-[#fc1821] text-[12px] font-bold pl-[20px] pt-[5px]"
                >
                  Por favor, ingrese un correo electrónico válido.
                </div>
              </div>

              <div class="mb-[15px]">
                <label
                  for="Donativo"
                  class="block text-[12px] text-[#999b99] mb-[5px] ml-[20px]"
                  >Donativo:</label
                >
                <input
                  type="number"
                  step="0.01"
                  placeholder="Digite el monto del donativo"
                  [(ngModel)]="amount"
                  name="amount"
                  class="w-full px-[20px] py-[10px] border-0 rounded-full bg-[#ededed] text-[14px] box-border outline-none"
                  [class]="
                    amountInput.invalid && amountInput.touched
                      ? 'border-[#fc1821] border-solid !border-[1px]'
                      : ''
                  "
                  required
                  min="1"
                  #amountInput="ngModel"
                  (input)="amountInput.control.markAsTouched()"
                />
                <div
                  *ngIf="amountInput.invalid && amountInput.touched"
                  class="text-[#fc1821] text-[12px] font-bold pl-[20px] pt-[5px]"
                >
                  El monto del donativo es requerido y debe ser mayor a 0.
                </div>
              </div>

              <div class="mb-[15px]">
                <label class="text-[14px]">
                  <input
                    type="checkbox"
                    [(ngModel)]="agreeToTerms"
                    name="agreeToTerms"
                    [class]="
                      termsInput.invalid && termsInput.touched
                        ? 'border-[#fc1821] border-solid !border-[1px]'
                        : ''
                    "
                    required
                    #termsInput="ngModel"
                    (change)="termsInput.control.markAsTouched()"
                  />
                  *He leído y aceptado
                  <a
                    class="text-[#dc3232]"
                    target="_blank"
                    href="../../../assets/files/POLITICA-DE-PRIVACIDAD-Y-PROTECCION-DE-DATOS-FUNDACION-TELETON-070623-1.pdf"
                    >la política de privacidad de datos.</a
                  >
                </label>
                <div
                  *ngIf="termsInput.invalid && termsInput.touched"
                  class="text-[#fc1821] text-[12px] font-bold pl-[20px] pt-[5px]"
                >
                  Debes aceptar la política de privacidad de datos.
                </div>
              </div>

              <div class="text-left">
                <button
                  type="submit"
                  [disabled]="donationForm.invalid"
                  class="bg-[#fc1821] hover:bg-[#043d57] text-[#ffffff] py-[15px] px-[20px] rounded-md font-quicksand font-bold md:w-[130px] w-full text-[16px] border-0"
                  [class]="
                    donationForm.invalid
                      ? 'pointer-events-none opacity-[0.5]'
                      : 'cursor-pointer'
                  "
                >
                  Donar
                </button>
              </div>
            </div>
          </form>

          <!-- Payment form controls -->
          <div id="txtTarjeta"></div>
          <div id="txtNumeroTarjeta"></div>
          <div id="txtFechaVencimiento"></div>
          <div id="txtCvv"></div>

          <!-- Existing content continues -->
        </div>
      </div>
    </div>
  </div>
</div>
