<section class="donate-telesponsors">
  <div
    class="relative pt-[150px] max-[1560px]:pt-[100px] max-[992px]:pt-[50px] md:pb-0 pb-[50px]"
  >
    <div class="max-w-screen-large mx-auto w-full relative h-full">
      <div class="relative md:py-[70px] md:px-0 px-[20px]">
        <div
          class="md:flex items-center border-[10px] border-solid border-[#BBE0FB] md:px-[35px] px-[20px] md:py-[30px] py-[20px] rounded-[20px]"
        >
          <div class="md:w-[120px] text-center">
            <img
              src="../../../../../assets/images/telepadrinos-logo.webp"
              class="md:w-[120px] w-[100px]"
            />
          </div>
          <div class="relative pl-[20px]">
            <div class="text-[40px] font-lilita uppercase text-[#000000]">
              Gracias a tu aporte mensual,
            </div>
            <div class="text-[20px] font-nunito text-[#000000] font-bold">
              un niño va a poder recibir una o más terapias físicas que le
              permitirán mejorar su calidad de vida. Tu contribución mensual
              ayudará a cambiar y mejorar la vida de un niño para siempre
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative md:py-[70px] py-[50px] md:px-0 px-[20px]">
    <img
      src="../../../../assets/images/fondo-hoja-rosado.webp"
      class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
    />
    <div class="max-w-screen-large mx-auto w-full relative z-[1] h-full">
      <div
        class="md:flex items-center p-[35px] max-[992px]:p-[20px] border-[10px] border-solid border-[#DE1318] rounded-[20px]"
      >
        <div class="bg-overlay-title bg-overlay-title-red"></div>
        <div
          class="px-[20px] py-[45px] max-[992px]:py-[20px] w-full bg-[#ffffff] rounded-[18px] box-border"
        >
          <div class="text-[40px] font-lilita mb-[20px] text-center">
            Información
          </div>

          <div class="mb-[20px]">
            <form [formGroup]="formDonate" (ngSubmit)="onSubmit()">
              <div class="relative">
                <div class="mb-[10px]">
                  <label class="form-label" for="nombre">Nombre:</label>
                  <input
                    id="nombre"
                    class="form-control"
                    formControlName="nombre"
                    (input)="onTextOnly($event, 'nombre')"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('nombre')?.invalid &&
                      formDonate.get('nombre')?.touched
                    "
                  >
                    El nombre es obligatorio.
                  </div>
                </div>

                <div class="mb-[10px]">
                  <label class="form-label" for="apellido">Apellido:</label>
                  <input
                    id="apellido"
                    class="form-control"
                    formControlName="apellido"
                    (input)="onTextOnly($event, 'apellido')"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('apellido')?.invalid &&
                      formDonate.get('apellido')?.touched
                    "
                  >
                    El apellido es obligatorio.
                  </div>
                </div>

                <div class="mb-[10px]">
                  <label class="form-label" for="tipoDocumento"
                    >Tipo de documento:</label
                  >
                  <select
                    (change)="onTipoDocumentoChange($event)"
                    formControlName="tipoDocumento"
                  >
                    <option value="DNI">DNI</option>
                    <option value="CE">CE</option>
                    <option value="PASAPORTE">Pasaporte</option>
                  </select>
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('tipoDocumento')?.invalid &&
                      formDonate.get('tipoDocumento')?.touched
                    "
                  >
                    Selecciona un tipo de documento.
                  </div>
                </div>

                <!-- Campo para DNI -->
                <div class="mb-[10px]" *ngIf="selectedDni === 'DNI'">
                  <label class="form-label" for="dni">DNI:</label>
                  <input
                    id="dni"
                    class="form-control"
                    formControlName="dni"
                    (input)="onNumberLengthOnly($event, 'dni', 8)"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('dni')?.invalid &&
                      formDonate.get('dni')?.touched
                    "
                  >
                    El DNI es obligatorio.
                  </div>
                </div>

                <!-- Campo para CE -->
                <div class="mb-[10px]" *ngIf="selectedDni === 'CE'">
                  <label class="form-label" for="ce">CE:</label>
                  <input
                    id="ce"
                    class="form-control"
                    formControlName="ce"
                    (input)="onNumberLengthOnly($event, 'ce', 20)"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('ce')?.invalid &&
                      formDonate.get('ce')?.touched
                    "
                  >
                    El número de CE es obligatorio.
                  </div>
                </div>

                <!-- Campo para Pasaporte -->
                <div class="mb-[10px]" *ngIf="selectedDni === 'PASAPORTE'">
                  <label class="form-label" for="pasaporte">Pasaporte:</label>
                  <input
                    id="pasaporte"
                    class="form-control"
                    formControlName="pasaporte"
                    (input)="onNumberLengthOnly($event, 'pasaporte', 20)"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('pasaporte')?.invalid &&
                      formDonate.get('pasaporte')?.touched
                    "
                  >
                    El número de pasaporte es obligatorio.
                  </div>
                </div>

                <div class="mb-[10px]">
                  <label class="form-label" for="correo">Correo:</label>
                  <input
                    id="correo"
                    class="form-control"
                    formControlName="correo"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('correo')?.invalid &&
                      formDonate.get('correo')?.touched
                    "
                  >
                    Introduce un correo válido.
                  </div>
                </div>

                <div class="mb-[20px]">
                  <label class="form-label" for="celular">Celular:</label>
                  <input
                    id="celular"
                    class="form-control"
                    formControlName="celular"
                    (input)="onNumberPhoneOnly($event, 'celular')"
                  />
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('celular')?.invalid &&
                      formDonate.get('celular')?.touched
                    "
                  >
                    El celular es obligatorio y debe tener 9 dígitos.
                  </div>
                </div>

                <div class="mb-[10px]">
                  <app-custom-radio
                    (selectionChange)="onRadioSelectionChange($event)"
                  >
                    <label
                      class="custom-radio"
                      (click)="onRadioSelectionChange('40')"
                      [class.selected]="isSelected('40')"
                    >
                      <input
                        type="radio"
                        formControlName="monto"
                        value="40"
                        class="hidden"
                      />
                      <div class="flex justify-between w-full">
                        <div class="relative">
                          <div class="text-[38px] font-lilita">S/ 40</div>
                          <div class="text-[18px] font-lilita">Mensual</div>
                        </div>
                        <img
                          src="../../../assets/images/hand-growing.svg"
                          class="w-auto"
                        />
                      </div>
                    </label>

                    <label
                      class="custom-radio"
                      (click)="onRadioSelectionChange('60')"
                      [class.selected]="isSelected('60')"
                    >
                      <input
                        type="radio"
                        formControlName="monto"
                        value="60"
                        class="hidden"
                      />
                      <div class="flex justify-between w-full">
                        <div class="relative">
                          <div class="text-[38px] font-lilita">S/ 60</div>
                          <div class="text-[18px] font-lilita">Mensual</div>
                        </div>
                        <img
                          src="../../../assets/images/hands-hearth.svg"
                          class="w-auto"
                        />
                      </div>
                    </label>

                    <label
                      class="custom-radio"
                      (click)="onRadioSelectionChange('90')"
                      [class.selected]="isSelected('90')"
                    >
                      <input
                        type="radio"
                        formControlName="monto"
                        value="90"
                        class="hidden"
                      />
                      <div class="flex justify-between w-full">
                        <div class="relative">
                          <div class="text-[38px] font-lilita">S/ 90</div>
                          <div class="text-[18px] font-lilita">Mensual</div>
                        </div>
                        <img
                          src="../../../assets/images/hold-hands.svg"
                          class="w-auto"
                        />
                      </div>
                    </label>
                  </app-custom-radio>
                </div>

                <div class="mb-[10px]">
                  <label class="form-label" for="terminos">
                    <input type="checkbox" formControlName="terminos" />
                    He leído y acepto los
                    <a
                      href="../../../../assets/files/3-POLITICA-DE-PRIVACIDAD-Y-PROTECCION-DE-DATOS-FUNDACION-TELETON-070623-1-1-4866-2116-9346-v.-3.pdf"
                      target="_blank"
                      class="text-[#F74F22] font-bold"
                      >términos y condiciones.</a
                    >
                  </label>
                  <div
                    class="error"
                    *ngIf="
                      formDonate.get('terminos')?.invalid &&
                      formDonate.get('terminos')?.touched
                    "
                  >
                    Debes aceptar los términos y condiciones.
                  </div>
                </div>
              </div>

              <button
                type="submit"
                [ngClass]="{
                  'opacity-50 pointer-events-none cursor-not-allowed':
                    formDonate.invalid
                }"
                class="block border-0 w-[180px] md:py-[20px] py-[15px] px-[20px] text-[#ffffff] text-[20px] bg-[#E30613] no-underline rounded-[20px] font-semibold font-nunito cursor-pointer mx-auto"
              >
                ¡Dona Ahora!
              </button>
            </form>

            <!-- Payment form controls -->
            <div id="txtTarjeta"></div>
            <div id="txtNumeroTarjeta"></div>
            <div id="txtFechaVencimiento"></div>
            <div id="txtCvv"></div>

            <!-- Existing content continues -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-static [dataFooter]="dataFooter"></app-footer-static>
<app-floating-button></app-floating-button>
