import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { HeaderComponent } from "./components/header/header.component";
import { SharedDataService } from "./shared/shared-data.service";
import { CommonModule } from "@angular/common";
import { filter } from "rxjs";

@Component({
  selector: "app-layout",
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, CommonModule],
  templateUrl: "./layout.component.html",
})
export class LayoutComponent implements OnInit {
  public showHeader: boolean = true;
  public data: any;

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.routerState.root.firstChild;
        if (currentRoute?.snapshot.data["showHeader"] !== undefined) {
          this.showHeader = currentRoute.snapshot.data["showHeader"];
        } else {
          this.showHeader = true;
        }
      });

    this.sharedDataService.currentData.subscribe((data) => {
      this.data = data;
    });
  }
}
