import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HomePaths } from "./home.paths";

@Injectable({
  providedIn: "root",
})
export class HomePageService {
  private _url: string;
  private homeApiUrl: string;

  constructor(private http: HttpClient) {
    this._url = environment.backendUrl;
    this.homeApiUrl = `${this._url}${HomePaths.HomeGet}`;
  }

  getHome() {
    return this.http.get<any>(this.homeApiUrl);
  }
}
