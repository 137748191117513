import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BannerLayoutComponent } from "../../layout/components/banner-layout/banner-layout.component";
import { SectionGratitudeComponent } from "../home/sections/section-gratitude/section-gratitude.component";
import { FooterStaticComponent } from "../../layout/components/footer-static/footer-static.component";
import { FloatingButtonComponent } from "../../layout/components/floating-button/floating-button.component";
import { HomePageService } from "../home/services/home.service";
import { BaseTitleComponent } from "../../components/base-title/base-title.component";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { SharedModule } from "../../../shared/directives/shared.module";

@Component({
  selector: "app-volunteers",
  standalone: true,
  imports: [
    CommonModule,
    BannerLayoutComponent,
    SectionGratitudeComponent,
    FooterInfoComponent,
    FooterComponent,
    FloatingButtonComponent,
    BaseTitleComponent,
    SharedModule,
  ],
  templateUrl: "./volunteers.component.html",
  styleUrl: "./volunteers.component.css",
})
export class VolunteersComponent implements OnInit {
  public dataHome?: any;
  public dataFooter?: any;
  public dataGratitude: any;

  constructor(private homeDataService: HomePageService) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.dataGratitude = this.dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: this.dataHome.footer_logo,
        footer_ribbon_txt: this.dataHome.footer_ribbon_txt,
        footer_txt: this.dataHome.footer_txt,
        social_networks: this.dataHome.social_networks,
      };
    });
  }
}
