import { ApplicationConfig, LOCALE_ID } from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideHttpClient } from "@angular/common/http";
import localeEsPe from "@angular/common/locales/es-PE";
import { registerLocaleData } from "@angular/common";

import { routes } from "./app.routes";
registerLocaleData(localeEsPe, "es-PE");

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    { provide: LOCALE_ID, useValue: "es-PE" },
  ],
};
