<div class="relative bg-[#E01319] px-[20px] md:py-[20px] py-[50px]">
  <img
    src="../../../../../assets/images/cornerback_top_left.webp"
    class="absolute top-[20px] left-[20px] block"
  />
  <img
    src="../../../../../assets/images/cornerback_top_right.webp"
    class="absolute top-[20px] right-[20px] block"
  />
  <img
    src="../../../../../assets/images/cornerback_bottom_left.webp"
    class="absolute bottom-[20px] left-[20px] block"
  />
  <img
    src="../../../../../assets/images/cornerback_bottom_right.webp"
    class="absolute bottom-[20px] right-[20px] block"
  />
  <div class="max-w-screen-largeXL mx-auto">
    <div class="relative font-quicksand text-[#ffffff] md:p-[20px]">
      <div class="block">
        <!-- <div
          class="text-center text-shadow-left font-bold md:text-[55px] md:text-[45px] text-[36px] w-[300px] max-[900px]:w-full mx-auto"
        >
          Meta:
        </div>
        <div
          class="text-center text-shadow-left font-bold text-[100px] max-[900px]:text-[42px] mb-[20px]"
        >
          {{
            (
              dataPayments?.goal_amount
              | currency : "PEN" : "symbol" : "1.0-0" : "es-PE"
            )?.replace("S/&nbsp;", "S/")
          }}
        </div> -->
        <div
          class="text-center text-shadow-left font-bold text-[100px] max-[900px]:text-[42px] mb-[20px]"
        >
          Gracias Perú
        </div>
      </div>
      <div class="block text-right max-[900px]:my-[0px] my-[-10px]">
        <img
          src="../../../../../assets/images/separator_meta.png"
          class="md:block hidden mx-auto w-auto max-[990px]:w-[100%]"
        />
        <img
          *ngIf="dataPayments?.is_active"
          src="../../../../../assets/images/separator_meta_mobile.png"
          class="md:hidden block w-full mb-[20px] mx-auto mr-[150px] w-auto max-[990px]:w-[100%]"
        />
      </div>
      <div class="block mt-[10px]" *ngIf="dataPayments?.is_active">
        <!-- <div
          class="text-center text-shadow-left font-bold md:text-[55px] md:text-[45px] text-[36px] w-[300px] max-[900px]:w-full mx-auto"
        >
          Así vamos:
        </div> -->
        <div
          class="text-center text-shadow-left font-bold text-[100px] max-[900px]:text-[42px]"
        >
          {{
            (
              dataPayments?.collected_amount
              | currency : "PEN" : "symbol" : "1.0-0" : "es-PE"
            )?.replace("S/&nbsp;", "S/")
          }}
        </div>
        <div
          class="text-center text-shadow-left font-bold md:text-[55px] md:text-[45px] text-[36px] mx-auto mb-[20px]"
        >
          ¡Lo logramos!
        </div>
      </div>
      <!-- <div class="relative shadow-custom" *ngIf="dataPayments?.is_active">
        <div class="bg-[#ffffff] p-[20px]">
          <div class="flex items-center justify-center md:mb-[20px]">
            <div class="md:w-[85px] w-[55px]">
              <img
                src="../../../../../assets/images/lateral_yellow_left_short.png"
                class="w-full"
              />
            </div>
            <div
              class="text-[#E30613] md:text-[40px] text-[20px] px-[20px] font-quicksand font-bold"
            >
              Así vamos:
            </div>
            <div class="md:w-[85px] w-[55px]">
              <img
                src="../../../../../assets/images/lateral_yellow_right_short.png"
                class="w-full"
              />
            </div>
          </div>
          <img
            src="../../../../../assets/images/separator_horizontal_yellow.png"
            class="w-full"
          />
          <div
            class="text-center text-shadow-left font-bold text-[105px] max-[900px]:text-[30px] mb-0 text-[#000000]"
          >
            {{
              dataPayments?.collected_amount
                | currency : "PEN" : "symbol" : "1.2-2" : "es-PE"
            }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
