<div class="relative py-[40px] overflow-hidden">
  <img
    src="../../../../../assets/images/flor_rosada.webp"
    class="absolute top-[-30px] md:left-[-30px] left-[-80px] z-[-1] w-[130px]"
  />
  <img
    src="../../../../../assets/images/flor_verde.png"
    class="absolute md:block hidden bottom-[-50px] right-[-50px] z-[-1]"
  />
  <div class="max-w-screen-short mx-auto">
    <div
      class="relative flex items-center font-quicksand md:mb-[40px] mb-[20px] md:px-0 px-[20px] justify-between"
    >
      <div class="md:mr-[20px] mr-[5px] relative">
        <img
          src="../../../../../assets/images/lateral_blue_left.png"
          class="md:w-auto w-[40px]"
        />
      </div>
      <div
        class="md:text-[30px] text-[20px] text-center"
        [innerHTML]="data?.donation_sites_text"
      ></div>
      <div class="md:ml-[20px] ml-[5px] relative">
        <img
          src="../../../../../assets/images/lateral_blue_right.png"
          class="md:w-auto w-[40px]"
        />
      </div>
    </div>
    <div
      class="flex flex-wrap items-center gap-[20px] justify-center md:px-0 px-[20px]"
    >
      <ng-container *ngFor="let logo of data && data.donation_sites_logos">
        <img
          [src]="logo.logo"
          [alt]="logo.name"
          class="md:w-auto w-[45%] h-auto"
        />
      </ng-container>
    </div>
  </div>
</div>
