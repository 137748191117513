import { Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { DonationComponent } from "./pages/donation/donation.component";
import { ThanksYouComponent } from "./pages/thanks-you/thanks-you.component";
import { ErrorComponent } from "./pages/error/error.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { HowToAccessComponent } from "./pages/how-to-access/how-to-access.component";
import { VolunteersComponent } from "./pages/volunteers/volunteers.component";
import { WhatDoWeDoComponent } from "./pages/what-do-we-do/what-do-we-do.component";
import { TelesponsorsComponent } from "./pages/telesponsors/telesponsors.component";
import { DonateComponent } from "./pages/telesponsors/donate/donate.component";
import { RendersAccountComponent } from "./pages/renders-account/renders-account.component";
import { NewsComponent } from "./pages/news/news.component";
import { NewsSlugComponent } from "./pages/news/news-slug/news-slug.component";

export const routes: Routes = [
  {
    path: "",
    title: "Teletón nos une - Teleton peru",
    component: HomeComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "donativo/donar-ahora",
    title: "Teletón - Donación",
    component: DonationComponent,
    data: { showFooter: false, showHeader: false },
  },
  {
    path: "sobre-nosotros",
    title: "Sobre Nosotros - Teletón",
    component: AboutUsComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "como-acceder",
    title: "Cómo Acceder - Teletón",
    component: HowToAccessComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "ser-voluntario",
    title: "Ser Voluntario - Teletón",
    component: VolunteersComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "que-hacemos",
    title: "Qué Hacemos - Teletón",
    component: WhatDoWeDoComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "telepadrinos",
    title: "Telepadrinos - Teletón",
    component: TelesponsorsComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "telepadrinos/donar",
    title: "Donar - Teletón",
    component: DonateComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "rinde-cuentas",
    title: "Rinde cuentas - Teletón",
    component: RendersAccountComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "noticias",
    title: "Noticias - Teletón",
    component: NewsComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "noticias/:slug",
    title: "Noticias - Teletón",
    component: NewsSlugComponent,
    data: { showFooter: true, showHeader: true },
  },
  {
    path: "gracias",
    title: "Teletón - Muchas Gracias",
    component: ThanksYouComponent,
    data: { showFooter: false, showHeader: false },
  },
  {
    path: "error",
    title: "Teletón - Error",
    component: ErrorComponent,
    data: { showFooter: false, showHeader: false },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
    component: HomeComponent,
    data: { showFooter: true, showHeader: true },
  },
];
