import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-hover-image",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./hover-image.component.html",
  styleUrl: "./hover-image.component.css",
})
export class HoverImageComponent {
  @Input() imageUrl: string = "";
  @Input() hoverImageUrl: string = "";
  @Input() customClass: string = "";

  isHovering = false;

  showHoverImage() {
    this.isHovering = true;
  }

  hideHoverImage() {
    this.isHovering = false;
  }
}
