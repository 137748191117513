<div class="tabs-zones flex max-[992px]:block font-nunito">
  <div class="w-1/6 max-[992px]:w-full max-[992px]:mb-[20px]">
    <div
      *ngFor="let tab of tabsData; let i = index"
      (click)="selectTab(i)"
      [class.active]="activeTab === i"
      class="tab-button relative md:text-[21px] text-[15px] text-[#043D57] max-[992px]:text-center font-semibold rounded-[5px] bg-[#ffffff] cursor-pointer py-[5px] px-[20px] mb-[10px] max-[992px]:mb-[3px] border"
    >
      {{ tab.nombre }}
    </div>
  </div>

  <div class="w-5/6 pl-[25px] max-[992px]:pl-0 max-[992px]:w-full">
    <div *ngIf="tabsData[activeTab]">
      <div class="mb-[20px] text-[#ffffff]">
        <div class="relative mb-[7px]">
          <span class="font-black">Dirección: </span>
          <span [innerHTML]="tabsData[activeTab].direccion"></span>
        </div>
        <div class="relative mb-[20px]">
          <span class="font-black">Contacto: </span>
          <span [innerHTML]="tabsData[activeTab].contacto"></span>
        </div>
        <iframe
          [src]="safeUrl"
          class="w-full h-[360px] border-[3px] border-solid border-[#fc1821] rounded-[20px]"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</div>
