<div
  [class]="
    'font-nunito text-[40px] max-[992px]:text-[30px] font-black max-[992px]:text-center max-[992px]:leading-[100%] animate__animated animate__delay-0.5s animate__fadeInUp' +
    ' ' +
    color +
    ' ' +
    customClass
  "
>
  {{ title }}
</div>
