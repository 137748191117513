import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-custom-radio",
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: "./custom-radio.component.html",
  styleUrl: "./custom-radio.component.css",
})
export class CustomRadioComponent {
  @Output() selectionChange = new EventEmitter<string>();
  selectedValue: string = "";

  onSelectionChange(value: string) {
    this.selectedValue = value;
    this.selectionChange.emit(this.selectedValue);
  }

  isSelected(value: string): boolean {
    return this.selectedValue === value;
  }
}
