<section class="volunteers font-nunito">
  <app-banner-layout
    [title]="'Quiero ser Voluntario'"
    [imgDesktop]="'../../../assets/images/head-volunteers.png'"
    [imgMobile]="'../../../assets/images/head-volunteers.png'"
  ></app-banner-layout>
  <div class="relative py-[50px]">
    <img
      src="../../../assets/images/circle-top-right.png"
      class="absolute top-0 right-0 w-[25%] h-auto"
    />
    <img
      src="../../../assets/images/circle-center-left.png"
      class="absolute m-auto top-0 bottom-0 left-0 w-[10%] h-auto"
    />
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <div class="md:flex items-center gap-[40px] mb-[40px]">
        <div class="md:w-1/2 max-[992px]:mb-[40px]">
          <app-base-title
            title="¿Qué es ser Voluntario?"
            color="text-[#FC1821]"
            customClass="md:mb-[10px] mb-[20px]"
          ></app-base-title>
          <div
            class="text-[#043D57] text-[25px] max-[992px]:text-[16px] font-nunito text-justify md:mb-[40px] mb-[20px]"
          >
            Ser Voluntario implica integrarse activamente al qué hacer
            institucional, con un corazón abierto a compartir, una mente
            dispuesta a discernir y motivación para trabajar en beneficio de las
            niñas y niños Teletón.
          </div>
          <a
            href="https://forms.gle/PmWhH5xbzvNrTF9U6"
            target="_blank"
            class="elementor-animation-float block font-nunito w-max no-underline text-[30px] font-black bg-[#043D57] text-[#ffffff] rounded-[20px] py-[15px] px-[30px] max-[992px]:py-[10px] max-[992px]:mx-auto"
            >Inscríbete aquí</a
          >
        </div>
        <div
          class="md:w-1/2 animate__animated animate__delay-0.5s animate__fadeInRight"
        >
          <img src="../../../assets/images/sv-1.png" class="w-full" />
        </div>
      </div>
      <div class="relative">
        <app-base-title
          title="La misión del Voluntariado Teletón es:"
          color="text-[#FC1821]"
          customClass="md:mb-[40px] mb-[20px] text-center"
        ></app-base-title>
        <div class="md:flex gap-[40px]">
          <div class="md:w-1/2">
            <div
              class="relative h-[180px] max-[992px]:h-[100px] mb-[30px] max-[992px]:mb-[15px] animate__animated animate__delay-0.5s"
              [appAnimateOnScroll]="'animate__zoomIn'"
            >
              <img
                src="../../../assets/images/sv-2.png"
                class="absolute h-full w-full object-contain object-center"
              />
            </div>
            <div class="flex">
              <div class="mr-[20px]">
                <div
                  class="w-[8px] h-[8px] rounded-full bg-[#043D57] mt-[10px]"
                ></div>
              </div>
              <div
                class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px]"
              >
                Promover la participación e inclusión social de niñas y niños
                Teletón, a través de la vinculación de la institución con la
                comunidad, con el fin de aportar en la construcción de un país
                más justo, igualitario e inclusivo para las personas en
                situación de discapacidad.
              </div>
            </div>
          </div>
          <div class="md:w-1/2">
            <div
              class="relative h-[180px] max-[992px]:h-[100px] mb-[30px] max-[992px]:mb-[15px] animate__animated animate__delay-0.5s"
              [appAnimateOnScroll]="'animate__zoomIn'"
            >
              <img
                src="../../../assets/images/sv-3.png"
                class="absolute h-full w-full object-contain object-center"
              />
            </div>
            <div class="flex">
              <div class="mr-[20px]">
                <div
                  class="w-[8px] h-[8px] rounded-full bg-[#043D57] mt-[10px]"
                ></div>
              </div>
              <div
                class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px]"
              >
                Promover y crear espacios de encuentro y colaboración entre
                voluntarios, niñas, niños, jóvenes, familias y la comunidad en
                general, a través de proyectos participativos de la Teletón.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative md:py-[60px] py-[50px] bg-[#FC1821]">
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <div class="md:flex gap-[40px]">
        <div
          class="md:w-1/2 relative max-[992px]:h-[210px] max-[992px]:mb-[50px] animate__animated animate__delay-0.5s"
          [appAnimateOnScroll]="'animate__fadeInLeft'"
        >
          <img
            src="../../../assets/images/sv-4.webp"
            class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
          />
        </div>
        <div class="md:w-1/2">
          <div
            class="text-[40px] font-black text-[#ffffff] animate__animated animate__delay-0.8s"
            [appAnimateOnScroll]="'animate__fadeInUp'"
          >
            Los valores del
          </div>
          <div
            class="text-[70px] max-[992px]:text-[50px] font-black text-[#ffffff] leading-[100%] mb-[20px] animate__animated animate__delay-0.5s"
            [appAnimateOnScroll]="'animate__fadeInUp'"
          >
            Voluntariado Teletón son:
          </div>
          <div class="flex mb-[10px] max-[992px]:mb-[20px]">
            <img
              src="../../../assets/images/icon-check-red.png"
              class="w-auto max-[992px]:w-[25px] max-[992px]:h-full mr-[10px]"
            />
            <div class="text-[#ffffff] text-[24px] max-[992px]:text-[16px]">
              Respeto y valoración de la diversidad.
            </div>
          </div>
          <div class="flex mb-[10px] max-[992px]:mb-[20px]">
            <img
              src="../../../assets/images/icon-check-red.png"
              class="w-auto max-[992px]:w-[25px] max-[992px]:h-full mr-[10px]"
            />
            <div class="text-[#ffffff] text-[24px] max-[992px]:text-[16px]">
              Compromiso de trabajo responsable.
            </div>
          </div>
          <div class="flex mb-[10px] max-[992px]:mb-[20px]">
            <img
              src="../../../assets/images/icon-check-red.png"
              class="w-auto max-[992px]:w-[25px] max-[992px]:h-full mr-[10px]"
            />
            <div class="text-[#ffffff] text-[24px] max-[992px]:text-[16px]">
              Alegría y calidez.
            </div>
          </div>
          <div class="flex mb-[10px] max-[992px]:mb-[20px]">
            <img
              src="../../../assets/images/icon-check-red.png"
              class="w-auto max-[992px]:w-[25px] max-[992px]:h-full mr-[10px]"
            />
            <div class="text-[#ffffff] text-[24px] max-[992px]:text-[16px]">
              Trabajo en equipo.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative pt-[60px]">
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <div class="md:flex items-center gap-[40px] mb-[60px]">
        <div class="md:w-1/2">
          <app-base-title
            title="Requisitos de ingreso al Voluntariado Teletón"
            color="text-[#FC1821]"
            customClass="md:mb-[10px] mb-[20px]"
          ></app-base-title>
          <div class="flex mb-[20px] max-[992px]:mb-[10px]">
            <div class="mr-[20px]">
              <div
                class="w-[8px] h-[8px] rounded-full bg-[#043D57] mt-[10px]"
              ></div>
            </div>
            <div
              class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px]"
            >
              Tener 18 años cumplidos al momento de la postulación.
            </div>
          </div>
          <div class="flex mb-[20px] max-[992px]:mb-[10px]">
            <div class="mr-[20px]">
              <div
                class="w-[8px] h-[8px] rounded-full bg-[#043D57] mt-[10px]"
              ></div>
            </div>
            <div
              class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px]"
            >
              Asistir a los talleres de inducción y bienvenida virtual y/o
              presencial.
            </div>
          </div>
          <div class="flex mb-[20px] max-[992px]:mb-[10px]">
            <div class="mr-[20px]">
              <div
                class="w-[8px] h-[8px] rounded-full bg-[#043D57] mt-[10px]"
              ></div>
            </div>
            <div
              class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px]"
            >
              Disponer de un tiempo mínimo para participar de cuatro horas
              distribuidas en la semana.
            </div>
          </div>
        </div>
        <div
          class="md:w-1/2 animate__animated animate__delay-0.5s animate__fadeInRight"
          [appAnimateOnScroll]="'animate__fadeInRight'"
        >
          <img src="../../../assets/images/sv-5.png" class="w-full" />
        </div>
      </div>
      <app-base-title
        title="Conoce cómo se vive el Voluntariado Teletón"
        color="text-[#FC1821]"
        customClass="md:mb-[40px] mb-[20px] text-center"
      ></app-base-title>
      <div class="relative mb-[20px]">
        <iframe
          class="w-full max-[992px]:w-[97%] h-[650px] max-[992px]:h-[200px] rounded-[50px] max-[992px]:rounded-[15px] border-[7px] border-solid border-[#FC1821]"
          src="https://www.youtube.com/embed/JIG3P3-GRDE?si=de5tILpMGHIaoxkk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div
        class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito mb-[10px] text-center mb-[20px]"
      >
        El Voluntariado Teletón está lleno de experiencias inolvidables hechas
        para ayudar a la rehabilitación de miles de niñas y niños con
        discapacidad.
      </div>
      <div
        class="text-[#043D57] font-bold text-center text-[35px] max-[992px]:text-[21px] max-[992px]:leading-[100%]"
      >
        ¡Tú también puedes formar parte del cambio!
      </div>
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
