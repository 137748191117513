<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<section class="telesponsors">
  <div
    class="relative h-[650px] max-[992px]:h-[700px] max-[768px]:h-full z-[0] flex items-center justify-center"
  >
    <picture>
      <source
        media="(max-width:920px)"
        srcset="../../../assets/images/hero-telepadrinos.webp"
      />
      <source
        media="(max-width:1024px)"
        srcset="../../../assets/images/hero-telepadrinos.webp"
      />
      <img
        src="../../../assets/images/hero-telepadrinos.webp"
        class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
      />
    </picture>
    <div
      class="max-w-screen-large mx-auto w-full max-[992px]:px-[0px] relative z-[1] h-full"
    >
      <div class="md:flex items-center">
        <div
          class="md:w-1/2 mt-[120px] max-[992px]:mt-[120px] max-[768px]:mt-[60px] max-[992px]:px-[20px]"
        >
          <div
            class="w-auto text-[40px] rounded-[20px] border-[10px] border-solid border-[#ffffff] leading-[120%] relative z-[2] overflow-hidden font-lilita uppercase px-[20px] py-[10px] mb-[20px]"
          >
            <div class="bg-overlay-title bg-overlay-title-transparent"></div>
            Descubre nuestra nueva opción para donar
          </div>
          <div
            class="text-[20px] text-[#000000] font-montserrat font-semibold leading-[130%] mb-[20px]"
          >
            Este dinero está destinado a niñas y niños vulnerables con
            discapacidad en todo el país.
            <span class="font-extrabold bg-[#FAB530]"
              >Con tu aporte, estarás contribuyendo a su rehabilitación.</span
            >
            Recuerda, entre más aportes, más terapias tendrán.
          </div>
          <div
            class="text-[30px] text-[#000000] font-montserrat font-extrabold leading-[110%] mb-[20px]"
          >
            ¡Únete y ayuda a transformar vidas hoy mismo!
          </div>
          <a
            (click)="scrollToSection('dona-aqui')"
            target="_blank"
            class="flex font-nunito justify-center w-[150px] py-[15px] px-[0px] text-[20px] text-[#ffffff] bg-[#E30613] no-underline rounded-[20px] font-bold box-shadow-right cursor-pointer"
            >¡Hazlo aquí!</a
          >
        </div>
      </div>
      <img
        src="../../../assets/images/hero-image-telepadrinos.webp"
        class="absolute max-[992px]:absolute max-[992px]:w-[450px] max-[768px]:relative w-[540px] bottom-0 right-0 z-[1] max-w-full h-auto max-[992px]:align-bottom"
      />
    </div>
  </div>
  <div class="relative">
    <div
      class="max-w-screen-large mx-auto w-full relative z-[1] py-[50px] max-[992px]:pt-[30px] max-[992px]:pb-[0px]"
    >
      <div
        class="flex max-[992px]:flex-wrap-reverse items-center md:flex-row-reverse my-[20px] max-[992px]:mb-[0px] max-[992px]:justify-center justify-normal"
      >
        <img
          src="../../../assets/images/first_image-1024x844.webp"
          class="absolute max-[992px]:absolute max-[992px]:relative w-[540px] bottom-0 left-0 z-[1] max-w-full h-auto"
        />
        <div class="md:w-1/2 max-[992px]:px-[20px] max-[992px]:grid">
          <div
            class="w-auto text-[40px] max-[992px]:text-[30px] rounded-[20px] border-[10px] border-solid border-[#7D367F] leading-[110%] relative z-[2] overflow-hidden font-lilita uppercase px-[20px] py-[10px] mb-[20px] text-right"
          >
            <div class="bg-overlay-title bg-overlay-title-blue"></div>
            Transformando Vidas:
            <span class="text-[0.75em] leading-[110%] block"
              >El Impacto de Teletón en la Rehabilitación Infantil</span
            >
          </div>
          <div
            class="text-[20px] text-[#000000] font-montserrat font-semibold leading-[130%] text-right"
          >
            En los últimos 5 años,
            <span class="font-bold bg-[#decddf]"
              >Teletón ha brindado atención a más de 4033 niños</span
            >
            de todo el Perú en situación de discapacidad, proporcionando
            <span class="font-bold bg-[#decddf]"
              >más de 681,269 atenciones médicas especializadas</span
            >
            para su rehabilitación física y motora. Estas terapias no solo
            representan tratamiento, sino vida, un futuro y la posibilidad de
            inclusión para cada niña o niño.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative py-[70px]">
    <img
      src="../../../assets/images/fondo-hoja-naranja.webp"
      class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
    />
    <div class="max-w-screen-large mx-auto w-full relative z-[1] h-full">
      <div
        class="md:flex items-center md:p-[35px] p-0 max-[992px]:py-[30px] border-[10px] border-solid border-[#DE1318] rounded-[20px]"
      >
        <div class="bg-overlay-title bg-overlay-title-red"></div>
        <iframe
          class="w-full md:h-[600px] h-[180px]"
          src="https://www.youtube.com/embed/0ylf6iu-gkw?si=ZnWb00igyy0DNGpQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <div class="relative">
    <div
      class="max-w-screen-large mx-auto w-full relative z-[1] pt-[50px] md:pb-[50px] pb-[0px]"
    >
      <div
        class="flex max-[768px]:flex-wrap-reverse items-center md:mt-[20px] md:mb-[20px] mb-[0px] max-[992px]:px-[20px] max-[768px]:px-[0px]"
      >
        <img
          src="../../../assets/images/unete-image.webp"
          class="absolute max-[992px]:absolute max-[768px]:relative w-[540px] max-[992px]:w-[400px] max-[768px]:w-[540px] bottom-0 right-0 z-[1] max-w-full h-auto"
        />
        <div class="md:w-1/2 md:px-0 px-[20px]">
          <div
            class="w-auto text-[40px] rounded-[20px] border-[10px] border-solid border-[#62A25D] leading-[110%] relative z-[2] overflow-hidden font-lilita px-[20px] py-[10px] mb-[20px]"
          >
            <div class="bg-overlay-title bg-overlay-title-green"></div>
            ¡Únete a Telepadrinos!
          </div>
          <div
            class="text-[20px] text-[#000000] font-montserrat font-semibold leading-[130%]"
          >
            Descubre una nueva forma de impactar vidas con Telepadrinos.
            <span class="font-bold bg-[#c0dabe]"
              >Con tu aporte mensual, serás parte del cambio, brindando salud,
              bienestar y amor a niños que lo necesitan.</span
            >
            Tu contribución mensual permitirá que un niño reciba terapias
            físicas vitales para su desarrollo y felicidad. <br />
            <br />
            ¡Cada donación cuenta! ¡Conviértete en un
            <span class="font-bold bg-[#c0dabe]"
              >héroe del cambio en Telepadrinos</span
            >
            y deja tu huella en el corazón de cada niño que ayudamos!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative py-[70px] md:px-0 px-[20px]" id="dona-aqui">
    <img
      src="../../../assets/images/fondo-hoja-rosado.webp"
      class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
    />
    <div
      class="relative text-[30px] text-[#000000] mb-[20px] font-nunito z-[1] md:w-[400px] mx-auto font-bold leading-[100%] max-[992px]:text-center"
    >
      A partir de ahora podrás elegir tu aporte mensual:
    </div>
    <div class="max-w-screen-large mx-auto w-full relative z-[1] h-full">
      <div
        class="md:flex items-center p-[35px] max-[992px]:p-[30px] border-[10px] border-solid border-[#DE1318] rounded-[20px]"
      >
        <div class="bg-overlay-title bg-overlay-title-red"></div>
        <div
          class="px-[20px] py-[45px] max-[992px]:py-[20px] w-full bg-[#ffffff] rounded-[18px] box-border"
        >
          <div class="text-[40px] font-lilita mb-[20px] text-center">
            Dona aquí
          </div>
          <div
            class="text-[20px] text-[#000000] font-semibold text-center font-nunito md:mb-[40px] mb-[20px]"
          >
            Tu contribución es vital para nuestros niños, garantizando el acceso
            a su rehabilitacion continua
            <br /><br />
            Gracias por brindarles oportunidades de desarrollo significativas
          </div>

          <div class="mb-[20px]">
            <app-custom-radio
              (selectionChange)="onRadioSelectionChange($event)"
            >
              <label
                class="custom-radio"
                (click)="onRadioSelectionChange('40')"
                [class.selected]="isSelected('40')"
              >
                <input type="radio" value="40" class="hidden" />
                <div class="flex justify-between w-full">
                  <div class="relative">
                    <div class="text-[38px] font-lilita">S/ 40</div>
                    <div class="text-[18px] font-lilita">Mensual</div>
                  </div>
                  <img
                    src="../../../assets/images/hand-growing.svg"
                    class="w-auto"
                  />
                </div>
              </label>

              <label
                class="custom-radio"
                (click)="onRadioSelectionChange('60')"
                [class.selected]="isSelected('60')"
              >
                <input type="radio" value="60" class="hidden" />
                <div class="flex justify-between w-full">
                  <div class="relative">
                    <div class="text-[38px] font-lilita">S/ 60</div>
                    <div class="text-[18px] font-lilita">Mensual</div>
                  </div>
                  <img
                    src="../../../assets/images/hands-hearth.svg"
                    class="w-auto"
                  />
                </div>
              </label>

              <label
                class="custom-radio"
                (click)="onRadioSelectionChange('90')"
                [class.selected]="isSelected('90')"
              >
                <input type="radio" value="90" class="hidden" />
                <div class="flex justify-between w-full">
                  <div class="relative">
                    <div class="text-[38px] font-lilita">S/ 90</div>
                    <div class="text-[18px] font-lilita">Mensual</div>
                  </div>
                  <img
                    src="../../../assets/images/hold-hands.svg"
                    class="w-auto"
                  />
                </div>
              </label>
            </app-custom-radio>
          </div>
          <div class="flex items-center justify-center">
            <button
              class="block border-0 w-[180px] md:py-[20px] py-[15px] px-[20px] text-[#ffffff] text-[20px] bg-[#E30613] no-underline rounded-[20px] font-semibold font-quicksand cursor-pointer"
              [ngClass]="{
                'opacity-50 pointer-events-none cursor-not-allowed':
                  !selectedValue
              }"
              (click)="redirectDonate()"
            >
              ¡Dona Ahora!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <div
      class="max-w-screen-large mx-auto w-full max-[992px]:px-[0px] relative z-[1]"
    >
      <div class="md:flex items-center">
        <div class="md:w-1/2">
          <img
            *ngIf="!isMobile"
            src="../../../assets/images/Imagen-00-9-2.webp"
            class="flex md:w-[440px] mx-auto"
          />
          <img
            *ngIf="isMobile"
            src="../../../assets/images/Imagen-00-9-3.webp"
            class="w-auto mx-auto flex"
          />
        </div>
        <div class="md:w-1/2 md:px-0 px-[20px]">
          <div
            class="w-auto text-[38px] rounded-[20px] border-[10px] border-solid border-[#DF682E] leading-[110%] relative z-[2] overflow-hidden font-lilita px-[20px] py-[10px] mb-[20px]"
          >
            <div class="bg-overlay-title bg-overlay-title-pink"></div>
            ¡Gracias por tu generosidad y solidaridad sigue siendo parte de esta
            increíble misión!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative bg-[#043D57]">
    <div
      class="max-w-screen-large mx-auto w-full max-[992px]:px-[20px] relative z-[1] p-[20px] box-border"
    >
      <div class="text-[#ffffff] text-[18px] font-bold text-center font-nunito">
        Si tienes alguna consulta, comunícate con nosotros al correo:
        <a
          href="mailto:telepadrinos@old1.teleton.pe"
          class="no-underline text-[#ffffff]"
          >telepadrinos&#64;old1.teleton.pe</a
        >
      </div>
    </div>
  </div>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
