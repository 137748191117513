import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { AccordionService } from "./service/accordion.service";

@Component({
  selector: "app-accordion",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./accordion.component.html",
  styleUrl: "./accordion.component.css",
})
export class AccordionComponent implements OnInit {
  @Input() title: string = "";
  @Input() index: number = 0;
  @Input() defaultOpen: boolean = false;
  isOpen: boolean = false;

  constructor(private accordionService: AccordionService) {}

  ngOnInit() {
    if (this.defaultOpen) {
      this.isOpen = true;
      this.accordionService.openAccordion(this.index);
    }
    this.accordionService.getOpenedAccordion().subscribe((openedIndex) => {
      this.isOpen = openedIndex === this.index;
    });
  }

  toggleAccordion() {
    if (this.isOpen) {
      this.accordionService.openAccordion(null!);
    } else {
      this.accordionService.openAccordion(this.index);
    }
  }
}
