<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<img
  *ngIf="(isMobile && data?.banner_img_mobile) || data?.banner_img_desktop"
  [src]="isMobile ? data?.banner_img_mobile : data?.banner_img_desktop"
  (load)="onImageLoad()"
  style="display: none"
  alt="background image"
/>
<section
  class="bg-banner relative md:h-[700px] h-[850px] transition-3s"
  [ngStyle]="{
    'background-image': bannerLoaded
      ? 'url(' +
        (isMobile ? data?.banner_img_mobile : data?.banner_img_desktop) +
        ')'
      : '',
    'background-size': 'cover',
    'background-position': isMobile ? '64% 2%' : 'center',
    'background-repeat': 'no-repeat'
  }"
>
  <div class="block md:py-[20px] px-[20px] py-[10px]">
    <div class="flex justify-between w-full">
      <div class="relative">
        <picture>
          <source
            [src]="data && data.banner_logo"
            media="(max-width: 1023px)"
          />
          <img
            class="max-[1560px]:w-[180px] max-[900px]:w-[170px] w-[280px]"
            [src]="data && data.banner_logo"
          />
        </picture>
      </div>
    </div>
  </div>
  <div class="relative md:w-1/3 w-full md:h-auto h-full">
    <div class="flex justify-center md:h-auto h-[50vh]">
      <div class="block px-[20px]">
        <div class="relative mb-[40px] text-center">
          <picture>
            <source
              srcset="../../../../assets/images/sticker_banner_01.png"
              media="(max-width: 1023px)"
            />
            <img
              class="md:w-full w-[260px]"
              src="../../../../assets/images/sticker_banner_01.png"
            />
          </picture>
        </div>
        <div
          class="md:relative absolute m-auto md:bottom-0 bottom-[100px] left-0 right-0 md:px-0 px-[20px]"
        >
          <picture>
            <source
              srcset="../../../../assets/images/sticker_banner_02.png"
              media="(max-width: 1023px)"
            />
            <img
              class="w-full"
              src="../../../../assets/images/sticker_banner_02.png"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div class="max-w-screen-largeXL mx-auto"></div>
</section>
