import { CommonModule } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";
import { ModalComponent } from "../../../../components/modal/modal.component";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { DeviceDetectorComponent } from "../../../../components/device/device.component";

@Component({
  selector: "app-section-stories",
  standalone: true,
  imports: [CommonModule, ModalComponent, DeviceDetectorComponent],
  templateUrl: "./section-stories.component.html",
  styleUrl: "./section-stories.component.css",
})
export class SectionStoriesComponent {
  isMobile: boolean = false;
  @Input() dataHome?: any;
  data: any;
  public isOpen: boolean = false;
  public videoUrl: SafeResourceUrl = "";

  constructor(private sanitizer: DomSanitizer) {}

  stories = [
    {
      imgSrc: "../../../../../assets/images/img_stories_01.jpg",
      videoUrl: "https://www.youtube.com/embed/gNJQf6fCyKY?si=OFfMd8GbpyN4qagP",
    },
    {
      imgSrc: "../../../../../assets/images/img_stories_02.jpg",
      videoUrl: "https://www.youtube.com/embed/YMM3qFnZwOU?si=KUXfBijJU90cR7aa",
    },
    {
      imgSrc: "../../../../../assets/images/img_stories_03.jpg",
      videoUrl: "https://www.youtube.com/embed/66fVdDw-Li0?si=mCP-AGcywQKqeMPt",
    },
    {
      imgSrc: "../../../../../assets/images/img_stories_04.jpg",
      videoUrl: "https://www.youtube.com/embed/jGE_KGbmohg?si=RXEG8mcohTDOQcNh",
    },
  ];

  openModal(videoId: string) {
    this.videoUrl = "";
    setTimeout(() => {
      const url = `${videoId}`;
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.isOpen = true;
    }, 10);
  }

  closeModal() {
    this.isOpen = false;
    setTimeout(() => {
      this.videoUrl = "";
    }, 300);
  }

  onBackdropClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const modalContent = document.querySelector(".modal-content");

    if (modalContent && !modalContent.contains(target)) {
      this.closeModal();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataHome !== undefined) {
      this.data = changes["dataHome"].currentValue;
    }
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }
}
