import { Component, OnInit, AfterViewInit } from "@angular/core";
import { BannerComponent } from "../../components/banner/banner.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SectionMetaComponent } from "./sections/section-meta/section-meta.component";
import { SectionChannelsComponent } from "./sections/section-channels/section-channels.component";
import { SectionStoresComponent } from "./sections/section-stores/section-stores.component";
import { SectionThanksYouComponent } from "./sections/section-thanks-you/section-thanks-you.component";
import { SectionRindeComponent } from "./sections/section-rinde/section-rinde.component";
import { SectionStoriesComponent } from "./sections/section-stories/section-stories.component";
import { SectionGratitudeComponent } from "./sections/section-gratitude/section-gratitude.component";
import { HomePageService } from "./services/home.service";
import { SharedDataService } from "../../layout/shared/shared-data.service";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { AccordionComponent } from "../../components/accordion/accordion.component";

import "slick-carousel";
import "jquery";
import { CommonModule } from "@angular/common";
import { NewsPageService } from "../news/services/news.service";
import { convertToSlug } from "../../../utils/utils";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    CommonModule,
    BannerComponent,
    SectionMetaComponent,
    SectionChannelsComponent,
    SectionStoresComponent,
    SectionThanksYouComponent,
    SectionRindeComponent,
    SectionStoriesComponent,
    SectionGratitudeComponent,
    FormsModule,
    HttpClientModule,
    FooterComponent,
    FooterInfoComponent,
    AccordionComponent,
  ],
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  isMobile: boolean = false;
  public dataHome: any;
  public dataNews: any[] = [];
  public selectedNewsList: any[] = [];
  public processedNewsList: any[] = [];
  public sanitizedNewsContentList: SafeHtml[] = [];

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private homeDataService: HomePageService,
    private sharedDataService: SharedDataService,
    private newsDataService: NewsPageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.sharedDataService.changeData(this.dataHome);

      this.metaService.updateTag({
        name: "description",
        content: this.dataHome.seo_description,
      });
      this.titleService.setTitle(this.dataHome.seo_title);

      const newsIds = [
        this.dataHome.home_news1,
        this.dataHome.home_news2,
        this.dataHome.home_news3,
        this.dataHome.home_news4,
        this.dataHome.home_news5,
      ];

      this.loadNewsByIds(newsIds);

      setTimeout(() => {
        ($(".home-historias-video-slick") as any).slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          dots: true,
          arrows: false,
        });

        ($(".home-banner-slick") as any).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          dots: false,
          arrows: true,
        });
      }, 100);
    });

    window.addEventListener("scroll", this.handleScroll);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  private loadNewsByIds(newsIds: number[]): void {
    this.newsDataService.getNews().subscribe((data) => {
      this.dataNews = data.results;

      this.selectedNewsList = this.dataNews.filter((newsItem: any) =>
        newsIds.includes(newsItem.id)
      );

      this.selectedNewsList.sort((a, b) => {
        const indexA = newsIds.indexOf(a.id);
        const indexB = newsIds.indexOf(b.id);

        return indexA - indexB;
      });

      this.processedNewsList = this.selectedNewsList.map((newsItem) => ({
        ...newsItem,
        sanitizedTitle: this.sanitizer.bypassSecurityTrustHtml(newsItem.title),
      }));
    });
  }

  handleScroll = () => {
    const button = document.querySelector(".floating-button") as HTMLElement;
    if (button) {
      if (window.scrollY > 0) {
        button.classList.add("scrolled");
        button.classList.remove("initial");
      } else {
        button.classList.add("initial");
        button.classList.remove("scrolled");
      }
    }
  };

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  convertToSlug(title: string): string {
    return convertToSlug(title);
  }
}
