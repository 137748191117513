import { Component } from '@angular/core';

@Component({
  selector: 'app-section-thanks-you',
  standalone: true,
  imports: [],
  templateUrl: './section-thanks-you.component.html',
  styleUrl: './section-thanks-you.component.css'
})
export class SectionThanksYouComponent {

}
