import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { HttpClientModule } from "@angular/common/http"; // Import HttpClientModule

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LayoutComponent,
    HttpClientModule, // Add HttpClientModule here
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"], // Ensure this is 'styleUrls' for multiple styles
})
export class AppComponent {
  title = "atk-teleton";
}
