<div class="relative py-[40px] md:px-[40px] px-[20px] font-quicksand">
  <div class="max-w-screen-largeXL mx-auto">
    <div
      class="md:flex block items-center justify-center max-[900px]:w-full w-max mx-auto"
    >
      <div
        class="md:text-[30px] text-[24px] font-semibold md:text-left text-center md:mb-0 mb-[20px]"
      >
        Conoce más detalles de nuestro Rinde cuentas aquí:
      </div>
      <a
        href="https://online.pubhtml5.com/byjvy/qhpq/"
        target="_blank"
        class="flex justify-center w-[170px] p-[20px] text-[24px] text-[#ffffff] bg-[#E30613] no-underline rounded-[20px] font-bold md:ml-[20px] mx-auto box-shadow-right"
        >Ver más</a
      >
    </div>
  </div>
</div>
