import { CommonModule } from "@angular/common";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FooterStaticComponent } from "../../../layout/components/footer-static/footer-static.component";
import { FloatingButtonComponent } from "../../../layout/components/floating-button/floating-button.component";
import { HomePageService } from "../../home/services/home.service";
import { CustomRadioComponent } from "../../../components/custom-radio/custom-radio.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SectionGratitudeComponent } from "../../home/sections/section-gratitude/section-gratitude.component";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-donate",
  standalone: true,
  imports: [
    CommonModule,
    FooterStaticComponent,
    FloatingButtonComponent,
    CustomRadioComponent,
    ReactiveFormsModule,
    SectionGratitudeComponent,
  ],
  templateUrl: "./donate.component.html",
  styleUrl: "./donate.component.css",
})
export class DonateComponent implements OnInit {
  public dataFooter?: any;
  public dataGratitude: any;
  public postAmmount: string = "";
  selectedValue: string = "";
  selectedDni: string = "DNI";
  formDonate: FormGroup;

  name: string = "";
  lastname: string = "";
  email: string = "";
  amount: number | null = null;
  phone: string = ""; // Add phone variable
  agreeToTerms: boolean = false;
  clientIp: string = ""; // Add client IP variable
  jwtToken: string | null = null; // Store JWT token here

  private backendUrl = environment.backendUrl;
  private sharedSecret = environment.sharedSecret; // Obtain shared secret from environment

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private homeDataService: HomePageService,
    private fb: FormBuilder
  ) {
    this.formDonate = this.fb.group({
      nombre: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"),
          Validators.maxLength(250),
        ],
      ],
      apellido: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$"),
          Validators.maxLength(250),
        ],
      ],
      tipoDocumento: ["", Validators.required],
      dni: ["", [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      ce: ["", [Validators.pattern("^[0-9]{1,20}$")]],
      pasaporte: ["", [Validators.pattern("^[A-Za-z0-9]{1,20}$")]],
      celular: ["", [Validators.required, Validators.pattern("^9[0-9]{8}$")]],
      correo: ["", [Validators.required, Validators.email]],
      terminos: [false, Validators.requiredTrue],
      monto: ["", Validators.required],
    });
  }

  onTipoDocumentoChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    this.selectedDni = selectedValue;

    if (selectedValue !== null) {
      const dniControl = this.formDonate.get("dni");
      const ceControl = this.formDonate.get("ce");
      const pasaporteControl = this.formDonate.get("pasaporte");

      dniControl?.setValue("");
      ceControl?.setValue("");
      pasaporteControl?.setValue("");

      if (selectedValue === "DNI") {
        dniControl?.setValidators([
          Validators.required,
          Validators.pattern("^[0-9]{8}$"),
        ]);
        ceControl?.clearValidators();
        pasaporteControl?.clearValidators();
      } else if (selectedValue === "CE") {
        dniControl?.clearValidators();
        ceControl?.setValidators([
          Validators.required,
          Validators.pattern("^[0-9]{1,20}$"),
        ]);
        pasaporteControl?.clearValidators();
      } else if (selectedValue === "PASAPORTE") {
        dniControl?.clearValidators();
        ceControl?.clearValidators();
        pasaporteControl?.setValidators([
          Validators.required,
          Validators.pattern("^[A-Za-z0-9]{1,20}$"),
        ]);
      }

      dniControl?.updateValueAndValidity();
      ceControl?.updateValueAndValidity();
      pasaporteControl?.updateValueAndValidity();
    }
  }

  getClientIp() {
    this.http
      .get<{ ip: string }>("https://api64.ipify.org?format=json")
      .subscribe(
        (response) => {
          this.clientIp = response.ip;
          //console.log("Client IP obtained:", this.clientIp);
        },
        (error) => {
          console.error("Error obtaining client IP:", error);
        }
      );
  }

  getToken(username: string, password: string): void {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    this.http
      .post<{ access: string }>(
        `${this.backendUrl}/api/token/`,
        { username, password },
        { headers }
      )
      .subscribe(
        (response) => {
          this.jwtToken = response.access;
          //console.log("Token obtained:", this.jwtToken);
        },
        (error) => {
          console.error("Error obtaining JWT Token:", error);
        }
      );
  }

  getJwtToken() {
    this.http
      .post<{ token: string }>(`${this.backendUrl}/api/get-token`, {
        shared_secret: this.sharedSecret,
      })
      .subscribe(
        (response) => {
          this.jwtToken = response.token;
          //console.log("JWT Token obtained:", this.jwtToken);
        },
        (error) => {
          console.error("Error obtaining JWT Token:", error);
        }
      );
  }

  onSubmit() {
    if (this.formDonate.valid) {
      console.log(this.formDonate.value);
    }

    if (!this.jwtToken) {
      console.error("JWT token not available");
      return;
    }

    const paymentData = {
      name: this.formDonate.value.nombre,
      lastname: this.formDonate.value.apellido,
      email: this.formDonate.value.correo,
      amount: this.formDonate.value.monto,
      phone: this.formDonate.value.celular, // Include phone in the data sent to the backend
      clientIp: this.clientIp, // Include client IP in the data sent to the backend
    };

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.jwtToken}`,
    });

    this.http
      .post(`${this.backendUrl}/api/payment/getPmtTrx/`, paymentData, {
        headers,
      })
      .subscribe((response: any) => {
        this.openVisaNetForm(response);
      });
  }

  openVisaNetForm(paymentData: any) {
    // Load the VisaNet script dynamically
    const script = this.renderer.createElement("script");
    script.src = paymentData.checkouturl;
    script.type = "text/javascript";
    script.onload = () => {
      // Log the data being sent to VisaNet
      const dataToSend = {
        sessiontoken: paymentData.sessiontoken,
        channel: "web",
        merchantid: paymentData.merchantid,
        purchasenumber: paymentData.purchasenumber,
        amount: this.amount?.toString() || "0",
        expirationminutes: paymentData.expirationminutes,
        timeouturl: paymentData.timeouturl,
        merchantlogo: paymentData.merchantlogo,
        formbuttoncolor: paymentData.formbuttoncolor,
        action: paymentData.action,
        "data-cardholdername": this.formDonate.value.nombre,
        "data-cardholderlastname": this.formDonate.value.apellido,
        "data-cardholderemail": this.formDonate.value.correo,
      };
      //console.log("Data being sent to VisaNet:", dataToSend);

      // Once the script is loaded, configure and open the VisaNet form
      (window as any).VisanetCheckout.configure({
        ...dataToSend,
        complete: function (params: any) {
          alert(JSON.stringify(params));
        },
      });
      (window as any).VisanetCheckout.open();
    };
    this.renderer.appendChild(document.body, script);
  }

  ngOnInit(): void {
    this.getClientIp(); // Get client IP on initialization
    this.getToken("payments", "Pruebas123"); // Get JWT token on initialization

    this.route.queryParams.subscribe((queryParams) => {
      this.postAmmount = queryParams["donation-amount"];

      if (this.postAmmount) {
        this.selectedValue = this.postAmmount;
        this.onRadioSelectionChange(this.postAmmount);
      } else {
        this.router.navigate(["/telepadrinos"]);
      }
    });

    this.homeDataService.getHome().subscribe((data) => {
      const dataHome = data.results[0];
      this.dataGratitude = dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: dataHome.footer_logo,
        footer_ribbon_txt: dataHome.footer_ribbon_txt,
        footer_txt: dataHome.footer_txt,
        social_networks: dataHome.social_networks,
      };
    });
  }

  onRadioSelectionChange(value: string) {
    this.selectedValue = value;
    this.formDonate.get("monto")?.setValue(this.selectedValue);
  }

  isSelected(value: string): boolean {
    return this.selectedValue === value;
  }

  onTextOnly(event: any, controlName: string) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^a-zA-ZÀ-ÿ\u00f1\u00d1 ]/g, "");
    this.formDonate.get(controlName)?.setValue(event.target.value);
  }

  onNumberOnly(event: any, controlName: string) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, "");
    this.formDonate.get(controlName)?.setValue(event.target.value);
  }

  onNumberLengthOnly(event: any, controlName: string, maxLength: number) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const validValue = numericValue.slice(0, maxLength);
    event.target.value = validValue;
    this.formDonate.get(controlName)?.setValue(validValue);
  }

  onNumberPhoneOnly(event: any, controlName: string) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    if (
      numericValue.length === 0 ||
      (numericValue.length === 1 && numericValue !== "9")
    ) {
      event.target.value = "";
      this.formDonate.get(controlName)?.setValue("");
      return;
    }

    const validValue = numericValue.slice(0, 9);

    event.target.value = validValue;
    this.formDonate.get(controlName)?.setValue(validValue);
  }
}
