import { CommonModule } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-section-gratitude",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./section-gratitude.component.html",
  styleUrl: "./section-gratitude.component.css",
})
export class SectionGratitudeComponent {
  @Input() dataHome?: any;
  data: any;

  storeLogos = [
    { src: "../../../../../assets/images/logo_promart.png", alt: "Promart" },
    { src: "../../../../../assets/images/logo_sndrt.png", alt: "" },
    { src: "../../../../../assets/images/logo_america.png", alt: "" },
    { src: "../../../../../assets/images/logo_tvpe.png", alt: "" },
    { src: "../../../../../assets/images/logo_jetsmart.png", alt: "" },
    { src: "../../../../../assets/images/logo_tierra_viva.png", alt: "" },
    { src: "../../../../../assets/images/logo_fiduciaria.png", alt: "" },
    { src: "../../../../../assets/images/logo_pwc.png", alt: "" },
    { src: "../../../../../assets/images/logo_win.png", alt: "" },
    { src: "../../../../../assets/images/logo_lxg.png", alt: "" },
    { src: "../../../../../assets/images/logo_plaza_norte.png", alt: "" },
    { src: "../../../../../assets/images/logo_payet.png", alt: "" },
  ];

  filterByGroup(group: number) {
    const dataToFilter = this.data?.sponsors_logos || this.data;
    return dataToFilter?.filter((logo: any) => logo.group === group);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataHome !== undefined) {
      this.data = changes["dataHome"].currentValue;
    }
  }
}
