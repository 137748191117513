import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BannerLayoutComponent } from "../../layout/components/banner-layout/banner-layout.component";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { FloatingButtonComponent } from "../../layout/components/floating-button/floating-button.component";
import { HomePageService } from "../home/services/home.service";
import { NewsPageService } from "./services/news.service";
import { Meta, Title } from "@angular/platform-browser";
import { DeviceDetectorComponent } from "../../components/device/device.component";
import { SliderNewsComponent } from "./slider-news/slider-news.component";

@Component({
  selector: "app-news",
  standalone: true,
  imports: [
    CommonModule,
    BannerLayoutComponent,
    FooterInfoComponent,
    FooterComponent,
    FloatingButtonComponent,
    DeviceDetectorComponent,
    SliderNewsComponent,
  ],
  templateUrl: "./news.component.html",
  styleUrl: "./news.component.css",
})
export class NewsComponent implements OnInit {
  isMobile: boolean = false;
  public dataHome?: any;
  public dataFooter?: any;
  public dataGratitude: any;
  public dataNews: any;
  public dataNewsPage: any;

  constructor(
    private homeDataService: HomePageService,
    private newsDataService: NewsPageService,
    private metaService: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.dataGratitude = this.dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: this.dataHome.footer_logo,
        footer_ribbon_txt: this.dataHome.footer_ribbon_txt,
        footer_txt: this.dataHome.footer_txt,
        social_networks: this.dataHome.social_networks,
      };
    });

    this.newsDataService.getNewsPage().subscribe((data) => {
      this.dataNewsPage = data.results[0];

      this.metaService.updateTag({
        name: "description",
        content: this.dataNewsPage.seo_description,
      });
      this.titleService.setTitle(this.dataNewsPage.seo_title);
    });

    this.newsDataService.getNews().subscribe((data) => {
      this.dataNews = data.results;
    });

    this.newsDataService.getNews().subscribe((data) => {
      this.dataNews = data.results.filter(
        (newsItem: any) =>
          newsItem.id !== this.dataNewsPage.news1 &&
          newsItem.id !== this.dataNewsPage.news2
      );
    });
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }
}
