<div class="relative py-[40px] bg-[#E01319] footer-info">
  <div class="max-w-screen-largeXL mx-auto">
    <div class="md:flex block gap-[20px] font-quicksand md:px-0 px-[20px]">
      <div class="md:w-1/4 w-full text-center md:mb-0 mb-[40px]">
        <img
          [src]="footerData && footerData.footer_logo"
          class="w-[194px] mb-[20px]"
        />
        <div class="w-[190px] font-quicksand mx-auto">
          <a
            href="/donativo/donar-ahora"
            class="block p-[18px] text-[#ffffff] bg-[#008AE9] no-underline rounded-[20px] font-bold text-[24px] box-shadow-left"
            >¡Dona Ahora!</a
          >
        </div>
      </div>
      <div
        class="md:w-1/4 w-[280px] md:text-[20px] text-[16px] font-semibold text-[#ffffff] md:mb-0 mb-[30px] md:pl-0 pl-[25px]"
      >
        <span [innerHTML]="footerData?.footer_txt">
          {{ footerData?.footer_txt }}</span
        >
      </div>
      <div class="w-1/4 max-[992px]:w-auto max-[992px]:mb-[40px]">
        <ul class="list-footer m-0 max-[992px]:px-[20px]">
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/inicio" class="no-underline">Inicio</a>
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/sobre-nosotros" class="no-underline">Sobre nosotros</a>
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/que-hacemos" class="no-underline">¿Qué hacemos?</a>
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/como-acceder" class="no-underline"
              >¿Cómo acceder a Teletón?</a
            >
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/rinde-cuentas" class="no-underline">Rinde cuentas</a>
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/telepadrinos" class="no-underline">Telepadrinos</a>
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/ser-voluntario" class="no-underline"
              >Quiero ser voluntario</a
            >
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a href="/noticias" class="no-underline"
              >Noticias</a
            >
          </li>
          <li
            class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
          >
            <a
              href="../../../../assets/files/POLITICA-DE-PRIVACIDAD-Y-PROTECCION-DE-DATOS-FUNDACION-TELETON-070623-1.pdf"
              target="_blank"
              class="no-underline"
              >Política de protección de datos</a
            >
          </li>
        </ul>
      </div>
      <div class="md:w-1/4 w-full">
        <div class="flex items-center gap-[5px] justify-center">
          <ng-container *ngFor="let item of footerData?.social_networks">
            <a [href]="item.url" target="_blank">
              <img [src]="item.logo" class="h-[23px]" />
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
