import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { TabsRendersComponent } from "./tabs-renders/tabs-renders.component";
import { RendersAccountPageService } from "./services/renders-account.service";
import { Meta, Title } from "@angular/platform-browser";
import { DeviceDetectorComponent } from "../../components/device/device.component";
import { SliderRendersComponent } from "./slider-renders/slider-renders.component";
import { SectionGratitudeComponent } from "../home/sections/section-gratitude/section-gratitude.component";
import { HomePageService } from "../home/services/home.service";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { FloatingButtonComponent } from "../../layout/components/floating-button/floating-button.component";

@Component({
  selector: "app-renders-account",
  standalone: true,
  imports: [
    CommonModule,
    TabsRendersComponent,
    DeviceDetectorComponent,
    SliderRendersComponent,
    SectionGratitudeComponent,
    FooterInfoComponent,
    FooterComponent,
    FloatingButtonComponent,
  ],
  templateUrl: "./renders-account.component.html",
  styleUrl: "./renders-account.component.css",
})
export class RendersAccountComponent {
  isMobile: boolean = false;
  public dataRenders: any;
  public dataHome?: any;
  public dataFooter?: any;
  public dataGratitude: any;

  constructor(
    private rendersAccountDataService: RendersAccountPageService,
    private dataHomeService: HomePageService,
    private metaService: Meta,
    private titleService: Title
  ) {}
  ngOnInit(): void {
    this.dataHomeService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.dataGratitude = this.dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: this.dataHome.footer_logo,
        footer_ribbon_txt: this.dataHome.footer_ribbon_txt,
        footer_txt: this.dataHome.footer_txt,
        social_networks: this.dataHome.social_networks,
      };
    });

    this.rendersAccountDataService.getRendersAccount().subscribe((data) => {
      this.dataRenders = data.results[0];

      this.metaService.updateTag({
        name: "description",
        content: this.dataRenders.seo_description,
      });
      this.titleService.setTitle(this.dataRenders.seo_title);
    });
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }
}
