<div class="accordion font-nunito mb-[15px]">
  <div
    class="accordion-header"
    [ngClass]="isOpen ? 'active' : ''"
    (click)="toggleAccordion()"
  >
    <div class="text-[20px] max-[992px]:text-[17px] max-[992px]:leading-[100%]">
      {{ title }}
    </div>

    <svg
      *ngIf="!isOpen"
      class="icon"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5v14M5 12h14"
        stroke="#fc1821"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>

    <!-- SVG Icon for '-' -->
    <svg
      *ngIf="isOpen"
      class="icon"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12h14"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>

  <div class="accordion-content" [class.open]="isOpen">
    <ng-content></ng-content>
  </div>
</div>
