import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { GoalService } from "./services/meta.service";

@Component({
  selector: "app-section-meta",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./section-meta.component.html",
  styleUrl: "./section-meta.component.css",
})
export class SectionMetaComponent {
  public dataPayments: any;
  progress: number = 75;

  constructor(private goalDataService: GoalService) {
    // this.incrementProgress();
  }

  // incrementProgress() {
  //   setInterval(() => {
  //     if (this.progress < 100) {
  //       this.progress += 1;
  //     }
  //   }, 1000);
  // }

  ngOnInit(): void {
    this.goalDataService.getGoal().subscribe((data) => {
      this.dataPayments = data.results[0];
      //console.log(this.dataPayments);
    });
  }
}
