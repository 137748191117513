<div
  class="relative h-[500px] max-[992px]:h-[260px] z-[0] flex items-center justify-center"
>
  <picture>
    <source media="(max-width:920px)" [srcset]="imgMobile" />
    <source media="(max-width:1024px)" [srcset]="imgDesktop" />
    <img
      [src]="imgDesktop"
      class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
    />
  </picture>
  <div
    class="relative text-[#ffffff] text-[55px] max-[992px]:text-[40px] font-black z-[1] md:px-0 px-[20px] max-[992px]:text-center max-[992px]:leading-[100%] font-nunito animate__animated animate__delay-0.5s animate__fadeInUp"
  >
    {{ title }}
  </div>
</div>
