import { Component, Input } from "@angular/core";
import { SharedDataService } from "../../shared/shared-data.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-footer-info",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./footer-info.component.html",
  styleUrl: "./footer-info.component.css",
})
export class FooterInfoComponent {
  @Input() dataFooter?: any;
  public footerData: any;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.sharedDataService.currentData.subscribe((data) => {
        if (!data || data.length === 0) {
          this.footerData = this.dataFooter;
        } else {
          this.footerData = data;
        }
      });
    }, 1000);
  }
}
