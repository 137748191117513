import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { GoalPaths } from "./meta.paths";

@Injectable({
  providedIn: "root",
})
export class GoalService {
  private _url: string;
  private homeApiUrl: string;

  constructor(private http: HttpClient) {
    this._url = environment.backendUrl;
    this.homeApiUrl = `${this._url}${GoalPaths.PaymentsGet}`;
  }

  getGoal() {
    return this.http.get<any>(this.homeApiUrl);
  }
}
