import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "app-tabs-about",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./tabs-about.component.html",
  styleUrl: "./tabs-about.component.css",
})
export class TabsAboutComponent {
  public activeTab: number = 0;
  public previousTab: number = 0;
  public transitionDirection: "up" | "down" = "up";
  public isAnimating = false;

  // Verifica si el índice activo es par
  isEvenTab() {
    return this.activeTab % 2 === 0;
  }

  changeTab(index: number) {
    if (this.isAnimating || index === this.activeTab) {
      return;
    }
    this.transitionDirection = index > this.activeTab ? "up" : "down";
    this.previousTab = this.activeTab;
    this.activeTab = index;
    this.isAnimating = true;

    setTimeout(() => {
      this.isAnimating = false;
    }, 500);
  }
}
