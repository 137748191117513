import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-modal-static",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./modal-static.component.html",
  styleUrl: "./modal-static.component.css",
})
export class ModalStaticComponent {
  @Output() onClose = new EventEmitter<void>();
  isOpen = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.onClose.emit();
  }
}
