import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccordionService {
  private openedAccordion = new BehaviorSubject<number | null>(null);

  openAccordion(index: number) {
    this.openedAccordion.next(index);
  }

  getOpenedAccordion() {
    return this.openedAccordion.asObservable();
  }
}
