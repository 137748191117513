import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "app-section-channels",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./section-channels.component.html",
  styleUrl: "./section-channels.component.css",
})
export class SectionChannelsComponent {
  banks = [
    {
      logo: "assets/images/logo_interbank.svg",
      accounts: [
        {
          label: "CtaCorriente Soles",
          number: "200-3005245794",
        },
        {
          label: "Cuenta dólares",
          number: "200-3005245815",
        },
      ],
      cci: [
        {
          label: "CCI Soles",
          number: "003-200-003005245794-39",
        },
        {
          label: "CCI dólares",
          number: "003-200-003005245815-32",
        },
      ],
      showCci: false,
    },
    {
      logo: "assets/images/logo_bbva.svg",
      accounts: [
        {
          label: "CtaCorriente Soles",
          number: "0011-0586-0100063341",
        },
        {
          label: "Cuenta dólares",
          number: "0011-0586-0100063368",
        },
      ],
      cci: [
        {
          label: "CCI Soles",
          number: "011-586-000100063341-50",
        },
        {
          label: "CCI dólares",
          number: "011-586-000100063368-53",
        },
      ],
      showCci: false,
    },
    {
      logo: "assets/images/logo_scotiabank.png",
      accounts: [
        {
          label: "CtaCorriente Soles",
          number: "000-1650701",
        },
        {
          label: "Cuenta dólares",
          number: "000-5100124",
        },
      ],
      cci: [
        {
          label: "CCI Soles",
          number: "009-170-000001650701-24",
        },
        {
          label: "CCI dólares",
          number: "009-170-000005100124-26",
        },
      ],
      showCci: false,
    },
    {
      logo: "assets/images/logo_banbif.svg",
      accounts: [
        {
          label: "CtaCorriente Soles",
          number: "007-000745991",
        },
        {
          label: "Cuenta dólares",
          number: "007-000745983",
        },
      ],
      cci: [
        {
          label: "CCI Soles",
          number: "038-100-107000745991-42",
        },
        {
          label: "CCI dólares",
          number: "038-100-207000745983-48",
        },
      ],
      showCci: false,
    },
  ];

  toggleCci(bank: any) {
    bank.showCci = !bank.showCci;
  }
}
