import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-tabs-zones",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./tabs-zones.component.html",
  styleUrl: "./tabs-zones.component.css",
})
export class TabsZonesComponent implements OnInit {
  activeTab = 0;
  safeUrl: SafeResourceUrl | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  tabsData = [
    {
      nombre: "Lima",
      direccion:
        "Av. Nicolás Arriola 3250 - San Luis, <span class='font-black'>Teléfono:</span> (01) 319-1400,",
      contacto: "contactenos.lima&#64;sanjuandedios.pe",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3901.6809389007485!2d-76.989652!3d-12.06546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c63f247b12d9%3A0x266cada860b1085c!2sCl%C3%ADnica%20San%20Juan%20de%20Dios%20Lima%20%7C%20Per%C3%BA!5e0!3m2!1ses-419!2sus!4v1729191295732!5m2!1ses-419!2sus",
    },
    {
      nombre: "Arequipa",
      direccion:
        "Av. Ejército Nº 1020 - Cayma, <span class='font-black'>Teléfono:</span> (054) 382-400,",
      contacto: "info.arequipa&#64;sanjuandedios.pe",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3827.719170830288!2d-71.549964!3d-16.388262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a42086cf8d5%3A0xb6b0b4bb26fe4882!2sCl%C3%ADnica%20San%20Juan%20de%20Dios%20Arequipa!5e0!3m2!1ses-419!2sus!4v1729191578054!5m2!1ses-419!2sus",
    },
    {
      nombre: "Chiclayo",
      direccion:
        "Carretera Chiclayo a Pimentel km. 10 - Pimentel, <span class='font-black'>Citas:</span> 962 945 351,",
      contacto: "informes.chiclayo@sanjuandedios.pe",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7923.102807537739!2d-79.91956!3d-6.824283!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904cfab185a04d91%3A0x5e256f39efd8b1af!2sHogar%20Cl%C3%ADnica%20San%20Juan%20de%20Dios!5e0!3m2!1ses-419!2sus!4v1729191620327!5m2!1ses-419!2sus",
    },
    {
      nombre: "Cusco",
      direccion:
        "Av. Manzanares N°264 - Urb. Manuel Prado, <span class='font-black'>Teléfono:</span> (084) 231340,",
      contacto: "informes.cusco@sanjuandedios.pe",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1939.6111003505634!2d-71.953716!3d-13.521951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dd5ffc30d9449%3A0x7a065e773ba87d1d!2sCl%C3%ADnica%20San%20Juan%20de%20Dios%20-%20Cusco!5e0!3m2!1ses-419!2sus!4v1729192929449!5m2!1ses-419!2sus",
    },
    {
      nombre: "Iquitos",
      direccion: "Carretera - Iquitos Nauta Km. 2.8 Distrito de San Juan,",
      contacto: "(972) 651 190",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7962.061520204242!2d-73.31178!3d-3.8034260000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ea0e663a8e583d%3A0x163805d1700c0142!2sClinica%20San%20Juan%20De%20Dios%20Iquitos!5e0!3m2!1ses-419!2sus!4v1729192945430!5m2!1ses-419!2sus",
    },
    {
      nombre: "Piura",
      direccion: "Av. Luis Montero 101-A, Urb. Miraflores - Castilla,",
      contacto:
        "(073) 310-874, <span class='font-black'>Celular:</span> 965 001 904",
      url_map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.417966059768!2d-80.62533080321046!3d-5.196806699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904a1062d8a74d75%3A0x3e1213479afb7333!2sHOSPITAL%20ESPECIALIZADO%20SAN%20JUAN%20DE%20DIOS%20DE%20PIURA!5e0!3m2!1ses-419!2sus!4v1729192971747!5m2!1ses-419!2sus",
    },
  ];

  selectTab(index: number) {
    this.activeTab = index;
    this.safeUrl = this.sanitizeUrl(this.tabsData[index].url_map);
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.safeUrl = this.sanitizeUrl(this.tabsData[this.activeTab].url_map);
  }
}
