import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { HttpClientModule } from "@angular/common/http"; // Import HttpClientModule
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';


@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LayoutComponent,
    HttpClientModule, // Add HttpClientModule here
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"], // Ensure this is 'styleUrls' for multiple styles
})
export class AppComponent {
  title = "atk-teleton";
}

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  initializeGtm(): void {
    if (environment.googleTag) {
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTag}`;
      document.head.appendChild(gtmScript);

      const gtmScriptInline = document.createElement('script');
      gtmScriptInline.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "${environment.googleTag}");
      `;
      document.head.appendChild(gtmScriptInline);
    }
  }

  initializeMetaPixel(): void {
    if (environment.metaPixelId) {
      const pixelScript = document.createElement('script');
      pixelScript.innerHTML = `
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "${environment.metaPixelId}");
        fbq("track", "PageView");
      `;
      document.head.appendChild(pixelScript);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <img
          height="1"
          width="1"
          style="display: none"
          src="https://www.facebook.com/tr?id=${environment.metaPixelId}&ev=PageView&noscript=1"
        />
      `;
      document.body.appendChild(noscript);
    }
  }
}