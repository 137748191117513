import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { HomePageService } from "../home/services/home.service";
import { FooterStaticComponent } from "../../layout/components/footer-static/footer-static.component";
import { FloatingButtonComponent } from "../../layout/components/floating-button/floating-button.component";
import { CustomRadioComponent } from "../../components/custom-radio/custom-radio.component";
import { FormsModule } from "@angular/forms";
import { DeviceDetectorComponent } from "../../components/device/device.component";
import { Router, RouterModule } from "@angular/router";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { FooterComponent } from "../../layout/components/footer/footer.component";

@Component({
  selector: "app-telesponsors",
  standalone: true,
  imports: [
    CommonModule,
    FooterInfoComponent,
    FooterComponent,
    FloatingButtonComponent,
    CustomRadioComponent,
    FormsModule,
    DeviceDetectorComponent,
    RouterModule,
  ],
  templateUrl: "./telesponsors.component.html",
  styleUrl: "./telesponsors.component.css",
})
export class TelesponsorsComponent implements OnInit {
  public dataHome?: any;
  isMobile: boolean = false;
  public dataFooter?: any;
  public dataGratitude: any;
  selectedValue: string = "";

  constructor(
    private homeDataService: HomePageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.dataGratitude = this.dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: this.dataHome.footer_logo,
        footer_ribbon_txt: this.dataHome.footer_ribbon_txt,
        footer_txt: this.dataHome.footer_txt,
        social_networks: this.dataHome.social_networks,
      };
    });
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  onRadioSelectionChange(value: string) {
    this.selectedValue = value;
  }

  isSelected(value: string): boolean {
    return this.selectedValue === value;
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  redirectDonate() {
    this.router
      .navigate(["telepadrinos/donar/"], {
        queryParams: { "donation-amount": this.selectedValue },
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
  }
}
