<section class="what-do-we-do font-nunito">
  <app-banner-layout
    [title]="'¿Qué hacemos?'"
    [imgDesktop]="'../../../assets/images/head-what-do-we-do.jpeg'"
    [imgMobile]="'../../../assets/images/head-what-do-we-do.jpeg'"
  ></app-banner-layout>
  <div class="relative pt-[50px]">
    <img
      src="../../../assets/images/circle-top-right.png"
      class="absolute top-0 right-0 w-[30%] h-auto"
    />
    <img
      src="../../../assets/images/circle-center-left.png"
      class="absolute m-auto top-0 bottom-0 left-0 w-[10%] h-auto"
    />
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <div class="md:flex items-center gap-[40px] mb-[30px]">
        <div class="md:w-3/5 max-[992px]:mb-[40px]">
          <app-base-title
            title="¿Qué hacemos?"
            customClass="md:mb-[10px] mb-[20px]"
          ></app-base-title>
          <div
            class="text-[#043D57] text-[22px] max-[992px]:text-[16px] font-nunito text-justify md:mb-[40px] mb-[20px] leading-[130%]"
          >
            La Fundación Teletón San Juan de Dios es una organización sin fines
            de lucro, dedicada a la rehabilitación integral de niños y niñas con
            discapacidad a nivel nacional; además, promueve su inclusión a la
            sociedad y el pleno respeto de sus derechos.
            <br /><br />
            La labor de la Fundación Teletón se realiza en coordinación con la
            Clínica San Juan de Dios, la cual cuenta con servicios de
            rehabilitación de calidad. En ese sentido, actualmente la Clínica
            San Juan de Dios de Piura, está en proceso de acceder a la CARF (The
            Commission on Accreditation of Rehabilitation Facilities), por su
            Programa de Rehabilitación Integral Pediátrico, con lo cual se
            convertiría en la primera clínica en el país con dicha acreditación.
            <br /><br />
            Por otro lado, gracias a la solidaridad de los peruanos, desde el
            2023, la Fundación Teletón estará brindando atención a niños y niñas
            con diagnóstico del espectro autista.
          </div>
        </div>
        <div
          class="md:w-2/5 animate__animated animate__delay-0.5s animate__fadeInRight"
        >
          <img src="../../../assets/images/qh-1.jpg" class="w-full" />
        </div>
      </div>
      <div
        class="relative mb-[30px] animate__animated animate__delay-0.5s animate__animated animate__delay-0.5s"
        [appAnimateOnScroll]="'animate__zoomIn'"
      >
        <app-base-title
          title="Atención a niñas y niños con discapacidad física y motora"
          color="text-[#FC1821]"
          customClass="md:mb-[40px] mb-[20px] text-center leading-[100%] md:w-[560px] mx-auto"
        ></app-base-title>
        <div class="md:flex gap-[20px]">
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(1)">
              <app-hover-image
                imageUrl="../../../assets/images/md-1.jpeg"
                hoverImageUrl="../../../assets/images/md-01.jpeg"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(2)">
              <app-hover-image
                imageUrl="../../../assets/images/md-2.jpeg"
                hoverImageUrl="../../../assets/images/md-02.jpeg"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(3)">
              <app-hover-image
                imageUrl="../../../assets/images/md-3.png"
                hoverImageUrl="../../../assets/images/md-03.png"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
        </div>
      </div>
      <div class="relative">
        <app-base-title
          title="Nueva Unidad Especializada en atención de TEA Infantil"
          customClass="mb-[30px] text-center"
        ></app-base-title>
        <div class="md:flex items-center gap-[60px] mb-[40px]">
          <div class="md:w-1/2 gap-[20px] max-[992px]:mb-[40px]">
            <div
              class="text-[#043D57] text-[22px] max-[992px]:text-[16px] font-nunito text-justify md:mb-[40px] mb-[20px] leading-[130%]"
            >
              A partir del 2023, las niñas y niños con diagnóstico de trastorno
              del espectro autista recibirán un servicio de calidad en la nueva
              Unidad de Atención Especializada de TEA, ubicada en la Clínica San
              Juan de Dios de Lima, la cual contará con tres áreas de servicio
              ambulatorio y doce áreas de rehabilitación con equipamiento de
              alto nivel para las terapias. El equipo profesional estará
              conformado por neuropediatras, neuropsicólogos, psicólogos,
              terapeutas de lenguaje, terapeutas ocupacionales y terapeutas de
              rehabilitación física.
            </div>
          </div>
          <div
            class="md:w-1/2 animate__animated animate__delay-0.5s animate__fadeInRight"
          >
            <img src="../../../assets/images/qh-2.png" class="w-full" />
          </div>
        </div>
      </div>
      <div
        class="relative mb-[30px] animate__animated animate__delay-0.5s animate__animated animate__delay-0.5s"
        [appAnimateOnScroll]="'animate__zoomIn'"
      >
        <app-base-title
          title="Atención a niñas y niños con Autismo"
          color="text-[#FC1821]"
          customClass="md:mb-[40px] mb-[20px] text-center"
        ></app-base-title>
        <div class="md:flex gap-[20px]">
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(4)">
              <app-hover-image
                imageUrl="../../../assets/images/md-4.png"
                hoverImageUrl="../../../assets/images/md-04.png"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(5)">
              <app-hover-image
                imageUrl="../../../assets/images/md-5.jpeg"
                hoverImageUrl="../../../assets/images/md-05.jpeg"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="block" (click)="openModal(6)">
              <app-hover-image
                imageUrl="../../../assets/images/md-6.jpeg"
                hoverImageUrl="../../../assets/images/md-06.jpeg"
                customClass="cursor-pointer"
              ></app-hover-image>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="relative">
        <img
          src="../../../assets/images/agradecemos-titulo-1.webp"
          class="mb-[30px] mx-auto flex md:w-auto w-[280px]"
        />
        <app-slider-static></app-slider-static>
      </div> -->
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>

<app-modal-static #modal>
  <div class="relative font-nunito">
    <div *ngIf="selectedContent === 1">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Consultas Médicas
      </div>
      <div class="flex max-[520px]:block gap-[30px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            Las niñas y niños son evaluados por un médico especializado quien
            determina el diagnóstico y el tratamiento respectivo.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-1.jpg" class="w-full" />
        </div>
      </div>
    </div>
    <div *ngIf="selectedContent === 2">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Terapias de Rehabilitación
      </div>
      <div class="flex max-[560px]:block gap-[40px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            De acuerdo al diagnóstico, las niñas y niños reciben las siguientes
            terapias de rehabilitación: físicas, de lenguaje, ocupacional,
            psicológicas, psicopedagógicas, neuropsicológicas, de
            psicomotricidad y multisensorial.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-2.jpeg" class="w-full" />
        </div>
      </div>
    </div>
    <div *ngIf="selectedContent === 3">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Cirugías
      </div>
      <div class="flex max-[560px]:block gap-[40px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            Según evaluación y viabilidad, el médico tratante determina los
            procedimientos quirúrgicos que requieren las niñas y los niños,
            según el proceso de su rehabilitación.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-3.jpeg" class="w-full" />
        </div>
      </div>
    </div>
    <div *ngIf="selectedContent === 4">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Especialidades médicas
      </div>
      <div class="flex max-[560px]:block gap-[40px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            Atención en neuropediatría y psiquiatría infantil ambas
            especialidades permitirán diagnosticar problemas del sistema
            nervioso y/o problemas conductuales o trastornos mentales.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-4.jpeg" class="w-full" />
        </div>
      </div>
    </div>
    <div *ngIf="selectedContent === 5">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Especialidades no médicas
      </div>
      <div class="flex max-[560px]:block gap-[40px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            Atención en neuropsicología. Esta especialidad permite diagnosticar
            problemas conductuales y emocionales.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-5.jpeg" class="w-full" />
        </div>
      </div>
    </div>
    <div *ngIf="selectedContent === 6">
      <div
        class="text-[45px] max-[992px]:text-[30px] font-extrabold text-[#043D57] mb-[20px] text-center"
      >
        Terapias físicas y rehabilitación
      </div>
      <div class="flex max-[560px]:block gap-[40px]">
        <div class="w-1/2 max-[560px]:w-auto">
          <div
            class="text-[22px] max-[992px]:text-[18px] font-medium text-[#043D57] md:leading-[150%] max-[992px]:leading-[140%] md:mb-0 mb-[20px]"
          >
            De acuerdo al tratamiento, las niñas y niños reciben las siguientes
            terapias de rehabilitación: terapia de lenguaje, de aprendizaje,
            ocupacional, de integración sensorial y psicomotricidad.
          </div>
        </div>
        <div class="w-1/2 max-[560px]:w-auto">
          <img src="../../../assets/images/mod-6.jpeg" class="w-full" />
        </div>
      </div>
    </div>
  </div>
</app-modal-static>
