<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<div class="slider-container slider-static">
  <ngx-slick-carousel #slickModal [config]="slideConfig" *ngIf="!isMobile">
    <div class="slide" *ngFor="let slide of slides">
      <img [src]="slide.img" [alt]="slide.alt" />
    </div>
  </ngx-slick-carousel>

  <ngx-slick-carousel #slickModalMobile [config]="slideConfig" *ngIf="isMobile">
    <div class="slide" *ngFor="let slide of slidesMobile">
      <img [src]="slide.img" [alt]="slide.alt" />
    </div>
  </ngx-slick-carousel>
</div>
