<!-- <app-banner [dataHome]="dataHome"></app-banner> -->
<!-- <app-section-meta id="section-meta"></app-section-meta> -->
<!-- <app-section-channels id="section-channels"></app-section-channels> -->
<!-- <app-section-stores
  [dataHome]="dataHome"
  id="section-stores"
></app-section-stores> -->
<!-- <app-section-thanks-you id="section-thanks-you"></app-section-thanks-you> -->
<!-- <app-section-rinde id="section-rinde"></app-section-rinde> -->
<!-- <app-section-stories
  [dataHome]="dataHome"
  id="section-stories"
></app-section-stories> -->

<div class="w-full home-banner-slick overflow-hidden">
  <ng-container *ngFor="let item of dataHome?.banners">
    <div class="home-banner-slick-item">
      <a [href]="item.url" *ngIf="item.url !== null">
        <img [src]="item.img_desktop" alt="Teletón Perú" class="w-full" />
      </a>
      <div *ngIf="item.url === null">
        <img [src]="item.img_desktop" alt="Teletón Perú" class="w-full" />
      </div>
    </div>
  </ng-container>
</div>

<div
  class="w-full py-14 bg-no-repeat bg-[47%_auto] bg-right-top"
  style="
    background-image: url(https://old1.teleton.pe/wp-content/uploads/2023/07/Enmascarar-grupo-17-2.png);
  "
>
  <div class="flex flex-col md:flex-row items-center justify-center gap-8">
    <div class="max-w-full px-10 xl:px-0 sm:w-1/2 xl:max-w-[600px]">
      <h2
        class="text-3xl xl:text-6xl text-[#043D57] font-black font-nunito leading-none m-0"
      >
        Más de
      </h2>
      <h1
        class="text-5xl xl:text-8xl text-[#043D57] font-black font-nunito m-0 leading-none"
      >
        40 años
      </h1>
      <h3
        class="text-xl xl:text-4xl text-[#043D57] font-black font-nunito m-0 leading-none mb-6"
      >
        trabajando a favor de las niñas y niños con discapacidad
      </h3>
      <p
        class="text-justify text-[#043D57] font-nunito text-lg xl:text-2xl xl:leading-8"
      >
        Desde hace más de cuatro décadas, la Teletón moviliza a la ciudadanía
        por la rehabilitación y la inclusión de miles de niños y niñas con
        discapacidad. Además, la Fundación Teletón San Juan de Dios, junto a
        otros 11 países del continente, forma parte de la Organización
        Internacional de Teletones (Oritel).
      </p>
    </div>
    <div class="w-full sm:w-1/2 xl:w-[600px]">
      <img
        src="https://old1.teleton.pe/wp-content/uploads/2023/07/Grupo-318-1-2.png"
        class="w-[80%] block mx-auto xl:w-full"
        alt="más de 40 años trabajando"
      />
    </div>
  </div>
</div>

<div
  class="w-full bg-no-repeat bg-[37%_auto] bg-left-bottom bg-[#E2E2E2] py-14"
  style="
    background-image: url(https://old1.teleton.pe/wp-content/uploads/2023/07/Enmascarar-grupo-27-1.png);
  "
>
  <div
    class="w-full flex flex-col md:flex-row items-center justify-center gap-0 xl:gap-8"
  >
    <div class="w-full sm:w-2/5 xl:w-[500px]">
      <div class="px-10 xl:px-0">
        <h4
          class="text-xl xl:text-4xl text-[#043D57] font-black font-nunito leading-none m-0 mb-6 text-center md:text-left"
        >
          Nuestras historias de vida
        </h4>
        <p
          class="text-[#043D57] font-nunito text-lg xl:text-2xl xl:leading-8 mb-6"
        >
          Historias que inspiran, llenan de esperanza a todo un país y buscan
          motivarnos a ser mejores personas por las niñas y niños Teletón y sus
          familias.
        </p>
        <p
          class="text-justify text-[#043D57] font-nunito text-lg xl:text-2xl xl:leading-8 mb-8"
        >
          Conoce aquí las historias llenas de amor y solidaridad.
        </p>
        <a
          href="#"
          class="font-nunito text-xl md:text-2xl font-black text-white no-underline rounded-md bg-[#FC1821] px-6 py-1 leading-3 transition-all delay-150 duration-500 ease-in-out hover:bg-black"
          >Ver más</a
        >
      </div>
    </div>
    <div class="w-full sm:w-3/5 xl:w-[700px] flex flex-col pb-10">
      <div class="px-10 xl:px-0">
        <a href="https://youtu.be/gNJQf6fCyKY" target="_blank">
          <picture decoding="async">
            <source
              type="image/webp"
              srcset="
                https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v1.jpg.webp         700w,
                https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v1-300x171.jpg.webp 300w
              "
              sizes="(max-width: 700px) 100vw, 700px"
            />
            <img
              decoding="async"
              src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v1.jpg"
              alt=""
              srcset="
                https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v1.jpg         700w,
                https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v1-300x171.jpg 300w
              "
              sizes="(max-width: 700px) 100vw, 700px"
              class="w-full h-auto block mx-auto xl:px-0 xl:w-[700px] xl:h-[400px]"
            />
          </picture>
        </a>
        <p
          class="text-center text-black py-6 text-sm xl:text-lg font-nunito font-bold leading-6 tracking-normal"
        >
          Allison cuenta con el amor y apoyo incondicional de su 'mamita'
          Susana, ellas demuestran que no hay obstáculos para seguir adelante.
        </p>
        <div class="home-historias-video-slick">
          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=YMM3qFnZwOU"
              target="_blank"
            >
              <picture decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v2_1.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v2_1-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v2_1.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v2_1.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v2_1-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=ITDVyS5-3Gw"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v3.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v3-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v3.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v3.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v3-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=5i2EVInNsSg"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v4.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v4-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v4.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v4.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v4-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=jGE_KGbmohg"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v5.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v5-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v5.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v5.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v5-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=aauHh7auvFc"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v6.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v6-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v6.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v6.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v6-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=66fVdDw-Li0"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v7.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v7-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v7.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v7.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v7-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=Ji5Gft7NXtM"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v8.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v8-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v8.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v8.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v8-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=c_j0tBgdIVw"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v9.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v9-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v9.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v9.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v9-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=OnSy1CvYjbU"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v10.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v10-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v10.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v10.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v10-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>

          <div class="home-historias-video-item">
            <a
              href="https://www.youtube.com/watch?v=8uzQGT-u9is"
              target="_blank"
            >
              <picture loading="lazy" decoding="async">
                <source
                  type="image/webp"
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v11.jpg.webp         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v11-300x171.jpg.webp 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
                <img
                  loading="lazy"
                  decoding="async"
                  width="700"
                  height="400"
                  src="https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v11.jpg"
                  alt=""
                  srcset="
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v11.jpg         700w,
                    https://old1.teleton.pe/wp-content/uploads/2023/10/historia-v11-300x171.jpg 300w
                  "
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="w-full bg-no-repeat bg-[22%_auto] bg-left-bottom py-14"
  style="
    background-image: url(https://old1.teleton.pe/wp-content/uploads/2023/07/Enmascarar-grupo-30-1-1.png);
  "
>
  <div class="w-full flex items-center justify-center">
    <h4
      class="text-xl xl:text-4xl text-[#FC1821] font-black font-nunito leading-none m-0 mb-12 text-center px-10 xl:px-0"
    >
      ¿Cómo se administra el dinero de la Teletón?
    </h4>
  </div>
  <div
    class="flex flex-col md:flex-row items-center justify-center gap-0 xl:gap-8"
  >
    <div class="w-full xl:w-[470px]">
      <img
        src="https://old1.teleton.pe/wp-content/uploads/2023/07/I-1.png"
        alt=""
        class="w-[80%] block mx-auto xl:w-full mb-10 md:mb-0"
      />
    </div>
    <div class="w-full xl:w-[730px]">
      <div class="px-10 xl:px-0">
        <app-accordion
          title="1- Se realiza el evento Teletón"
          [index]="0"
          [defaultOpen]="true"
        >
          <div
            class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
          >
            Los peruanos realizan su donación
          </div>
        </app-accordion>

        <app-accordion
          title="2- La Fiducuaria administra los fondos de la Teletón"
          [index]="1"
          [defaultOpen]="false"
        >
          <div
            class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
          >
            Por transparencia a la ciudadanía La Fiduciaria presta los servicios
            para administrar los fondos de la Teletón y brindar transparencia a
            la rehabilitación de las niñas y niños.
          </div>
        </app-accordion>

        <app-accordion
          title="3- Los posibles usuarios en situación de vulnerabilidad se acercan a la Teletón"
          [index]="2"
          [defaultOpen]="false"
        >
          <div
            class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
          >
            Una niña o niño en situación de discapacidad presenta su caso y es
            evaluado. Si es admitido, se remite a la Clínica San Juan de Dios.
          </div>
        </app-accordion>

        <app-accordion
          title="4- La Clínica San Juan de Dios presta los servicios a la Teletón"
          [index]="3"
          [defaultOpen]="false"
        >
          <div
            class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
          >
            La Teletón coordina los servicios de rehabilitación con la Clínica
            San Juan de Dios para atender a las niñas y niños admitidos con
            discapacidad.
          </div>
        </app-accordion>

        <app-accordion
          title="5- Se inicia el proceso de rehabilitación"
          [index]="4"
          [defaultOpen]="false"
        >
          <div
            class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
          >
            Las niñas y niños inician su proceso de rehabilitación a través de:
            <ul>
              <li>Consultas médicas</li>
              <li>Terapias</li>
              <li>
                Cirugías (según el diagnóstico e indicaciones del médico
                tratante)
              </li>
            </ul>
          </div>
        </app-accordion>
      </div>
    </div>
  </div>
</div>

<!-- <div
  class="w-full bg-no-repeat bg-[37%_auto] bg-right-bottom pt-14 pb-20 px-0 bg-[#E2E2E2]"
  style="
    background-image: url(https://old1.teleton.pe/wp-content/uploads/2023/07/Enmascarar-grupo-38-1.png);
  "
>
  <div class="flex items-center justify-center">
    <h4
      class="text-4xl text-[#043D57] font-black font-nunito leading-none m-0 mb-12 text-center"
    >
      Noticias
    </h4>
  </div>
  <div
    class="flex flex-col xl:flex-row items-center justify-center gap-8 xl:px-0 w-[85%] xl:w-full mx-auto"
  >
    <div class="w-full xl:w-[600px]">
      <ng-container
        *ngFor="let newsContent of processedNewsList | slice : 0 : 1"
      >
        <div
          class="relative w-full drop-shadow-lg md:h-[600px] h-[300px] flex flex-col items-center justify-center bg-center bg-cover rounded-xl shadow-lg overflow-hidden"
          [ngStyle]="{
            'background-image':
              'url(' +
              (isMobile
                ? newsContent.banner_img_mobile
                : newsContent.banner_img_desktop) +
              ')'
          }"
        >
          <div
            class="absolute w-full h-full z-[1] bg-[rgba(227,6,19,0.4)] top-0 left-0"
          ></div>
          <p
            class="font-nunito text-white text-2xl text-center px-12 font-semibold pb-4 z-[2]"
            style="text-shadow: 3px 3px 5px #000"
            [innerHTML]="newsContent.sanitizedTitle"
          ></p>
          <a
            [href]="'/noticias/' + convertToSlug(newsContent.title)"
            target="_blank"
            class="no-underline bg-[#043d57] px-6 text-white font-nunito font-extrabold rounded py-2 transition-all delay-75 duration-500 hover:bg-[#FC1821] z-[2]"
            style="text-shadow: 3px 3px 5px #000"
          >
            Leer más
          </a>
        </div>
      </ng-container>
    </div>
    <div class="w-full md:w-[600px] md:h-[600px]">
      <div class="w-full h-full grid md:grid-cols-2 md:grid-rows-2 gap-4">
        <ng-container *ngFor="let newsContent of processedNewsList | slice : 1">
          <div
            class="relative h-[300px] w-full md:h-full flex flex-col items-center justify-center bg-no-repeat bg-center bg-cover rounded-xl shadow-lg overflow-hidden"
            [ngStyle]="{
              'background-image':
                'url(' +
                (isMobile
                  ? newsContent.banner_img_mobile
                  : newsContent.banner_img_desktop) +
                ')'
            }"
          >
            <div
              class="absolute w-full h-full z-[1] bg-[rgba(17,29,92,0.6)] top-0 left-0"
            ></div>
            <p
              class="font-nunito text-white text-lg md:text-xl text-center px-12 font-semibold pb-4 leading-tight z-[2]"
              style="text-shadow: 3px 3px 5px #000"
              [innerHTML]="newsContent.sanitizedTitle"
            ></p>
            <a
              [href]="'/noticias/' + convertToSlug(newsContent.title)"
              target="_blank"
              class="no-underline bg-[#FC1821] px-6 text-white font-nunito font-extrabold rounded py-2 transition-all delay-75 duration-500 hover:bg-[#043d57] z-[2]"
              style="text-shadow: 3px 3px 5px #000"
              >Leer más</a
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<app-section-gratitude
  [dataHome]="dataHome"
  id="section-gratitude"
></app-section-gratitude>

<a
  href="/donativo/donar-ahora"
  class="floating-button initial text-[24px] font-quicksand font-bold no-underline box-shadow-left"
>
  ¡Donar Ahora!
</a>

<app-footer-info />
<app-footer />
