<section class="about-us">
  <app-banner-layout
    [title]="'Sobre nosotros'"
    [imgDesktop]="'../../../assets/images/head-about-us.jpeg'"
    [imgMobile]="'../../../assets/images/head-about-us.jpeg'"
  ></app-banner-layout>
  <div class="relative">
    <div
      class="max-w-screen-large mx-auto py-[50px] max-[992px]:px-[20px] relative z-[1]"
    >
      <div class="md:flex items-center gap-[60px]">
        <div class="md:w-3/5">
          <div
            class="text-[#043D57] font-nunito text-[40px] max-[992px]:text-[30px] font-black max-[992px]:text-center animate__animated animate__delay-0.5s animate__fadeInUp"
          >
            Nuestra historia
          </div>
          <div
            class="text-[#043D57] font-nunito text-[25px] max-[992px]:text-[16px] py-[20px] leading-[140%] max-[992px]:leading-[110%] text-justify"
          >
            La noche del viernes 11 de diciembre de 1981 se encendieron las
            luces del set principal de Panamericana Televisión y con ellas, las
            luces de esperanza de miles de familias peruanas. Así, se iniciaba
            la primera edición de la Teletón en nuestro país, la cual duró un
            poco más de 27 horas y logró más de medio millón de soles en
            recaudación, por lo que se convirtió en la actividad solidaria con
            mayor fuerza en Perú, movilizando a todo el país por la
            rehabilitación de niñas y niños.
          </div>
        </div>
        <div
          class="md:w-2/5 animate__animated animate__delay-0.5s animate__fadeInRight"
        >
          <img src="../../../assets/images/sn-1.png" class="w-full" />
        </div>
      </div>
    </div>
    <img
      src="../../../assets/images/bg-enmascarar-circulo.png"
      class="absolute bottom-0 right-0 w-[30%] h-auto"
    />
  </div>
  <div class="relative">
    <img
      src="../../../assets/images/banner-sobre-nosotros.jpeg"
      class="w-full rounded-tl-[250px] max-[992px]:rounded-tl-[60px] align-middle"
    />
  </div>
  <div class="relative">
    <img
      src="../../../assets/images/bg-enmascarar-top.png"
      class="absolute top-0 right-0 w-[26%] h-auto"
    />
    <div class="max-w-[1000px] px-[20px] max-[992px]:px-[5px] mx-auto">
      <div
        class="bg-[#FC1821] text-[#ffffff] p-[40px] max-[992px]:p-[15px] rounded-[26px] mt-[-70px] max-[992px]:mt-[-20px] inline-block"
      >
        <div
          class="text-[40px] max-[992px]:leading-[100%] max-[992px]:text-center font-black font-nunito mb-[10px] animate__animated animate__delay-0.5s"
          [appAnimateOnScroll]="'animate__fadeInUp'"
        >
          La Fundación Teletón forma parte de la Oritel
        </div>
        <div
          class="text-[25px] max-[992px]:text-[16px] leading-[140%] font-nunito"
        >
          La Organización Internacional de Teletones, Oritel, es la institución
          que integra a 12 países de América (Perú, Chile, Colombia, El
          Salvador, Honduras, México, Nicaragua, Paraguay, Puerto Rico, Uruguay,
          Argentina y Estados Unidos), que tienen como objetivo común la
          rehabilitación de niñas y niños con discapacidad, la cual se logra a
          través del evento solidario anual, gracias a la colaboración de la
          ciudadanía, las empresas y los medios de comunicación.
        </div>
      </div>
    </div>
  </div>
  <div class="relative py-[50px]">
    <div class="max-w-screen-largeXL mx-auto">
      <div
        class="flex max-[768px]:flex-wrap items-center px-[30px] max-[992px]:px-[20px] gap-[50px] max-[992px]:gap-[20px]"
      >
        <div
          class="hidden max-[768px]:block text-[#043D57] text-[40px] max-[992px]:text-[30px] max-[992px]:text-center font-nunito font-black leading-[100%] mb-[0px] animate__animated animate__delay-0.5s"
          [appAnimateOnScroll]="'animate__fadeInUp'"
        >
          <!-- TITULO MOBILE -->
          Centros de rehabilitación donde se atienden las niñas y niños Teletón
        </div>
        <div class="md:w-1/2 relative">
          <img src="../../../assets/images/map-01.png" class="w-full" />
          <img
            src="../../../assets/images/ubications-map.png"
            class="absolute top-0 left-0 right-0 bottom-0 w-full h-full animate__animated animate__delay-0.5s"
            [appAnimateOnScroll]="'animate__fadeInUp'"
          />
        </div>
        <div class="md:w-1/2">
          <div
            class="max-[768px]:hidden text-[#043D57] text-[40px] max-[992px]:text-[30px] max-[992px]:text-center font-nunito font-black leading-[100%] mb-[20px] animate__animated animate__delay-0.5s"
            [appAnimateOnScroll]="'animate__fadeInUp'"
          >
            <!-- TITULO DESKTOP -->
            Centros de rehabilitación donde se atienden las niñas y niños
            Teletón
          </div>
          <div
            class="text-[#043D57] text-[25px] max-[992px]:text-[16px] font-nunito text-justify"
          >
            Hoy la Fundación Teletón trabaja en alianza con las seis clínicas
            San Juan de Dios que se ubican en Lima, Piura, Chiclayo, Iquitos,
            Cusco y Arequipa, donde se brindan atenciones con los más altos
            estándares de calidad; entre consultas médicas, terapias de
            rehabilitación y cirugías para miles de niñas y niños en situación
            de vulnerabilidad, a nivel nacional.
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../../assets/images/bg-enmascarar-bottom.png"
      class="absolute bottom-0 right-0 w-[26%] h-auto"
    />
  </div>
  <div class="relative">
    <div class="max-w-screen-largeXL mx-auto">
      <div class="md:flex px-[30px] max-[992px]:px-[20px]">
        <div class="md:w-1/2">
          <app-tabs-about></app-tabs-about>
        </div>
        <div class="md:w-1/2">
          <div
            class="relative pt-[54px] max-[768px]:pt-[0px] max-[768px]:pt-[0px]"
          >
            <div
              class="relative pt-[10px] bg-[#fc1821] px-[10px] rounded-tr-[30px] max-[992px]:rounded-[0px] h-[340px] max-[992px]:h-[250px] max-[768px]:h-auto max-[992px]:pb-[10px]"
            >
              <img
                src="../../../assets/images/image-tab-01.jpeg"
                class="rounded-[30px] w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
