<div class="relative">
  <div class="relative w-full md:h-[100vh] h-full bg-[#E30613]">
    <img
      src="../../../../../assets/images/cornerback_top_left.webp"
      class="absolute top-[20px] left-[20px]"
    />
    <img
      src="../../../../../assets/images/cornerback_top_right.webp"
      class="absolute top-[20px] right-[20px]"
    />
    <img
      src="../../../../../assets/images/cornerback_bottom_left.webp"
      class="absolute bottom-[20px] left-[20px]"
    />
    <img
      src="../../../../../assets/images/cornerback_bottom_right.webp"
      class="absolute bottom-[20px] right-[20px]"
    />
    <div
      class="md:flex block items-center justify-center h-full md:py-0 py-[100px]"
    >
      <div class="max-w-screen-largeXL mx-auto">
        <div class="md:flex block items-center justify-center">
          <div class="md:w-2/3 w-full text-center">
            <div
              class="relative md:text-[96px] text-[50px] text-[#ffffff] font-helloKidme uppercase md:w-[800px] w-full leading-[90%] mx-auto mb-[20px]"
            >
              NO PUDIMOS PROCESAR TU DONACION.
            </div>
            <div class="relative mb-[40px] text-center">
              <picture>
                <source
                  srcset="../../../../assets/images/sticker_banner_01.png"
                  media="(max-width: 1023px)"
                />
                <img
                  class="w-[260px]"
                  src="../../../../assets/images/sticker_banner_01.png"
                />
              </picture>
            </div>
          </div>
          <div class="md:w-1/3 w-full text-center">
            <img
              src="../../../assets/images/img_thanks_03.png"
              class="md:w-auto w-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-section-gratitude
  [dataHome]="dataHome"
  id="section-gratitude"
></app-section-gratitude>
<app-footer-info />
<app-footer />
