<section class="footer-static">
  <div class="relative bg-[#FC1821] py-[50px]">
    <div class="max-w-screen-largeXL mx-auto">
      <div class="px-[20px]">
        <div class="flex max-[992px]:block">
          <div class="w-1/4 max-[992px]:w-auto max-[992px]:text-center">
            <img
              [src]="footerData && footerData.footer_logo"
              class="w-[194px] max-[992px]:w-[245px] mb-[20px]"
            />
            <div
              class="w-[190px] font-quicksand max-[992px]:mx-auto max-[992px]:mb-[40px]"
            >
              <div class="mx-auto elementor-animation-pop">
                <a
                  href="/donativo/donar-ahora"
                  class="block py-[18px] px-[30px] text-[#ffffff] bg-[#043D57] no-underline rounded-[20px] font-bold text-[16px] flex items-center gap-[10px] mx-auto"
                >
                  ¡Donar Ahora!
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="heart-icon"
                    fill="white"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                  >
                    <path
                      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="w-1/4 max-[992px]:w-auto max-[992px]:px-[20px]">
            <span
              [innerHTML]="footerData?.footer_txt"
              class="block text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] leading-[40px]"
            >
              {{ footerData?.footer_txt }}</span
            >
          </div>
          <div class="w-1/4 max-[992px]:w-auto max-[992px]:mb-[40px]">
            <ul class="list-footer m-0 max-[992px]:px-[20px]">
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/inicio" target="_blank">Inicio</a>
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/sobre-nosotros" target="_blank">Sobre nosotros</a>
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/que-hacemos" target="_blank">¿Qué hacemos?</a>
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/como-acceder" target="_blank"
                  >¿Cómo acceder a Teletón?</a
                >
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/rinde-cuentas" target="_blank">Rinde cuentas</a>
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a href="/ser-voluntario" target="_blank"
                  >Quiero ser voluntario</a
                >
              </li>
              <li
                class="text-[16px] text-[#ffffff] font-nunito font-bold mb-[15px] list-none"
              >
                <a
                  href="../../../../assets/files/POLITICA-DE-PRIVACIDAD-Y-PROTECCION-DE-DATOS-FUNDACION-TELETON-070623-1.pdf"
                  target="_blank"
                  >Política de protección de datos</a
                >
              </li>
            </ul>
          </div>
          <div class="w-1/4 max-[992px]:w-auto">
            <div
              class="flex items-center gap-[10px] justify-center max-[992px]:justify-between"
            >
              <ng-container *ngFor="let item of footerData?.social_networks">
                <a [href]="item.url" target="_blank">
                  <img [src]="item.logo" class="h-[28px]" />
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-[#043D57]">
    <div class="max-w-screen-largeXL mx-auto text-center">
      <div
        class="h-[50px] max-[992px]:h-[80px] text-[#ffffff] flex items-center justify-center max-[992px]:px-[40px]"
      >
        <span class="block md:mb-0 font-nunito text-[16px]">{{
          footerData?.footer_ribbon_txt
        }}</span>
      </div>
    </div>
  </div>
</section>
