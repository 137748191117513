import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { NewsPagePaths, NewsPaths } from "./news.paths";

@Injectable({
  providedIn: "root",
})
export class NewsPageService {
  private _url: string;
  private apiUrl: string;
  private apiUrlNewsPage: string;

  constructor(private http: HttpClient) {
    this._url = environment.backendUrl;
    this.apiUrl = `${this._url}${NewsPaths.NewsGet}`;
    this.apiUrlNewsPage = `${this._url}${NewsPagePaths.NewsPageGet}`;
  }

  getNews() {
    return this.http.get<any>(this.apiUrl);
  }

  getNewsPage() {
    return this.http.get<any>(this.apiUrlNewsPage);
  }
}
