import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ViewChild } from "@angular/core";
import {
  SlickCarouselComponent,
  SlickCarouselModule,
} from "ngx-slick-carousel";
import { NgxSlickItemModule } from "../slider/ngx-slick-item.module";
import { DeviceDetectorComponent } from "../device/device.component";

@Component({
  selector: "app-slider-static",
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    NgxSlickItemModule,
    DeviceDetectorComponent,
  ],
  templateUrl: "./slider-static.component.html",
  styleUrl: "./slider-static.component.css",
})
export class SliderStaticComponent implements AfterViewInit {
  isMobile: boolean = false;

  @ViewChild("slickModal", { static: false })
  slickModal!: SlickCarouselComponent;

  @ViewChild("slickModalMobile", { static: false })
  slickModalMobile!: SlickCarouselComponent;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    infinite: true,
  };

  slides = [
    { img: "../../../assets/images/logo-line-1.webp", alt: "Slide 1" },
    { img: "../../../assets/images/logo-line-2.webp", alt: "Slide 2" },
    { img: "../../../assets/images/logo-line-3.webp", alt: "Slide 3" },
  ];

  slidesMobile = [
    { img: "../../../assets/images/logo-line-mb-1.jpg", alt: "Slide 1" },
    { img: "../../../assets/images/logo-line-mb-2.jpg", alt: "Slide 2" },
    { img: "../../../assets/images/logo-line-mb-3.jpg", alt: "Slide 3" },
    { img: "../../../assets/images/logo-line-mb-4.jpg", alt: "Slide 2" },
    { img: "../../../assets/images/logo-line-mb-5.jpg", alt: "Slide 3" },
  ];

  ngAfterViewInit() {
    this.initSlick();
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
    this.initSlick();
  }

  private initSlick() {
    setTimeout(() => {
      if (this.isMobile && this.slickModalMobile) {
        this.slickModalMobile.initSlick();
      } else if (!this.isMobile && this.slickModal) {
        this.slickModal.initSlick();
      }
    }, 0);
  }
}
