import { CommonModule } from "@angular/common";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { DeviceDetectorComponent } from "../../../components/device/device.component";
import { ModalService } from "../../../components/modal/modal.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { ModalsComponent } from "../../../components/modals/modals.component";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule, DeviceDetectorComponent, ModalsComponent],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.css",
})
export class HeaderComponent implements OnInit {
  public isRoot: boolean = false;
  isMobile: boolean = false;
  isMenuOpen = false;
  isPartOpen = false;
  @ViewChild("modal") modal!: ModalsComponent;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.checkDevice();

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isRoot = event.url === "/";
      });
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  togglePart(): void {
    this.isPartOpen = !this.isPartOpen;
  }

  openModal() {
    this.modal.openModal();
  }

  @HostListener("window:resize", [])
  onResize() {
    this.checkDevice();
  }

  private checkDevice() {
    this.isMobile = window.innerWidth <= 768;
  }
}
