<section class="how-to-access">
  <app-banner-layout
    [title]="'¿Cómo acceder a Teletón?'"
    [imgDesktop]="'../../../assets/images/que-hacemos-2.png'"
    [imgMobile]="'../../../assets/images/que-hacemos-2.png'"
  ></app-banner-layout>
  <div class="relative py-[50px]">
    <img
      src="../../../assets/images/circle-top-right.png"
      class="absolute top-0 right-0 w-[20%] h-auto"
    />
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <app-base-title
        title="¿Quiénes pueden acceder a Teletón?"
        customClass="md:mb-[10px] mb-[20px]"
      ></app-base-title>
      <div
        class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito text-justify md:mb-[40px] mb-[20px]"
      >
        Para ser evaluado, la niña o niño debe debe tener el siguiente perfil:
      </div>
      <div class="md:flex gap-[30px]">
        <div class="md:w-1/3 md:m-0 mb-[50px]">
          <div class="relative h-[160px] mb-[20px]">
            <img
              src="../../../assets/images/csv-1.jpeg"
              class="absolute h-full w-full object-contain object-center"
            />
          </div>
          <div
            class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito text-center mb-[10px]"
          >
            Tener entre los 0 y 18 años, con diagnóstico de discapacidad
            física-motora o con diagnóstico de autismo.
          </div>
        </div>
        <div class="md:w-1/3 md:m-0 mb-[50px]">
          <div class="relative h-[160px] mb-[20px]">
            <img
              src="../../../assets/images/csv-2.webp"
              class="absolute h-full w-full object-contain object-center"
            />
          </div>
          <div
            class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito text-center mb-[10px]"
          >
            No tener ningún seguro privado de salud o Essalud.
          </div>
        </div>
        <div class="md:w-1/3 md:m-0 mb-[50px]">
          <div class="relative h-[160px] mb-[20px]">
            <img
              src="../../../assets/images/csv-3.png"
              class="absolute h-full w-full object-contain object-center"
            />
          </div>
          <div
            class="text-[#043D57] text-[24px] max-[992px]:text-[16px] font-nunito text-center mb-[10px]"
          >
            Condición socioeconómica de vulnerabilidad.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative py-[50px] bg-[#043d57]">
    <div class="max-w-screen-large mx-auto max-[992px]:px-[20px]">
      <app-base-title
        title="¿Qué debes hacer para solicitar ingresar a Teletón?"
        color="text-[#ffffff]"
        customClass="md:mb-[10px] mb-[20px]"
      ></app-base-title>
      <div
        class="text-[#ffffff] text-[25px] max-[992px]:text-[16px] font-nunito text-justify md:mb-[40px] mb-[20px]"
      >
        Si la niña o niño tiene el perfil descrito anteriormente, sus padres o
        apoderados deben seguir los siguientes pasos:
      </div>
      <div
        class="flex mb-[20px] animate__animated animate__delay-0.5s animate__animated animate__delay-0.5s"
        [appAnimateOnScroll]="'animate__fadeInRightBig'"
      >
        <div class="w-[80px]">
          <img
            src="../../../assets/images/csv-5.png"
            class="w-auto max-[992px]:w-[50px]"
          />
        </div>
        <div class="w-full pl-[20px] max-[992px]:pl-0">
          <div
            class="text-[33px] max-[992px]:text-[30px] text-[#ffffff] font-nunito font-bold md:my-[10px] max-[992px]:mb-[10px] max-[992px]:leading-[100%]"
          >
            Elige un centro
          </div>
          <div
            class="text-[24px] max-[992px]:text-[16px] max-[992px]:leading-[110%] text-[#ffffff] font-nunito font-normal"
          >
            Ubica la dirección de la Clínica San Juan de Dios más cercana al
            lugar de tu residencia. Puedes ver aquí las sedes a nivel nacional.
          </div>
        </div>
      </div>
      <div class="py-[20px]">
        <app-tabs-zones></app-tabs-zones>
      </div>
      <div
        class="md:flex gap-[20px] animate__animated animate__delay-0.5s animate__animated animate__delay-0.5s"
        [appAnimateOnScroll]="'animate__fadeInRightBig'"
      >
        <div class="md:w-1/2 flex mb-[20px]">
          <div class="w-[80px]">
            <img
              src="../../../assets/images/csv-6.png"
              class="w-auto max-[992px]:w-[50px]"
            />
          </div>
          <div class="w-full pl-[20px]">
            <div
              class="text-[33px] max-[992px]:text-[30px] text-[#ffffff] font-nunito font-bold md:my-[10px] max-[992px]:mb-[10px] max-[992px]:leading-[100%]"
            >
              Pide orientación
            </div>
            <div
              class="text-[24px] max-[992px]:text-[16px] max-[992px]:leading-[110%] text-[#ffffff] font-nunito font-normal"
            >
              Acércate a cualquier módulo de atención al usuario con el DNI en
              físico de la niña o niño.
            </div>
          </div>
        </div>
        <div class="md:w-1/2 flex mb-[20px]">
          <div class="w-[80px]">
            <img
              src="../../../assets/images/csv-7.png"
              class="w-auto max-[992px]:w-[50px]"
            />
          </div>
          <div class="w-full pl-[20px]">
            <div
              class="text-[33px] max-[992px]:text-[30px] text-[#ffffff] font-nunito font-bold md:my-[10px] max-[992px]:mb-[10px] max-[992px]:leading-[100%]"
            >
              Acércate a servicio social
            </div>
            <div
              class="text-[24px] max-[992px]:text-[16px] max-[992px]:leading-[110%] text-[#ffffff] font-nunito font-normal"
            >
              Cuando te lo indiquen, acércate a la oficina de servicio social,
              donde recibirás la información necesaria para la cita de
              evaluación.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative pt-[50px]">
    <img
      src="../../../assets/images/circle-top-left.png"
      class="absolute top-0 left-0 md:w-auto w-full h-auto"
    />
    <div class="max-w-screen-large mx-auto max-[992px]:px-[20px]">
      <app-base-title
        title="¿Qué diagnósticos frecuentes se atienden?"
        color="text-[#FC1821] text-center"
        customClass="md:mb-[40px] mb-[20px]"
      ></app-base-title>
      <div class="md:flex gap-[30px] items-center">
        <div
          class="md:w-1/2 max-[992px]:mb-[40px] animate__animated animate__delay-0.5s"
          [appAnimateOnScroll]="'animate__fadeInLeft'"
        >
          <img src="../../../assets/images/csv-4.png" class="w-full" />
        </div>
        <div class="md:w-1/2">
          <app-accordion
            title="1- Parálisis Cerebral Infantil"
            [index]="0"
            [defaultOpen]="true"
          >
            <div
              class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
            >
              La parálisis cerebral es una condición neurológica crónica que se
              produce por el daño en el cerebro inmaduro en desarrollo, con
              mayor frecuencia antes del nacimiento. <br />
              Se caracteriza por movimientos corporales alterados, retraso
              psicomotor y, si no es tratada oportunamente, puede producir
              deformidades en las extremidades y el tronco.
            </div>
          </app-accordion>
          <app-accordion
            title="2- Artrogriposis múltiple congénita"
            [index]="1"
          >
            <div
              class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
            >
              La artrogriposis múltiple congénita (AMC) o síndrome
              artrogripótico es el diagnóstico que se hace a niñas y niños que
              nacen con rigidez de dos o más articulaciones, producto de una
              disminución de la movilidad mientras están en el vientre materno.
            </div>
          </app-accordion>
          <app-accordion title="3- Daño Cerebral adquirido" [index]="2">
            <div
              class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
            >
              Este diagnóstico se refiere a lesiones cerebrales severas o
              moderadas. Las lesiones más frecuentes se deben a traumatismos
              encefalocraneanos, aunque también a rotura o trombosis de vasos
              sanguíneos cerebrales (accidentes cerebrovasculares), presencia de
              tumores, asfixias o algunas enfermedades.
            </div>
          </app-accordion>
          <app-accordion
            title="4- Malformaciones congénitas y amputaciones"
            [index]="3"
          >
            <div
              class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
            >
              Se le llama malformaciones congénitas a la ausencia de una o más
              partes de las extremidades al momento de nacer y a una alteración
              de la estructura de partes del cuerpo (esqueleto y órganos). Se
              denomina amputación a la pérdida de un segmento que estaba
              presente, por causas traumáticas (accidentes) o enfermedades
              (tumores). Las amputaciones pueden ser de miembros superiores o
              inferiores.
            </div>
          </app-accordion>
          <app-accordion title="5- Autismo" [index]="4">
            <div
              class="text-[21px] max-[992px]:text-[17px] max-[992px]:leading-[120%] p-[20px] max-[992px]:px-[0px] text-[#043D57] text-justify"
            >
              El Trastorno del Espectro Autista (TEA) es una condición de origen
              neurobiológico que afecta a la configuración del sistema nervioso
              y al funcionamiento cerebral. Acompaña a la persona a lo largo de
              su vida y afecta, fundamentalmente, a dos áreas del funcionamiento
              personal: la comunicación e interacción social, y la flexibilidad
              del comportamiento y del pensamiento.
            </div>
          </app-accordion>
        </div>
      </div>
    </div>
  </div>
  <app-section-gratitude
    [dataHome]="dataGratitude"
    id="section-gratitude"
  ></app-section-gratitude>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
