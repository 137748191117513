import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  SlickCarouselComponent,
  SlickCarouselModule,
} from "ngx-slick-carousel";
import { NgxSlickItemModule } from "../../../components/slider/ngx-slick-item.module";
import { DeviceDetectorComponent } from "../../../components/device/device.component";

@Component({
  selector: "app-slider-renders",
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    NgxSlickItemModule,
    DeviceDetectorComponent,
  ],
  templateUrl: "./slider-renders.component.html",
  styleUrl: "./slider-renders.component.css",
})
export class SliderRendersComponent implements AfterViewInit, OnChanges {
  isMobile: boolean = false;
  @Input() data: any = [];
  @ViewChild("slickModal", { static: false })
  slickModal!: SlickCarouselComponent;

  @ViewChild("slickModalMobile", { static: false })
  slickModalMobile!: SlickCarouselComponent;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  ngAfterViewInit() {
    this.initSlick();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["data"] && this.data.length) {
      this.initSlick();
    }
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
    this.initSlick();
  }

  private initSlick() {
    setTimeout(() => {
      if (this.isMobile && this.slickModalMobile) {
        this.slickModalMobile.initSlick();
      } else if (!this.isMobile && this.slickModal) {
        this.slickModal.initSlick();
      }
    }, 100);
  }
}
