<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<div class="py-[20px]">
  <div class="slider-container slider-renders">
    <ngx-slick-carousel #slickModal [config]="slideConfig" *ngIf="!isMobile">
      <div
        class="box-shadow-card my-[20px] md:mx-[10px] mx-[20px]"
        *ngFor="let slide of data"
      >
        <a [routerLink]="['/noticias', convertToSlug(slide.title)]">
          <div class="relative md:h-[230px] h-[210px]">
            <img
              [src]="slide.banner_img_desktop"
              class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
            />
          </div>
        </a>
        <div class="p-[20px]">
          <a
            [routerLink]="['/noticias', convertToSlug(slide.title)]"
            class="block no-underline"
          >
            <div
              class="line-clamp-2 text-[#1B1D26] text-[25px] leading-[110%] font-nunito font-black mb-[20px] h-[55px]"
            >
              {{ slide.title }}
            </div>
          </a>
          <div
            class="line-clamp-4 text-[16px] text-[#848484] font-nunito leading-[1.8] mb-[20px] h-[115px]"
          >
            {{ slide.summary }}
          </div>
          <a
            [routerLink]="['/noticias', convertToSlug(slide.title)]"
            class="block max-w-max text-[#FC1821] text-[16px] no-underline font-black font-nunito"
            >Leer más</a
          >
        </div>
      </div>
    </ngx-slick-carousel>

    <ngx-slick-carousel
      #slickModalMobile
      [config]="slideConfig"
      *ngIf="isMobile"
    >
      <div
        class="box-shadow-card my-[20px] md:mx-[10px] mx-[20px]"
        *ngFor="let slide of data"
      >
        <div class="relative md:h-[230px] h-[210px]">
          <img
            [src]="slide.banner_img_desktop"
            class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
          />
        </div>
        <div class="p-[20px]">
          <div
            class="line-clamp-2 text-[#1B1D26] text-[25px] leading-[110%] font-nunito font-black mb-[20px] h-[55px]"
          >
            {{ slide.title }}
          </div>
          <div
            class="line-clamp-4 text-[16px] text-[#848484] font-nunito leading-[1.8] mb-[20px] h-[115px]"
          >
            {{ slide.summary }}
          </div>
          <a
            [routerLink]="['/noticias', convertToSlug(slide.title)]"
            class="block max-w-max text-[#FC1821] text-[16px] no-underline font-black font-nunito"
            >Leer más</a
          >
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
