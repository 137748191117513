<div class="relative" *ngIf="!isMobile">
  <div class="relative">
    <div class="absolute left-[20px] top-[40px] z-[1]" *ngIf="!isRoot">
      <a href="./" class="block no-underline">
        <img src="../../../../assets/images/logo-full.png" class="w-[220px]" />
      </a>
    </div>
    <div class="social-header flex items-center gap-[10px] mb-[20px]">
      <a
        href="https://www.facebook.com/FundacionTeletonPeru/"
        class="block"
        target="_blank"
      >
        <img
          src="../../../../assets/images/icon-facebook-white.png"
          class="w-[25px]"
        />
      </a>
      <a
        href="https://www.instagram.com/teleton_peru/"
        class="block"
        target="_blank"
      >
        <img
          src="../../../../assets/images/icon-instagram-white.png"
          class="w-[25px]"
        />
      </a>
      <a
        href="https://www.tiktok.com/@teletonperu"
        class="block"
        target="_blank"
      >
        <img
          src="../../../../assets/images/icon-tiktok-white.png"
          class="w-[25px]"
        />
      </a>
      <a href="https://x.com/teleton_peru" class="block" target="_blank">
        <img
          src="../../../../assets/images/icon-twitter-white.png"
          class="w-[25px]"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/teletonperu/mycompany/"
        class="block"
        target="_blank"
      >
        <img
          src="../../../../assets/images/icon-linkedin-white.png"
          class="w-[25px]"
        />
      </a>
      <a
        href="https://www.youtube.com/@FundacionTeletonPeru"
        class="block"
        target="_blank"
      >
        <img
          src="../../../../assets/images/icon-youtube-white.png"
          class="w-[25px]"
        />
      </a>
    </div>
  </div>
  <button class="menu-button" (click)="toggleMenu()">
    <div class="hamburger-icon">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>

  <div
    class="full-screen-menu font-nunito"
    [ngClass]="{ open: isMenuOpen, 'fade-in': isMenuOpen }"
  >
    <button class="close-button" (click)="toggleMenu()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-10 h-10"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <div class="max-w-screen-large mx-auto w-full">
      <div class="relative py-[40px] px-[20px]">
        <div class="mb-[20px]">
          <a href="./" class="block no-underline">
            <img
              src="../../../../assets/images/logo-full.png"
              class="w-[260px]"
            />
          </a>
        </div>
        <div class="list-menu-desktop flex gap-[20px]">
          <div class="md:w-1/3">
            <div class="relative mb-[10px]">
              <a href="/" rel="noopener">Inicio</a>
            </div>
            <div class="relative mb-[10px]">
              <a href="/sobre-nosotros" rel="noopener">Sobre nosotros</a>
            </div>
            <div class="relative mb-[10px]">
              <a href="/que-hacemos" rel="noopener">¿Qué hacemos?</a>
            </div>
            <div class="relative mb-[10px]">
              <a href="/como-acceder" rel="noopener"
                >¿Cómo acceder a Teletón?</a
              >
            </div>
            <div class="relative mb-[10px]">
              <a href="/rinde-cuentas" rel="noopener">Rinde cuentas</a>
            </div>
            <div class="relative mb-[10px]">
              <a
                href="/telepadrinos/"
                class="!text-[#F74F22] hover:!text-[#ffffff]"
                >Telepadrinos</a
              >
            </div>
          </div>
          <div class="md:w-1/3">
            <span class="block mb-[20px]">Quiero ser parte del Teletón</span>
            <div class="text-center">
              <a
                href="/ser-voluntario"
                rel="noopener"
                class="p-[10px] bg-[rgba(255,255,255,0.3)] block w-max ml-[30px] mb-[20px]"
                >Quiero ser voluntario</a
              >
            </div>
            <div class="relative mb-[10px]">
              <a href="/noticias/" rel="noopener">Noticias</a>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="flex items-center justify-center gap-[10px] mb-[20px]">
              <a
                href="https://www.facebook.com/FundacionTeletonPeru/"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-facebook-white.png"
                  class="w-[38px]"
                />
              </a>
              <a
                href="https://www.instagram.com/teleton_peru/"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-instagram-white.png"
                  class="w-[38px]"
                />
              </a>
              <a
                href="https://www.tiktok.com/@teletonperu"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-tiktok-white.png"
                  class="w-[38px]"
                />
              </a>
              <a
                href="https://x.com/teleton_peru"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-twitter-white.png"
                  class="w-[38px]"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/teletonperu/mycompany/"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-linkedin-white.png"
                  class="w-[38px]"
                />
              </a>
              <a
                href="https://www.youtube.com/@FundacionTeletonPeru"
                class="block"
                target="_blank"
              >
                <img
                  src="../../../../assets/images/icon-youtube-white.png"
                  class="w-[38px]"
                />
              </a>
            </div>
            <div class="font-quicksand mx-auto elementor-animation-pop">
              <a
                (click)="openModal()"
                class="btn-dona cursor-pointer block py-[18px] px-[30px] text-[#ffffff] bg-[#FC1821] no-underline rounded-[20px] font-bold text-[24px] uppercase flex items-center gap-[10px] mx-auto"
              >
                ¡Donar Ahora!
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="heart-icon"
                  fill="white"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="relative h-[70px] bg-[#FC1821] flex items-center" *ngIf="isMobile">
  <div class="relative flex items-center justify-between w-full px-[20px]">
    <a href="./" class="block no-underline">
      <img src="../../../../assets/images/logo-mobile.png" class="w-[170px]" />
    </a>
    <button class="bg-transparent border-0" (click)="toggleMenu()">
      <div class="hamburger-icon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </button>
  </div>
  <div
    class="full-screen-menu font-nunito"
    [ngClass]="{ open: isMenuOpen, 'fade-in': isMenuOpen }"
  >
    <button class="close-button" (click)="toggleMenu()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <div class="landscape-view max-w-screen-large mx-auto w-full">
      <div class="relative py-[40px] px-[20px]">
        <div class="mb-[20px]">
          <a href="./" class="block no-underline">
            <img
              src="../../../../assets/images/logo-mobile.png"
              class="w-[170px]"
            />
          </a>
        </div>
        <div class="relative">
          <div class="relative py-[10px]">
            <a href="/" rel="noopener">Inicio</a>
          </div>
          <div class="relative py-[10px]">
            <a href="/sobre-nosotros" rel="noopener">Sobre nosotros</a>
          </div>
          <div class="relative py-[10px]">
            <a href="/que-hacemos" rel="noopener">¿Qué hacemos?</a>
          </div>
          <div class="relative py-[10px]">
            <a href="/como-acceder" rel="noopener">¿Cómo acceder a Teletón?</a>
          </div>
          <div class="relative py-[10px]">
            <a href="/rinde-cuentas" rel="noopener">Rinde cuentas</a>
          </div>
          <div class="relative py-[10px]">
            <a
              href="/telepadrinos/"
              class="!text-[#F74F22] hover:!text-[#ffffff]"
              >Telepadrinos</a
            >
          </div>

          <div class="relative">
            <div
              class="flex text-[#ffffff] font-bold items-center py-[10px]"
              (click)="togglePart()"
            >
              Quiero ser parte del Teletón
              <span
                class="flex ml-2 transition-transform duration-200"
                [class.rotate-180]="isPartOpen"
                >&#9662;</span
              >
            </div>
            <div class="text-center ml-[20px] py-[10px]" *ngIf="isPartOpen">
              <a
                href="/ser-voluntario"
                rel="noopener"
                target="_blank"
                class="block w-max"
                >Quiero ser voluntario</a
              >
            </div>
          </div>
          <div class="relative py-[10px]">
            <a href="/noticias/" rel="noopener">Noticias</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modals #modal>
  <div
    class="md:text-[40px] text-[30px] font-bold text-center font-nunito mb-[15px]"
  >
    ¿Como donar?
  </div>
  <div
    class="w-auto md:text-[20px] text-[16px] font-nunito rounded-[20px] border-[10px] border-solid border-[#2ba9ea] leading-[110%] relative z-[2] overflow-hidden md:px-[20px] px-[15px] md:py-[20px] py-[15px] mb-[20px] text-center"
  >
    <div class="bg-overlay-title bg-overlay-title-blue"></div>
    Tienes dos formas de hacerlo: a través de una donación única o
    convirtiéndote en Telepadrino. ¡Escoge la opción que mejor se adapte a ti y
    ayuda a transformar vidas hoy mismo!
  </div>
  <div class="text-[20px] text-center font-nunito mb-[15px]">Como donar</div>
  <div class="md:flex gap-[20px] mb-[10px]">
    <a class="custom-radio no-underline" href="/donativo/donar-ahora">
      <div class="block">
        <img
          src="../../../../assets/images/donacion-unica.webp"
          class="w-[100px] max-[768px]:w-[60px]"
        />
        <div class="md:text-[38px] text-[30px] font-lilita text-[#000000]">
          Donación única
        </div>
      </div>
    </a>
    <a class="custom-radio no-underline" href="/telepadrinos">
      <div class="block">
        <img
          src="../../../../assets/images/telepadrinos-logo.webp"
          class="w-[100px] max-[768px]:w-[60px]"
        />
        <div class="md:text-[38px] text-[30px] font-lilita text-[#000000]">
          Donación Mensual
        </div>
      </div>
    </a>
  </div>
</app-modals>
