import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appAnimateOnScroll]',
})
export class AnimateOnScrollDirective {
  @Input('appAnimateOnScroll') animationClass: string = '';
  @Input() delay: number = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const elementPosition = this.el.nativeElement.getBoundingClientRect().top;
    const viewHeight = window.innerHeight;

    if (elementPosition < viewHeight) {
      setTimeout(() => {
        this.renderer.addClass(this.el.nativeElement, this.animationClass);
      }, this.delay);
    }
  }
}
