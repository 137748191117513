import { CommonModule } from "@angular/common";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { convertToSlug } from "../../../utils/utils";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../../../shared/directives/shared.module";
import { HeaderPagesComponent } from "../../layout/components/header-pages/header-pages.component";

@Component({
  selector: "app-donation",
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, HeaderPagesComponent],
  templateUrl: "./donation.component.html",
  styleUrl: "./donation.component.css",
})
export class DonationComponent implements OnInit {
  public dataNewsDetail: any;
  public safeHtmlContent: SafeHtml | null = null;
  public postSlug: string = "";

  name: string = "";
  lastname: string = "";
  email: string = "";
  amount: number | null = null;
  phone: string = ""; // Add phone variable
  agreeToTerms: boolean = false;
  clientIp: string = ""; // Add client IP variable
  jwtToken: string | null = null; // Store JWT token here

  private backendUrl = environment.backendUrl;
  private sharedSecret = environment.sharedSecret; // Obtain shared secret from environment

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  // ngOnInit(): void {
  //   this.route.params.subscribe((params) => {
  //     this.postSlug = params["slug"];
  //     this.dataNewsDetail = this.newsData.find(
  //       (news) => this.convertToSlug(news.title) === this.postSlug
  //     );

  //     if (this.dataNewsDetail && this.dataNewsDetail.text_column) {
  //       this.setHtmlContent(this.dataNewsDetail.text_column.join("<br/>"));
  //     }

  //     // if (!this.dataNewsDetail) {
  //     //   this.router.navigate(["/"]);
  //     // }
  //   });
  // }

  setHtmlContent(htmlContent: string) {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  convertToSlug(title: string): string {
    return convertToSlug(title);
  }

  ngOnInit() {
    this.getClientIp(); // Get client IP on initialization
    this.getToken("payments", "Pruebas123"); // Get JWT token on initialization
  }

  validateNoNumbers(event: KeyboardEvent): void {
    const charCode = event.charCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  }

  validateNoSpecialChars(event: KeyboardEvent): void {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getClientIp() {
    this.http
      .get<{ ip: string }>("https://api64.ipify.org?format=json")
      .subscribe(
        (response) => {
          this.clientIp = response.ip;
          //console.log("Client IP obtained:", this.clientIp);
        },
        (error) => {
          console.error("Error obtaining client IP:", error);
        }
      );
  }

  getToken(username: string, password: string): void {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    this.http
      .post<{ access: string }>(
        `${this.backendUrl}/api/token/`,
        { username, password },
        { headers }
      )
      .subscribe(
        (response) => {
          this.jwtToken = response.access;
          //console.log("Token obtained:", this.jwtToken);
        },
        (error) => {
          console.error("Error obtaining JWT Token:", error);
        }
      );
  }

  getJwtToken() {
    this.http
      .post<{ token: string }>(`${this.backendUrl}/api/get-token`, {
        shared_secret: this.sharedSecret,
      })
      .subscribe(
        (response) => {
          this.jwtToken = response.token;
          //console.log("JWT Token obtained:", this.jwtToken);
        },
        (error) => {
          console.error("Error obtaining JWT Token:", error);
        }
      );
  }

  onSubmit() {
    if (!this.jwtToken) {
      console.error("JWT token not available");
      return;
    }

    const paymentData = {
      name: this.name,
      lastname: this.lastname,
      email: this.email,
      amount: this.amount,
      phone: this.phone, // Include phone in the data sent to the backend
      clientIp: this.clientIp, // Include client IP in the data sent to the backend
    };

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.jwtToken}`,
    });

    this.http
      .post(`${this.backendUrl}/api/payment/getPmtTrx/`, paymentData, {
        headers,
      })
      .subscribe((response: any) => {
        this.openVisaNetForm(response);
      });
  }

  openVisaNetForm(paymentData: any) {
    // Load the VisaNet script dynamically
    const script = this.renderer.createElement("script");
    script.src = paymentData.checkouturl;
    script.type = "text/javascript";
    script.onload = () => {
      // Log the data being sent to VisaNet
      const dataToSend = {
        sessiontoken: paymentData.sessiontoken,
        channel: "web",
        merchantid: paymentData.merchantid,
        purchasenumber: paymentData.purchasenumber,
        amount: this.amount?.toString() || "0",
        expirationminutes: paymentData.expirationminutes,
        timeouturl: paymentData.timeouturl,
        merchantlogo: paymentData.merchantlogo,
        formbuttoncolor: paymentData.formbuttoncolor,
        action: paymentData.action,
        "data-cardholdername": this.name,
        "data-cardholderlastname": this.lastname,
        "data-cardholderemail": this.email,
      };
      //console.log("Data being sent to VisaNet:", dataToSend);

      // Once the script is loaded, configure and open the VisaNet form
      (window as any).VisanetCheckout.configure({
        ...dataToSend,
        complete: function (params: any) {
          alert(JSON.stringify(params));
        },
      });
      (window as any).VisanetCheckout.open();
    };
    this.renderer.appendChild(document.body, script);
  }
}
