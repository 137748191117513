import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-base-title",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./base-title.component.html",
  styleUrl: "./base-title.component.css",
})
export class BaseTitleComponent {
  @Input() title?: any;
  @Input() color?: any = "text-[#043D57]";
  @Input() customClass?: any;
}
