import { CommonModule } from "@angular/common";
import {
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { DeviceDetectorComponent } from "../device/device.component";

@Component({
  selector: "app-banner",
  standalone: true,
  imports: [CommonModule, DeviceDetectorComponent],
  templateUrl: "./banner.component.html",
  styleUrl: "./banner.component.css",
})
export class BannerComponent implements OnInit {
  isMobile: boolean = false;
  @Input() dataHome?: any;
  data: any;
  activeTab: string | null = null;
  marginOffset = 100;

  constructor() {}

  ngOnInit(): void {
    this.onScroll();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const sections = [
      "section-quienes-somos",
      "section-iniciativas",
      "section-noticias",
    ];
    for (let i = 0; i < sections.length; i++) {
      const sectionId = sections[i];
      const element = document.getElementById(sectionId);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          this.activeTab = sectionId;
          break;
        }
      }
    }
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - this.marginOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      this.activeTab = sectionId;
    }
  }

  isActive(tabId: string): boolean {
    return this.activeTab === tabId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataHome !== undefined) {
      this.data = changes["dataHome"].currentValue;
    }
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  bannerLoaded: boolean = false;

  onImageLoad() {
    this.bannerLoaded = true;
  }
}
