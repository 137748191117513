export function convertToSlug(title: string): string {
  if (!title) return "";

  return title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^\-+/, "")
    .replace(/\-+$/, "");
}

export function convertLineBreaksToHTML(text: string): string {
  return text.replace(/\r?\n/g, "<br>");
}

export function formatDateFns(data: string): string {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const date = new Date(data);
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${year}`;
}
