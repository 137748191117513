import { CommonModule } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-section-stores",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./section-stores.component.html",
  styleUrl: "./section-stores.component.css",
})
export class SectionStoresComponent {
  @Input() dataHome?: any;
  data: any;

  storeLogos = [
    {
      src: "../../../../../assets/images/logo_plaza_vea_stores.png",
      alt: "Plaza Vea",
    },
    { src: "../../../../../assets/images/logo_promart.png", alt: "Promart" },
    { src: "../../../../../assets/images/logo_ripley.png", alt: "Ripley" },
    { src: "../../../../../assets/images/logo_tambo.png", alt: "Tambo" },
    { src: "../../../../../assets/images/logo_makro.png", alt: "Makro" },
    { src: "../../../../../assets/images/logo_mass.png", alt: "Mass" },
    { src: "../../../../../assets/images/logo_vivanda.png", alt: "Vivanda" },
    { src: "../../../../../assets/images/logo_aruma.png", alt: "Aruma" },
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataHome !== undefined) {
      this.data = changes["dataHome"].currentValue;
      //console.log(this.data);
    }
  }
}
