import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HomePageService } from "../../home/services/home.service";
import { NewsPageService } from "../services/news.service";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { convertToSlug } from "../../../../utils/utils";
import { BannerLayoutComponent } from "../../../layout/components/banner-layout/banner-layout.component";
import { BaseTitleComponent } from "../../../components/base-title/base-title.component";

@Component({
  selector: "app-news-slug",
  standalone: true,
  imports: [CommonModule, BannerLayoutComponent, BaseTitleComponent],
  templateUrl: "./news-slug.component.html",
  styleUrl: "./news-slug.component.css",
})
export class NewsSlugComponent implements OnInit {
  slug: string = "";
  isMobile: boolean = false;
  public dataHome?: any;
  public dataFooter?: any;
  public dataGratitude: any;
  public dataNews: any;
  public filteredNews: any;
  public filteredNewsHtmlContent?: SafeHtml;

  constructor(
    private route: ActivatedRoute,
    private homeDataService: HomePageService,
    private newsDataService: NewsPageService,
    private metaService: Meta,
    private titleService: Title,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get("slug") || "";
      console.log(this.slug);
    });

    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.dataGratitude = this.dataHome.sponsors_logos;
      this.dataFooter = {
        footer_logo: this.dataHome.footer_logo,
        footer_ribbon_txt: this.dataHome.footer_ribbon_txt,
        footer_txt: this.dataHome.footer_txt,
        social_networks: this.dataHome.social_networks,
      };
    });

    this.newsDataService.getNews().subscribe((data) => {
      this.dataNews = data.results;

      this.filteredNews = this.dataNews.filter(
        (newsItem: any) =>
          convertToSlug(newsItem.title) === this.slug.toLowerCase()
      );

      if (this.filteredNews.length === 0) {
        console.log("No se encontraron noticias que coincidan con el slug");
      } else {
        this.filteredNews = this.filteredNews[0];
        this.filteredNewsHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
          this.filteredNews.html_content
        );
      }

      this.metaService.updateTag({
        name: "description",
        content: this.dataHome.seo_description,
      });
      this.titleService.setTitle(this.filteredNews?.title + " - Teleton Perú");
    });
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  convertToSlug(title: string): string {
    return convertToSlug(title);
  }
}
