import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-banner-layout",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./banner-layout.component.html",
  styleUrl: "./banner-layout.component.css",
})
export class BannerLayoutComponent {
  @Input() title?: string;
  @Input() imgDesktop?: string;
  @Input() imgMobile?: string;
}
