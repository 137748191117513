<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<section class="how-to-access">
  <app-banner-layout
    [title]="'Noticias'"
    [imgDesktop]="'../../../assets/images/banner-news.png'"
    [imgMobile]="'../../../assets/images/banner-news.png'"
  ></app-banner-layout>
  <div class="relative py-[50px]">
    <div class="relative">
      <div class="max-w-screen-largeXL mx-auto w-full relative h-full">
        <div class="relative px-[20px]">
          <div class="md:flex gap-[30px]">
            <div class="md:w-1/2">
              <div class="relative md:h-[320px] h-[180px] mb-[20px]">
                <img
                  [src]="
                    isMobile
                      ? dataNewsPage?.news1_detail?.banner_img_mobile
                      : dataNewsPage?.news1_detail?.banner_img_desktop
                  "
                  class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
                />
              </div>
              <div class="text-html">
                <div
                  class="text-[29px] text-[#000000] font-quicksand mb-[20px] font-bold"
                  [innerHTML]="dataNewsPage?.news1_detail?.title"
                ></div>
                <div
                  class="text-[18px] text-[#000000] font-quicksand mb-[30px] font-bold"
                  [innerHTML]="dataNewsPage?.news1_detail?.subtitle"
                ></div>
                <div
                  class="text-[16px] text-[#616161] font-quicksand mb-[40px]"
                  [innerHTML]="dataNewsPage?.news1_detail?.html_content"
                ></div>
              </div>
            </div>
            <div class="md:w-1/2">
              <div class="relative md:h-[320px] h-[180px] mb-[20px]">
                <img
                  [src]="
                    isMobile
                      ? dataNewsPage?.news2_detail?.banner_img_mobile
                      : dataNewsPage?.news2_detail?.banner_img_desktop
                  "
                  class="absolute object-cover object-center z-[1] w-full h-full top-0 left-0"
                />
              </div>
              <div class="text-html">
                <div
                  class="text-[29px] text-[#000000] font-quicksand mb-[20px] font-bold"
                  [innerHTML]="dataNewsPage?.news2_detail?.title"
                ></div>
                <div
                  class="text-[18px] text-[#000000] font-quicksand mb-[30px] font-bold"
                  [innerHTML]="dataNewsPage?.news2_detail?.subtitle"
                ></div>
                <div
                  class="text-[16px] text-[#616161] font-quicksand mb-[40px]"
                  [innerHTML]="dataNewsPage?.news2_detail?.html_content"
                ></div>
              </div>
            </div>
          </div>
          <app-slider-news [data]="dataNews"></app-slider-news>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer-info [dataFooter]="dataHome" />
<app-footer [dataFooter]="dataHome" />
<app-floating-button></app-floating-button>
