<div class="relative py-[80px] px-[20px]">
  <div class="max-w-screen-largeXL mx-auto">
    <div class="relative font-quicksand">
      <div class="text-[40px] text-[#E01319] font-bold text-center">
        <div class="mb-[60px]">Agradecemos a:</div>
        <div
          class="md:flex block items-center justify-center gap-[80px] md:mb-[20px]"
        >
          <ng-container *ngFor="let logo of filterByGroup(1)">
            <img
              [src]="logo.logo"
              [alt]="logo.name"
              class="md:mb-0 mb-[10px] md:w-auto w-[180px]"
            />
          </ng-container>
        </div>
        <div class="block text-center md:py-[60px] py-[30px]">
          <img
            src="../../../../../assets/images/separator_yellow_largeXL.png"
            class="md:block hidden w-full"
          />
          <img
            src="../../../../../assets/images/separator_yellow_largeXL_mobile.png"
            class="md:hidden block w-full"
          />
        </div>
        <div class="max-w-screen-medium mx-auto">
          <div
            class="flex flex-wrap items-center gap-[20px] justify-center md:mt-0 mt-[10px] mb-[20px]"
          >
            <ng-container *ngFor="let logo of filterByGroup(2)">
              <img [src]="logo.logo" [alt]="logo.name" class="w-auto h-auto" />
            </ng-container>
          </div>
          <div
            *ngIf="filterByGroup(3)?.length > 0"
            class="flex flex-wrap items-center gap-[20px] justify-center md:mt-0 mt-[10px] mb-[20px]"
          >
            <ng-container *ngFor="let logo of filterByGroup(3)">
              <img [src]="logo.logo" [alt]="logo.name" class="w-auto h-auto" />
            </ng-container>
          </div>
          <div
            *ngIf="filterByGroup(4)?.length > 0"
            class="flex flex-wrap items-center gap-[20px] justify-center md:mt-0 mt-[10px] mb-[20px]"
          >
            <ng-container *ngFor="let logo of filterByGroup(4)">
              <img [src]="logo.logo" [alt]="logo.name" class="w-auto h-auto" />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
