<div
  class="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 modal-backdrop"
  *ngIf="isOpen"
  [ngClass]="{ show: isOpen }"
>
  <div
    class="relative rounded-lg shadow-lg modal-content"
    [ngClass]="{ show: isOpen }"
  >
    <button (click)="closeModal()" class="absolute top-2 right-2 text-xl">
      &times;
    </button>
    <div class="p-4">
      <iframe
        width="560"
        height="315"
        [src]="sanitizer.bypassSecurityTrustResourceUrl(videoUrl!)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
