<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<div class="py-[20px]">
  <div class="slider-container slider-renders">
    <ngx-slick-carousel #slickModal [config]="slideConfig" *ngIf="!isMobile">
      <div
        class="md:rounded-[20px] rounded-[30px] box-shadow-card my-[20px] md:mx-[10px] mx-[20px]"
        *ngFor="let slide of data"
      >
        <img
          [src]="slide.img_banner"
          class="w-full mb-[20px] rounded-[20px] box-shadow-card"
        />
        <div
          class="text-[#FC1821] text-[30px] max-[992px]:text-[20px] max-[768px]:text-[30px] leading-[110%] text-center w-[250px] max-[992px]:w-auto max-[768px]:w-[250px] mx-auto font-quicksand font-black mb-[20px]"
        >
          {{ slide.title }}
        </div>
        <div class="pb-[20px] text-center">
          <a
            [href]="slide.file"
            target="_blank"
            class="block font-nunito w-max no-underline text-[20px] font-bold bg-[#FC1821] text-[#ffffff] rounded-[10px] py-[10px] px-[30px] max-[992px]:py-[10px] mx-auto"
            >Descargar</a
          >
        </div>
      </div>
    </ngx-slick-carousel>

    <ngx-slick-carousel
      #slickModalMobile
      [config]="slideConfig"
      *ngIf="isMobile"
    >
      <div
        class="md:rounded-[20px] rounded-[30px] box-shadow-card my-[20px] md:mx-[10px] mx-[20px]"
        *ngFor="let slide of data"
      >
        <img
          [src]="slide.img_banner"
          class="w-full mb-[20px] rounded-[20px] box-shadow-card"
        />
        <div
          class="text-[#FC1821] text-[30px] max-[992px]:text-[20px] max-[768px]:text-[30px] leading-[110%] text-center w-[250px] max-[992px]:w-auto max-[768px]:w-[250px] mx-auto font-quicksand font-black mb-[20px]"
        >
          {{ slide.title }}
        </div>
        <div class="pb-[20px] text-center">
          <a
            [href]="slide.file"
            target="_blank"
            class="block font-nunito w-max no-underline text-[20px] font-bold bg-[#FC1821] text-[#ffffff] rounded-[10px] py-[10px] px-[30px] max-[992px]:py-[10px] mx-auto"
            >Descargar</a
          >
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
