<div
  class="relative bg-[#008AE9] px-[20px] md:pt-[100px] pb-[40px] pt-[40px] overflow-hidden"
>
  <div class="max-w-screen-short mx-auto">
    <div class="relative font-helloKidme md:px-[20px]">
      <div
        class="relative text-center shadow-text md:text-[100px] text-[70px] uppercase text-[#ffffff] mb-[40px] md:leading-[100%] leading-[90%]"
      >
        <img
          src="../../../../../assets/images/lateral_skyblue_left.png"
          class="absolute m-auto top-0 bottom-0 left-0"
        />
        <span>Canales de donacion</span>
        <img
          src="../../../../../assets/images/lateral_skyblue_right.png"
          class="absolute m-auto top-0 bottom-0 right-0"
        />
      </div>
      <div class="relative">
        <div class="relative">
          <div
            class="relative bg-[#ffffff] py-[60px] md:px-[60px] px-[40px] shadow-custom mb-[40px] z-[1]"
          >
            <img
              src="../../../../../assets/images/cornerback_top_left.webp"
              class="absolute top-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_top_right.webp"
              class="absolute top-[20px] right-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_left.webp"
              class="absolute bottom-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_right.webp"
              class="absolute bottom-[20px] right-[20px]"
            />
            <div class="flex justify-between flex-wrap">
              <div class="md:w-1/3 w-1/2 text-center md:order-0 order-1">
                <img
                  src="../../../../../assets/images/logo_yape.png"
                  class="md:w-auto w-[100px]"
                />
              </div>
              <div class="md:w-1/3 w-full text-center md:order-2 order-3">
                <div class="font-quicksand">
                  <div class="text-[20px] font-medium mb-[10px]">Yapea al</div>
                  <div class="text-[40px] font-bold">989 361 677</div>
                  <div class="block text-center mb-[10px]">
                    <img
                      src="../../../../../assets/images/separator_yellow_mini.png"
                      alt=""
                    />
                  </div>
                  <div class="text-[20px] font-medium">
                    o abre tu app Yape y escanea este código QR
                  </div>
                </div>
              </div>
              <div class="md:w-1/3 w-1/2 text-center md:order-0 order-2">
                <img
                  src="../../../../../assets/images/qr_yape.png"
                  class="md:w-auto w-[100px]"
                />
              </div>
            </div>
          </div>
          <img
            src="../../../../../assets/images/flor_amarilla.webp"
            class="md:absolute md:block hidden top-[-50px] right-[-120px]"
          />
        </div>

        <div class="relative">
          <div
            class="relative bg-[#ffffff] px-[20px] py-[60px] shadow-custom mb-[40px] z-[1]"
          >
            <img
              src="../../../../../assets/images/cornerback_top_left.webp"
              class="absolute top-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_top_right.webp"
              class="absolute top-[20px] right-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_left.webp"
              class="absolute bottom-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_right.webp"
              class="absolute bottom-[20px] right-[20px]"
            />
            <div
              class="md:flex block items-center justify-center md:w-[534px] mx-auto text-center md:mt-[0px] mt-[20px] mb-[40px]"
            >
              <img
                src="../../../../../assets/images/logo_bcp.png"
                class="w-[190px] md:mb-0 mb-[10px]"
              />
              <!-- <div class="relative text-center font-quicksand text-[24px]">
                Desde
                <a
                  href="https://www.viabcp.com"
                  target="_blank"
                  class="underline text-[#000000]"
                  >www.viabcp.com</a
                >
                ó desde la app del BCP
              </div> -->
            </div>
            <div class="md:flex block font-quicksand">
              <div class="md:w-1/2 w-full text-center">
                <div class="md:mb-[10px] mb-[20px]">
                  <span class="block text-[20px] mb-[5px] font-medium"
                    >CtaCorriente Soles:</span
                  >
                  <span
                    class="block md:text-[30px] text-[20px] font-bold no-underline"
                    >191-011-11-11-0-33</span
                  >
                </div>
                <div class="md:mb-[10px] mb-[20px]">
                  <span class="block text-[20px] mb-[5px] font-medium"
                    >CCI Soles:</span
                  >
                  <span class="block md:text-[24px] text-[20px] font-bold"
                    >002-191-000111111033-52</span
                  >
                </div>
              </div>
              <div class="md:w-1/2 w-full text-center">
                <div class="md:mb-[10px] mb-[20px]">
                  <span class="block text-[20px] mb-[5px] font-medium"
                    >Cuenta dólares:</span
                  >
                  <span
                    class="block md:text-[30px] text-[20px] font-bold no-underline"
                    >191-077-77-77-1-41</span
                  >
                </div>
                <div class="md:mb-[10px] mb-[20px]">
                  <span class="block text-[20px] mb-[5px] font-medium"
                    >CCI dólares:</span
                  >
                  <span class="block md:text-[24px] text-[20px] font-bold"
                    >002-191-000777777141-56</span
                  >
                </div>
              </div>
            </div>
            <div class="block text-center my-[40px]">
              <img
                src="../../../../../assets/images/separator_yellow_large.png"
                class="md:block hidden w-full"
              />
              <img
                src="../../../../../assets/images/separator_yellow_mobile.png"
                class="md:hidden block w-full"
              />
            </div>
            <div class="md:flex block font-quicksand md:mb-[20px] mb-[40px]">
              <div class="md:w-1/2 w-full text-center">
                <div
                  class="relative text-[24px] font-quicksand font-medium md:mb-0 mb-[20px]"
                >
                  Dona ya en la
                  <span class="font-bold">app Banca Móvil BCP,</span> ingresando
                  al botón <span class="font-bold">DONACIONES.</span>
                </div>
              </div>
              <div class="md:w-1/2 w-full text-center">
                <div class="relative px-[20px] pl-0">
                  <div
                    class="flex rounded-[10px] no-underline text-left border-[1px] border-solid border-[#f4eded] shadow-button md:px-[20px] px-[10px] pt-[10px] md:w-[85%] w-[100%]"
                  >
                    <div class="w-2/3">
                      <div
                        class="text-[#002a8c] font-bold md:text-[17px] text-[14px] mb-[5px]"
                      >
                        Donaciones
                      </div>
                      <span class="text-[14px] text-[#000000]"
                        >Transforma vidas y sé parte del cambio</span
                      >
                    </div>
                    <div class="w-1/3 flex items-center justify-between">
                      <img
                        src="../../../../../assets/images/hands-cora.jpg"
                        class="w-auto"
                      />
                      <img
                        src="../../../../../assets/images/arrow-right-blue.jpg"
                        class="h-[15px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../../../../assets/images/flor_azul.webp"
            class="absolute top-[-90px] left-[-90px]"
          />
          <img
            src="../../../../../assets/images/alas_verde_oscuro.png"
            class="absolute md:block hidden bottom-[90px] right-[-90px]"
          />
        </div>

        <div class="relative">
          <div
            class="relative bg-[#ffffff] px-[20px] py-[60px] shadow-custom mb-[40px] z-[1]"
          >
            <img
              src="../../../../../assets/images/cornerback_top_left.webp"
              class="absolute top-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_top_right.webp"
              class="absolute top-[20px] right-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_left.webp"
              class="absolute bottom-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_right.webp"
              class="absolute bottom-[20px] right-[20px]"
            />

            <div class="banks">
              <div class="relative" *ngFor="let bank of banks">
                <div class="block">
                  <div class="md:flex block gap-[20px] font-quicksand">
                    <div class="md:w-1/4 w-full text-center">
                      <img [src]="bank.logo" class="w-[160px]" />
                      <div
                        class="text-[#E01319] text-[16px] font-bold px-[5px] cursor-pointer"
                        (click)="toggleCci(bank)"
                      >
                        <span>Ver CCI</span>
                        <img
                          src="assets/images/icon_arrow_bottom_red.svg"
                          class="w-[10px] ml-[10px]"
                          [ngClass]="bank.showCci ? 'rotate-180' : 'rotate-0'"
                        />
                      </div>
                    </div>
                    <div class="md:w-3/4 w-full md:flex block gap-[20px]">
                      <div
                        *ngFor="let account of bank.accounts"
                        class="md:w-1/2 w-full md:text-left text-center md:mt-0 mt-[20px]"
                      >
                        <div class="mb-[10px]">
                          <span class="block text-[20px] mb-0 font-medium"
                            >{{ account.label }}:</span
                          >
                          <span class="block text-[20px] font-bold">{{
                            account.number
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex gap-[20px] font-quicksand mt-[-5px]">
                    <div class="md:w-1/4 md:block hidden text-center"></div>
                    <div
                      class="md:w-3/4 w-full md:flex block gap-[20px] md:mt-0 mt-[20px] md:text-left text-center"
                    >
                      <div
                        class="md:w-1/2 w-full"
                        *ngFor="let cci of bank.cci"
                        [ngClass]="{ hidden: !bank.showCci }"
                      >
                        <div class="mb-[10px]">
                          <span class="block text-[20px] mb-0 font-medium"
                            >{{ cci.label }}:</span
                          >
                          <span class="block text-[20px] font-bold">{{
                            cci.number
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="block text-center my-[20px]">
                    <img
                      src="assets/images/separator_yellow_large.png"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
              <div class="relative">
                <div class="block">
                  <div
                    class="md:flex block items-center gap-[20px] font-quicksand"
                  >
                    <div class="md:w-1/4 w-full text-center">
                      <img
                        src="../../../../../assets/images/logo_izipay.svg"
                        class="w-[160px]"
                      />
                    </div>
                    <div
                      class="md:w-3/4 w-full md:flex block items-center gap-[20px] md:text-left text-center"
                    >
                      <div
                        class="md:w-1/2 w-full text-[20px] font-medium md:mt-0 mt-[20px]"
                      >
                        Para clientes de otros bancos (No BCP) y billeteras
                        digitales, abre el app y escanea el código QR
                      </div>
                      <div class="md:w-1/2 w-full md:mt-0 mt-[20px]">
                        <img
                          src="../../../../../assets/images/qr_izipay.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="block text-center my-[20px]">
                    <img
                      src="assets/images/separator_yellow_large.png"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
              <div
                class="relative md:w-[530px] w-full md:text-left text-center mx-auto"
              >
                <div
                  class="md:flex block font-quicksand text-[16px] gap-[10px]"
                >
                  <div class="md:w-[130px] w-full md:mb-0 mb-[20px]">
                    Si te solicitan:
                  </div>
                  <div class="w-full">
                    <div class="md:flex block">
                      <span>Razón Social:</span>
                      <span class="font-bold md:mt-0 ml-[5px] block">
                        Fundación Teletón San Juan De Dios
                      </span>
                    </div>
                    <div class="md:flex block">
                      <span>RUC:</span>
                      <span class="font-bold md:mt-0 ml-[5px] block"
                        >20523760789</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../../../../assets/images/flor_rosada.webp"
            class="absolute md:block hidden top-[90px] right-[-110px]"
          />
          <img
            src="../../../../../assets/images/alas_verde_claro.png"
            class="absolute top-[150px] left-[-90px]"
          />
          <img
            src="../../../../../assets/images/alas_rojo.png"
            class="absolute md:block hidden bottom-[90px] right-[-90px]"
          />
        </div>

        <div class="relative">
          <div
            class="relative bg-[#ffffff] py-[60px] md:px-[60px] px-[20px] shadow-custom mb-[40px] z-[1]"
          >
            <img
              src="../../../../../assets/images/cornerback_top_left.webp"
              class="absolute top-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_top_right.webp"
              class="absolute top-[20px] right-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_left.webp"
              class="absolute bottom-[20px] left-[20px]"
            />
            <img
              src="../../../../../assets/images/cornerback_bottom_right.webp"
              class="absolute bottom-[20px] right-[20px]"
            />
            <div
              class="text-center md:text-[24px] text-[20px] font-quicksand mb-[20px]"
            >
              Desde el Perú o el extranjero:
            </div>
            <div class="md:flex block justify-between">
              <div class="md:w-1/2 w-full text-center">
                <div class="font-quicksand">
                  <div class="block">
                    <div class="w-full mb-[20px]">
                      <img
                        src="../../../../../assets/images/logo_paypal.svg"
                        class="md:w-auto w-[120px] mx-auto"
                      />
                    </div>
                    <div class="text-[20px] mb-[20px] w-[240px] mx-auto">
                      Envía tu donación vía PayPal a nuestra cuenta oficial
                      <span class="font-bold">&#64;teleton20</span>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="../../../../../assets/images/separator_vertical_yellow_mini.png"
                class="md:block hidden"
              />
              <img
                src="../../../../../assets/images/separator_horizontal_yellow_mini.png"
                class="md:hidden block w-[170px] mx-auto mb-[20px]"
              />
              <div class="md:w-1/2 w-full text-center font-quicksand">
                <div
                  class="md:text-[20px] text-[16px] md:mb-[50px] mb-[30px] font-bold"
                >
                  Con tarjetas de crédito y débito
                </div>
                <div class="flex items-center justify-center">
                  <a
                    href="/donativo/donar-ahora"
                    class="block md:w-auto w-[120px] md:px-[20px] md:py-[20px] py-[15px] px-[10px] text-[#ffffff] md:text-[24px] text-[16px] bg-[#E30613] no-underline rounded-[20px] font-bold"
                    >¡Dona Ahora!</a
                  >
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../../../../assets/images/flor_amarilla.webp"
            class="absolute md:block hidden top-[-80px] left-[-120px]"
          />
          <img
            src="../../../../../assets/images/flor_naranja.png"
            class="absolute md:block hidden m-auto bottom-0 top-0 right-[-100px]"
          />
        </div>
      </div>
    </div>
  </div>
</div>
