<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<div class="relative py-[80px] px-[20px] bg-[#008AE9]">
  <div class="max-w-screen-largeXL mx-auto">
    <div class="max-w-screen-short mx-auto">
      <div
        class="relative text-center shadow-text md:text-[100px] text-[60px] md:leading-[100%] leading-[80%] uppercase text-[#ffffff] mb-[20px] flex items-baseline justify-between font-helloKidme"
      >
        <div class="md:mr-[20px] mr-[10px]">
          <img
            src="../../../../../assets/images/cornerback_top_left.webp"
            class="md:w-auto w-[40px]"
          />
        </div>
        <div class="block">HISTORIAS DE VIDA</div>
        <div class="md:ml-[20px] ml-[10px]">
          <img
            src="../../../../../assets/images/cornerback_top_right.webp"
            class="md:w-auto w-[40px]"
          />
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="md:flex block justify-center gap-[40px]">
        <div
          *ngFor="let story of data && data.site_videos"
          class="relative w-1/4 md:w-[240px] w-full h-[200px] cursor-pointer shadow-custom md:mb-0 mb-[20px]"
          (click)="openModal(story.video_url)"
        >
          <img
            [src]="isMobile ? story.video_img_mobile : story.video_img_desktop"
            class="relative h-full w-full object-cover md:object-bottom object-top absolute"
          />
          <img
            src="../../../../../assets/images/button_play_red.svg"
            class="absolute m-auto top-0 bottom-0 left-0 right-0"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center transition-opacity duration-300 ease-in-out z-[1]"
  [ngClass]="{ 'opacity-0 pointer-events-none': !isOpen }"
  (click)="onBackdropClick($event)"
>
  <button
    class="absolute top-[20px] right-[20px] text-black bg-transparent border-0"
    (click)="closeModal()"
  >
    <img
      src="../../../../../assets/images/icon-close-white.svg"
      class="w-[20px] brightness-0 invert-[1]"
    />
  </button>
  <div
    class="modal-content relative w-[100%] max-w-[800px] h-[450px] p-4 rounded-lg"
  >
    <iframe
      *ngIf="isOpen"
      width="100%"
      height="100%"
      [src]="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>
