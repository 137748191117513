<section class="how-to-access">
  <div class="relative md:py-[80px] bg-[#e30613]"></div>
  <div class="relative py-[50px]">
    <img
      src="../../../assets/images/circle-top-right.png"
      class="absolute top-0 right-0 w-[20%] h-auto"
    />
    <div
      class="max-w-screen-large mx-auto max-[992px]:px-[20px] relative z-[1]"
    >
      <app-base-title
        [title]="filteredNews?.title"
        color="text-[#043D57]"
        customClass="leading-[100%] text-center text-[45px] md:mb-[50px] mb-[20px]"
      ></app-base-title>
      <div class="relative">
        <img
          [src]="
            isMobile
              ? filteredNews?.banner_img_mobile
              : filteredNews?.banner_img_desktop
          "
          class="w-full mb-[20px]"
        />
      </div>
      <app-base-title
        [title]="filteredNews?.subtitle"
        color="text-[#043D57]"
        customClass="leading-[100%] text-center !text-[28px] mb-[40px]"
      ></app-base-title>
      <div class="text-html-news">
        <div
          class="md:text-[24px] text-[16px] text-[#043D57] font-nunito mb-[40px]"
          [innerHTML]="filteredNewsHtmlContent"
        ></div>
      </div>
    </div>
  </div>
</section>
