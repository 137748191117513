import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-modals",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./modals.component.html",
  styleUrl: "./modals.component.css",
})
export class ModalsComponent {
  @Output() onClose = new EventEmitter<void>();
  isOpen = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.onClose.emit();
  }
}
