import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { SectionGratitudeComponent } from "../home/sections/section-gratitude/section-gratitude.component";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { Meta, Title } from "@angular/platform-browser";
import { HomePageService } from "../home/services/home.service";
import { SharedDataService } from "../../layout/shared/shared-data.service";

@Component({
  selector: "app-error",
  standalone: true,
  imports: [
    CommonModule,
    SectionGratitudeComponent,
    FooterInfoComponent,
    FooterComponent,
  ],
  templateUrl: "./error.component.html",
  styleUrl: "./error.component.css",
})
export class ErrorComponent {
  public dataHome: any;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private homeDataService: HomePageService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.sharedDataService.changeData(this.dataHome);

      this.metaService.updateTag({
        name: "description",
        content: this.dataHome.seo_description,
      });
      this.titleService.setTitle(this.dataHome.seo_title);
    });
  }
}
