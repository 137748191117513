import { CommonModule } from "@angular/common";
import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-footer-static",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./footer-static.component.html",
  styleUrl: "./footer-static.component.css",
})
export class FooterStaticComponent {
  @Input() dataFooter?: any;
  footerData: any;

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataFooter !== undefined) {
      this.footerData = changes["dataFooter"].currentValue;
    }
  }
}
