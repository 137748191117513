<div class="tabs font-nunito">
  <button
    class="border-0 px-[50px] max-[992px]:px-[15px] py-[14px] rounded-tl-[15px] rounded-tr-[15px] cursor-pointer bg-[#FC1821] text-[19px] font-black text-[#ffffff] font-nunito outline-none"
    (click)="changeTab(0)"
  >
    Misión
  </button>
  <button
    class="border-0 px-[50px] max-[992px]:px-[15px] py-[14px] rounded-tl-[15px] rounded-tr-[15px] cursor-pointer bg-[#043D57] text-[19px] font-black text-[#ffffff] font-nunito outline-none"
    (click)="changeTab(1)"
  >
    Visión
  </button>
  <button
    class="border-0 px-[50px] max-[992px]:px-[15px] py-[14px] rounded-tl-[15px] rounded-tr-[15px] cursor-pointer bg-[#FC1821] text-[19px] font-black text-[#ffffff] font-nunito outline-none"
    (click)="changeTab(2)"
  >
    Valores
  </button>
</div>

<div
  class="tab-content px-[50px] max-[992px]:px-[20px] py-[40px] max-[992px]:py-[20px] text-[#ffffff] font-nunito"
  [ngClass]="{
    'bg-[#FC1821]': isEvenTab(),
    'bg-[#043D57]': !isEvenTab()
  }"
>
  <div
    *ngIf="previousTab === 0 || activeTab === 0"
    [ngClass]="{
      'fade-up': transitionDirection === 'up' && activeTab === 0,
      'fade-down': transitionDirection === 'down' && activeTab === 0,
      inactive: activeTab !== 0
    }"
  >
    <div class="text-[35px] font-bold mb-[10px] max-[992px]:leading-[100%]">
      Nuestra misión
    </div>
    <div
      class="text-[22px] max-[992px]:text-[16px] leading-[140%] max-[992px]:leading-[100%] text-justify"
    >
      Somos una organización sin fines de lucro que tiene como misión realizar
      rehabilitación de calidad a niñas y niños con bajos recursos económicos.
      Además, generar proyectos y programas sociales, lo que nos ayuda a obtener
      un cambio cultural enfocado en la inclusión social.
    </div>
  </div>

  <div
    *ngIf="previousTab === 1 || activeTab === 1"
    [ngClass]="{
      'fade-up': transitionDirection === 'up' && activeTab === 1,
      'fade-down': transitionDirection === 'down' && activeTab === 1,
      inactive: activeTab !== 1
    }"
  >
    <div class="text-[35px] font-bold mb-[10px] max-[992px]:leading-[100%]">
      Nuestra visión
    </div>
    <div
      class="text-[22px] max-[992px]:text-[16px] leading-[140%] max-[992px]:leading-[100%] text-justify"
    >
      Ser la fundación líder a nivel nacional en la inclusión social y
      rehabilitación de niñas y niños que no cuentan con los recursos económicos
      para recibir sus terapias, mejorando así su calidad de vida.
    </div>
  </div>

  <div
    *ngIf="previousTab === 2 || activeTab === 2"
    [ngClass]="{
      'fade-up': transitionDirection === 'up' && activeTab === 2,
      'fade-down': transitionDirection === 'down' && activeTab === 2,
      inactive: activeTab !== 2
    }"
  >
    <div class="text-[35px] font-bold mb-[10px] max-[992px]:leading-[100%]">
      Nuestros valores son:
    </div>
    <div
      class="text-[22px] max-[992px]:text-[16px] leading-[140%] max-[992px]:leading-[100%] text-justify"
    >
      <ul class="md:ml-[-15px]">
        <li class="mb-[10px]">Calidad</li>
        <li class="mb-[10px]">Responsabilidad</li>
        <li class="mb-[10px]">Respeto</li>
        <li class="mb-[10px]">Espiritualidad</li>
      </ul>
    </div>
  </div>
</div>
