import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { RendersAccountPaths } from "./renders-account.paths";

@Injectable({
  providedIn: "root",
})
export class RendersAccountPageService {
  private _url: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this._url = environment.backendUrl;
    this.apiUrl = `${this._url}${RendersAccountPaths.RendersAccountGet}`;
  }

  getRendersAccount() {
    return this.http.get<any>(this.apiUrl);
  }
}
