import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DeviceDetectorComponent } from "../../../components/device/device.component";

@Component({
  selector: "app-tabs-renders",
  standalone: true,
  imports: [CommonModule, DeviceDetectorComponent],
  templateUrl: "./tabs-renders.component.html",
  styleUrl: "./tabs-renders.component.css",
})
export class TabsRendersComponent {
  isMobile: boolean = false;
  selectedTab: number = 0;
  @Input() items: any[] = [];
  loading = true;

  selectTab(index: number) {
    if (index >= 0 && index < this.items.length) {
      this.selectedTab = index;
      this.loading = true;
    }
  }

  onImageLoad() {
    this.loading = false;
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
  }
}
