import { Component } from '@angular/core';

@Component({
  selector: 'app-section-rinde',
  standalone: true,
  imports: [],
  templateUrl: './section-rinde.component.html',
  styleUrl: './section-rinde.component.css'
})
export class SectionRindeComponent {

}
