<app-device-detector (isMobile)="onDeviceChange($event)"></app-device-detector>
<div class="tabs">
  <div *ngIf="loading" class="loader"></div>
  <img
    *ngIf="items && items.length > 0"
    [src]="
      isMobile ? items[selectedTab].img_mobile : items[selectedTab].img_desktop
    "
    alt="Tab Image"
    class="tab-image"
    (load)="onImageLoad()"
  />

  <ng-container *ngIf="!isMobile && items && items.length > 0">
    <ul class="tab-header">
      <li
        *ngFor="let tab of items; let i = index"
        [class.active]="selectedTab === i"
        (click)="selectTab(i)"
      >
        {{ tab.name }}
      </li>
    </ul>

    <div class="tab-content">
      <span [innerHTML]="items[selectedTab].text"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="isMobile">
    <ul class="tab-header">
      <li *ngFor="let tab of items; let i = index">
        <div (click)="selectTab(i)" [class.active]="selectedTab === i">
          {{ tab.name }}
        </div>

        <div *ngIf="selectedTab === i" class="tab-content">
          <span [innerHTML]="tab.text"></span>
        </div>
      </li>
    </ul>
  </ng-container>
</div>
